import React, { useEffect, useRef } from 'react';
import './css/portfolio.css';
import { NavLink } from 'react-router-dom';
import Layouts from './layout/layout';
import { useState } from 'react';
import portfolioService from '../services/portfolioService';
import webService from '../services/webService';
import baseURL from './utils/baseURL';

const Portfolio = () => {

    const portfolioRef = useRef(null);
    const [protfolio,setPortfolio] = useState([])
    const [tempData,setTempData] = useState([])
    const [cate,setCate] = useState([])

    const scrollToPortfolio = () => {
        portfolioRef.current.scrollIntoView()
    }

    useEffect(()=>{
        getPortfolio()
        getCategory()
    },[])
    

    const getPortfolio = async () => { 
        await webService.getPortfolio().then(res => {  
          setPortfolio(res.data?.result)
          setTempData(res.data?.result)
          console.log(res.data?.result);
          
        })
    }

    // get category
    const getCategory = async()=>{  
        await portfolioService.getCategories().then(res => { 
          setCate(res.data?.result); 
         })
    }

    useEffect(() => {
        
        filterSelection("all",0)
        // Add active class to the current button (highlight it)
        var btnContainer = document.getElementById("filterBtnContainer");
        var btns = btnContainer.getElementsByClassName("filterBtn");
        for (var i = 0; i < btns.length; i++) {
            btns[i].addEventListener("click", function () {
                var current = document.getElementsByClassName("activeBtn");
                current[0].className = current[0].className.replace(" activeBtn", "");
                this.className += " activeBtn";
            });
        }
    }, [])

    // tab wise Filter Data
    const filterSelection = (c,index) => {
        setActiveButtonIndex(index)
        if (c === "all") {
            setPortfolio(tempData);
          } else {
            setPortfolio(
                tempData.filter((item) =>
                item.categories.some((cat) => cat.name === c)
              )
            );
          }
    }

    const [activeButtonIndex, setActiveButtonIndex] = useState(0);

    return (
        <>
            <Layouts>
                <div className='portfolio'>
                    <section className="part-1">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-12 col-lg-6" data-aos="fade-down"
                                        data-aos-duration={1000}>
                                    <div
                                        className="d-flex flex-column"
                                        data-aos="fade-down"
                                        data-aos-duration={1000}
                                    >
                                        <div className="heading-1">
                                            Our <span style={{ color: "#F58634" }}>Portfolio</span>
                                        </div>
                                        <div className="_description">
                                        We are giving  a glimpse of our skills and dedication to top-notch tech services.
                                        </div>
                                        <div className="view_portfolio">
                                            <button onClick={scrollToPortfolio}>View Portfolio</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div
                                        className="one_people"
                                        data-aos="fade-left"
                                        data-aos-duration={1000}
                                    >
                                        <img src="/image/portfolio/svg/main_image.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="part-2" ref={portfolioRef}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div
                                        id="filterBtnContainer"
                                        data-aos="fade-up"
                                        data-aos-duration={1000}
                                    >
                                        <button
                                            className={`filterBtn ${activeButtonIndex === 0 ? 'activeBtn' : ''}`}
                                            onClick={() => { filterSelection('all', 0) }}
                                        >
                                            All
                                        </button>
                                        {
                                            cate && cate.map((c,index) =>
                                            <button key={index} className={`filterBtn ${activeButtonIndex === index + 1 ? 'activeBtn' : ''}`} onClick={() => { filterSelection(`${c.name}`,index + 1) }}>
                                                {c.name}
                                            </button>
                                            )
                                        }
                                       
                                        
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div
                                        className="row"
                                        id="projectContainer"
                                        data-aos="fade-up"
                                        data-aos-duration={1000}
                                    >
                                        {protfolio.length ===0 ?
                                        <div className="col-12" id="emptyProjectState">
                                            <div className="row justify-content-center align-items-center">
                                                <div className="col-12 col-md-6 col-lg-4">
                                                    <div>
                                                        <img src="/image/portfolio/svg/data_not_found.svg" alt="" />
                                                        <div>Data not found</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                        :''}
                                        {
                                            protfolio &&  protfolio.map((port,index) => 
                                            
                                            <div className={`col-12 col-md-6 col-lg-4 filterProject showProject ${port?.categories[0]?.name}`} key={index}>
                                                <div className="card_box_container ">
                                                    <div className="card_top_container">
                                                        <div className="card_top_link">
                                                            <NavLink  to={`/portfolio_details/${port.id}`}>
                                                                <button>View Details</button>
                                                            </NavLink>
                                                        </div>
                                                        <div className='protfolio-image-card'>
                                                        <img src={port.image?baseURL+"/"+port.image:""} alt='no' loading='lazy' />
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="card_bottom_container">
                                                        <div className="card_box_title">{port.title}</div>
                                                        <div className="card_box_text">
                                                            {port.aboutUs}
                                                        </div>
                                                        <div className="card_box_chips">
                                                        {
                                                            port.tags.map((tag)=>
                                                            <div className="card_box_chip">{tag.name}</div>
                                                            )
                                                        } 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            )
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="part-3">
                        <div className="container">
                            <div className="row">
                                <div className="info_wrapper">
                                    <div className="sub-heading">Let’s Talk</div>
                                    <div className="heading">
                                        Want to{" "}
                                        <span style={{ color: "#F58634" }}>
                                            Discuss your Project with Us?
                                        </span>
                                    </div>
                                    <div className="details">
                                    Let's connect and explore your ideas together
                                    </div>
                                    <div className="contact_us">
                                        <NavLink to="/contact">
                                            <button>Contact Us</button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Layouts>
        </>

    )
}

export default Portfolio