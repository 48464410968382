/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import baseURL from "../components/utils/baseURL.js";

class WebServices {
  
  async getCompany() {  
    
        return await axios.get(`${baseURL}/web/logo`);
      }

      async getService() {  
        return await axios.get(`${baseURL}/web/service`);
      }

      async getOurComplete() {  
        return await axios.get(`${baseURL}/web/ourcomplete`);
      }

      async getjob() {  
        return await axios.get(`${baseURL}/web/job`);
      }

      async getReview() {  
        return await axios.get(`${baseURL}/web/review`);
      }

      async getRecent() {  
        return await axios.get(`${baseURL}/web/portfolio`);
      }

      async getRecentService() {  
        return await axios.get(`${baseURL}/web/portfolioservice`);
      }

      async getPortfolio() {  
        return await axios.get(`${baseURL}/web/portfoliolist`);
      }

        // create Service 
      async applyJob(data) {  
          return await axios.post(`${baseURL}/web/apply`,data,{
              headers: { 
                  "Content-Type": "multipart/form-data" 
              }
              
          });
      } 

       // portfolio details by id
      async getPortDetails(id) {  
          return await axios.get(`${baseURL}/web/portfoliodetails?id=`+id);
      }

      // client logo
      async getClientLogo() {  
        return await axios.get(`${baseURL}/web/clientlogo`);
      } 

        // google analaytics
        async getvisitorData() {  
          return await axios.get(`${baseURL}/web/visit`);
        } 
}

export default new WebServices()