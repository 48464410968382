import React from 'react'
import Header from './header'
import Footer from './footer'
import ScrollButton from './scrollButton'
import { useState } from 'react'
import { useEffect } from 'react'
import webService from '../../services/webService'

const Layouts = (props) => {
  const [email,setEmail] = useState("");
  const [phone,setPhone] = useState("");
  const [social,setSocial] = useState([]);
  useEffect(() => {
    getCompanyDetails();
  },[])
  
  
  const getCompanyDetails = async () => { 
    await webService.getCompany().then(res => { 
        setEmail(res.data?.result[0]?.email ? res.data?.result[0].email : "") 
        setPhone(res.data?.result[0]?.phone ? res.data?.result[0].phone : "") 
        setSocial(res.data?.social)
    })
}
  return (
    <>
    <Header/>
    {props.children}
    <Footer  email={email} phone={phone} social={social}/>
    <ScrollButton />
    </>
  )
}

export default Layouts