import axios from "axios";
import baseURL from "../components/utils/baseURL.js"

class SettingServices { 

    // create Company 
    async createComapny(token,data) {  
        return await axios.post(`${baseURL}/service/company`,data,{
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "multipart/form-data" 
            }
            
        });
    } 

     // get Company 
     async getCompany(token) { 
        // console.log(token)
        return await axios.get(`${baseURL}/service/company`,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
      }

      // get Service 
     async getService(token) { 
        return await axios.get(`${baseURL}/service/servicelist`,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
      }

        // create Service 
    async createService(token,data) {  
        return await axios.post(`${baseURL}/service`,data,{
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "multipart/form-data" 
            }
            
        });
    } 

    // delete Service 
    async deleteService(token,data) {  
        return await axios.post(`${baseURL}/service/delete`,data,{
            headers: {
                'Authorization': `Bearer ${token}` 
            }
        });
    }

    // edit Service 
    async detailService(token,id) {  
        // console.log(id)
        return await axios.get(`${baseURL}/service/editservice?id=`+id,{
            headers: {
                'Authorization': `Bearer ${token}` 
            }
        });
    }

     // update Service 
     async updateService(token,data) {  
        // console.log(data)
        return await axios.post(`${baseURL}/service/updateservice`,data,{
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "multipart/form-data" 
            }
            
        });
    }

    // get Social 
    async getSocial(token) { 
    return await axios.get(`${baseURL}/service/social`,{
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    }
    
    // create Service 
    async createSocial(token,data) {  
        return await axios.post(`${baseURL}/service/social`,data,{
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "multipart/form-data" 
            }
            
        });
    } 
    
    // delete Social 
    async deleteSocial(token,data) {  
        return await axios.post(`${baseURL}/service/socialdelete`,data,{
            headers: {
                'Authorization': `Bearer ${token}` 
            }
        });
    }

    
    // edit social 
    async detailSocial(token,id) {  
        // console.log(id)
        return await axios.get(`${baseURL}/service/editsocial?id=`+id,{
            headers: {
                'Authorization': `Bearer ${token}` 
            }
        });
    }

     // update social 
     async updateSocial(token,data) {  
        // console.log(data)
        return await axios.post(`${baseURL}/service/updatesocial`,data,{
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "multipart/form-data" 
            }
            
        });
    }
    
    
} 
    
export default new SettingServices()