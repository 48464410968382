import React from 'react'
import WebRoute from '../../layout'
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const AuthenticatedRoute = () => {
 
  return (
    <WebRoute/>
  )
}

export default AuthenticatedRoute