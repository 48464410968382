import React, { useEffect } from 'react'
import Layouts from '../layout/layouts'
import XError from '../../common/xError'
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import settingService from '../../../services/settingService';
import Swal from 'sweetalert2';
import { useRef } from 'react';
import portfolioService from '../../../services/portfolioService';
import DOMPurify from 'dompurify';

const AddService = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [categories,setCategories] = useState([]);
    const [formData, setFormData] = useState({
        name: '', 
        aboutUs:'',
        category:"",
        image: null, // To store the selected image file
      });

      const [errors, setError] = useState({
        name: '', 
        aboutUs:'',
        category:"",
        image: null, // To store the selected image file
    });
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    // Function to handle image selection
    const handleImageChange = (event) => {
        const file = event.target.files[0]; 
        if (file && allowedFile(file.name)) {
          setFormData({ ...formData, image: event.target.files[0]});
          setSelectedImage(URL.createObjectURL(file));
        }else{
          setSelectedImage(null); 
          setError({...errors,image:"Please select jpg,png and jpeg file."})
        }
    };
    // set input data
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]:DOMPurify.sanitize(value)}));
    };

    useEffect(() =>{
        getCategory(); 
    },[])

    // get category
    const getCategory = async()=>{  
      await portfolioService.getCategories().then(res => { 
        setCategories(res.data?.result); 
       })
    }

    const onSubmit = async(event) => {
      setIsLoading(true)
        event.preventDefault();
        if (checkValidation()) {
            const token = localStorage.getItem('token');
            const postData = new FormData();
            postData.append('name', formData.name); 
            postData.append('service_logo', formData.image); 
            postData.append('about_us', formData.aboutUs);
            postData.append('category', formData.category);
            await settingService.createService(token,postData).then(async (res) => { 
                // console.log(res.data?.result);
                setIsLoading(false)
                Swal.fire("Service added successfully.");
                navigate("/service-list")
            }).catch((err) => {
                setIsLoading(false)
                Swal.fire("Service not added.");
            })
       }else{
        setIsLoading(false);
      }
        
    };
    const checkValidation = () => {
        //  console.log("validation",state);
        let error = {
            name: '', 
            aboutUs:'',
            category:'',
            image: null,
        };
    
        const { name,aboutUs,category, image } = formData;
    
        let flag = true;
    
        if (name === "" || name === null || name === undefined || name.trim() === '') {
          error.name = "The name field is required.";
          flag = false;
        }  
        if (aboutUs === "" || aboutUs === null || aboutUs === undefined || aboutUs.trim() === '') {
            error.aboutUs = "The description field is required.";
            flag = false;
        }
        if (category === "" || category === null || category === undefined || category.trim() === '') {
          error.category = "The category field is required.";
          flag = false;
        } 
        if (image === "" || image === null || image === undefined) {
            error.image = "The file field is required.";
            flag = false;
        }
        setError({ ...error });
        // console.log(errors)
        return flag;
      };
      const allowedFile = (filename) => {
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const extension = filename.split('.').pop().toLowerCase();
        return allowedExtensions.includes(extension);
      };
    const onReset = ()=>{ 
        setFormData({
            name: '', 
            aboutUs:'',
            category:'',
            image: null, // To store the selected image file
          }); 
          setSelectedImage(""); 
          fileInputRef.current.value = '';
          setError({
            name: '', 
            aboutUs:'',
            category:'',
            image: null, // To store the selected image file
        });
      }

  return (
    <Layouts>
    <div className='admin-portfolio'>
      <div className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12s"> 
              <Link to="/service-list" className='add-link-color'>
                <h4 className="dash-title">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M4.78645 10.7139L13.7804 19.7048C14.175 20.0984 14.8144 20.0984 15.21 19.7048C15.6047 19.3111 15.6047 18.6717 15.21 18.2781L6.92952 10.0005L15.209 1.72302C15.6037 1.32937 15.6037 0.689976 15.209 0.295329C14.8144 -0.098321 14.174 -0.098321 13.7794 0.295329L4.78545 9.28616C4.39687 9.67575 4.39687 10.3252 4.78645 10.7139Z" fill="#1F4168"/>
                </svg>
                <span className='ps-3'>Add Service</span></h4>
                </Link>
              </div>  
            </div>
          </div>
      </div>
      <div className='add-portfolio-body'>
          <div className="container-fluid">
              <div className='row'>
                  <div className='col-12'>
                      <div className='card add-portfolio-card'>
                          <div className='card-body'>
                            <form>
                              <div className='slectd-image pb-4'>
                                    {selectedImage && <img src={selectedImage} alt="Selected" width={120} height={106} loading='lazy'/>}
                              </div>
                              <div className='row'>
                                  <div className='col-sm-6'>
                                        <div className="mb-4">
                                            <label className="form-label">Upload Image</label>
                                            <input type="file" name='image' className="form-control hint" ref={fileInputRef} accept=".jpg, .jpeg, .png" onChange={handleImageChange}
                                            /> 
                                            <XError message={errors.image} />
                                        </div>
                                  </div>   
                                  <div className='col-sm-6'>
                                      <div className="mb-4">
                                          <label className="form-label">Name</label>
                                          <input type="text" className="form-control" name="name" value={formData.name} onChange={handleChange}
                                            placeholder="Enter name" maxLength={25}/> 
                                            <XError message={errors.name} />
                                      </div>
                                  </div> 
                              </div> 
                              <div className='row'>
                                  <div className='col-sm-6'>
                                        <div className="mb-4">
                                        <label for="exampleFormControlTextarea1" class="form-label">Description</label>
                                        <textarea class="form-control" name='aboutUs' value={formData.aboutUs} id="exampleFormControlTextarea1" rows="3" onChange={handleChange}
                                         placeholder='Enter description' maxLength={150}></textarea>
                                        <XError message={errors.aboutUs} />
                                        </div>
                                  </div>  
                                  <div className='col-sm-6'>
                                        <div className="mb-4">
                                            <label className="form-label">Category</label>
                                            <select class="form-select form-select-lg" aria-label=".form-select-lg example" name="category" 
                                            value={formData.category}
                                            onChange={handleChange}
                                            >
                                                <option value="">Select Category</option>
                                                {
                                                    categories && categories.map(cat => { 

                                                        return <option value={cat.id} key={cat.id}>{cat?.name}</option>
                                                    
                                                    })
                                                }
                                            </select>
                                            <XError message={errors.category} /> 
                                        </div>
                                    </div>
                              </div>
                              <div className='footer-button pt-2'>
                                   <button type='button' onClick={onReset} className='cancel-button'>Cancel</button> 
                                   {isLoading ? (
                                   <button type='button' className='submit-button'>Loading...</button> 
                                   ):(
                                    <button type='button' onClick={onSubmit} className='submit-button'>Submit</button>
                                   )}
                              </div>
                            </form>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
</Layouts>
  )
}

export default AddService