import axios from "axios";
import baseURL from "../components/utils/baseURL.js"

class TechnologyServices { 
 
     // get Technology
     async getTechnology(token) { 
        return await axios.get(`${baseURL}/technology`,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

    // create Technology 
    async createTechnology(token,data) {  
        return await axios.post(`${baseURL}/technology`,data,{
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "multipart/form-data" 
            }
            
        });
    } 

      // edit Technology
      async detailTechnology(token,id) {  
        // console.log(id)
        return await axios.get(`${baseURL}/technology/edit?id=`+id,{
            headers: {
                'Authorization': `Bearer ${token}` 
            }
        });
    }

     // update Technology 
     async updateTechnology(token,data) {  
        // console.log(data)
        return await axios.post(`${baseURL}/technology/update`,data,{
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "multipart/form-data" 
            }
            
        });
    }

    // delete Our job 
    async deleteTechnology(token,data) {  
        return await axios.post(`${baseURL}/technology/delete`,data,{
            headers: {
                'Authorization': `Bearer ${token}` 
            }
        });
    }
} 
    
export default new TechnologyServices()