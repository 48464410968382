import React, { useEffect, useState } from 'react';

const ScrollButton = () => {

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible);
    }, [])

    const toggleVisible = () => {
        const scrolled = document.body.scrollTop || document.documentElement.scrollTop;
        if (scrolled > 20) {
            setVisible(true)
        }
        else if (scrolled <= 20) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <button id="scrollToTopBtn" title="Go to top" onClick={scrollToTop} style={{ display: visible ? 'block' : 'none' }}>
            <i className="fa fa-chevron-up"></i>
        </button>
    )
}

export default ScrollButton