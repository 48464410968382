import React,{useState, useRef} from 'react'
import { NavLink } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import baseURL from '../utils/baseURL';


const RecentWork = ({recentWork,id}) => {
    

    
    
    const [loader, setLoader] = useState(false)

    const swiperRef = useRef(null);

    gsap.registerPlugin(ScrollTrigger)

  return (
    <>
         <section style={{ backgroundColor: "#FCFAF8" }}>
                        <div className="work-div" style={{ paddingTop: 100, paddingBottom: 100 }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h4 data-aos="fade-up" data-aos-duration={1000}>
                                            Recent <span style={{ color: "#F58634" }}>Works </span>
                                        </h4>
                                        <p
                                            style={{
                                                fontSize: 16,
                                                fontWeight: 500,
                                                lineHeight: '20px',
                                                textAlign: "center",
                                                marginBottom: 40
                                            }}
                                            data-aos="fade-up"
                                            data-aos-duration={1000}
                                        >
                                            Check out our latest projects to see our recent works and witness the
                                            quality and innovation we bring to the table.
                                        </p>
                                    </div>
                                </div>
                                {!loader ?
                                    <div
                                        className=""
                                        data-aos="fade-left"
                                        data-aos-duration={1000}
                                    >
                                        {
                                            recentWork.data && recentWork.data.length > 0 ? (
                                                <div className="row justify-content-center ">
                                                    
                                                    <Swiper
                                                className="mySwiper"
                                                ref={swiperRef}
                                                modules={[Navigation]}
                                                breakpoints={{
                                                    // when window width is >= 320px
                                                    320: {
                                                        slidesPerView: 1,
                                                        spaceBetween: 0,
                                                    },
                                                    // when window width is >= 640px
                                                    992: {
                                                        slidesPerView: 3,
                                                        spaceBetween: 0,
                                                    }
                                                }}
                                                navigation={{
                                                    nextEl: ".swiper-button-next-new",
                                                    prevEl: ".swiper-button-prev-new"
                                                }}>
                                                    { recentWork.data.filter((rec) => rec.id !== id).map((rec, index) => {
                                                        return (
                                                            <SwiperSlide>
                                                            <div className="slider-pt" key={index}>
                                                                <div className="slide">
                                                                    <div className="slide_content">
                                                                        <NavLink to={`/portfolio_details/${rec.id}`}>
                                                                            <button>View Details</button>
                                                                        </NavLink>
                                                                    </div>
                                                                    <img src={rec.image ? baseURL + "/" + rec.image : ""} className="img-fluid" alt='no-imag' loading='lazy'/>
                                                                </div>
                                                                <div className="slide_bottom_container">
                                                                    <h4>{rec.title}</h4>
                                                                    <div className="chip_container">
                                                                        {
                                                                            rec.tags.map((tag) =>
                                                                                <div className="chip">{tag.name}</div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            </SwiperSlide>
                                                        )
                                                    })
                                                }
                                                </Swiper>
                                                
                                                </div>
                                            ) : (
                                                <div style={{ textAlign: "center" }}>
                                                    <img src="/image/svg/notfound.svg" alt="" width={200} />
                                                    
                                                </div>
                                            )
                                        }

                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </section> 
    </>
  )
}

export default RecentWork
