import React from 'react';
import { NavLink } from 'react-router-dom';


const Footer = (props) => {

    return (
        <footer>
            <div className="row">
                <img src="/image/F1.png" id="curve" className="img-fluid" alt="" width={1365} height={103} />
            </div>
            <div className="footer">
                <div className="container container-footer">
                    <div
                        className="row justify-content-between"
                        style={{ marginRight: 0, marginLeft: 0 }}
                    >
                        <div className="col-12 col-lg-4 Footer-logo">
                            <NavLink to="/">
                                <img
                                    src="/image/calidig-logo.svg"
                                    alt="no images"
                                    className="img-fluid footer-clogo"
                                    width={220}
                                    height={28}
                                    loading='eager'
                                />
                            </NavLink>
                            <p className="CDS">Calidig IT Solutions LLP</p>
                        </div>
                        <div className="col-12 col-lg-2 FR">
                            <div id="heading">Services</div>
                            <p id="fst">
                                <NavLink to="/platform">Platform Development</NavLink>
                            </p>
                            <p>
                                <NavLink to="/mobile">Mobile App</NavLink>
                            </p>
                            <p>
                                <NavLink to="/uiux">UI / UX Design</NavLink>
                            </p>
                            <p>
                                <NavLink to="/startup">Startup Solutions</NavLink>
                            </p>
                        </div>
                        <div className="col-12 col-lg-2 FR">
                            <div id="heading">Company</div>
                            <p id="fst">
                                <NavLink to="/careers">Join Us</NavLink>
                            </p>
                        </div> 
                        {/* info@calidig.com  7527 092 710*/}
                        <div className="col-12 col-lg-2 FR">
                            <div id="heading">Contact Us</div>
                            <p id="1fst">
                                <img style={{ marginRight: 5 }} src="/image/svg/Email.svg" alt=""  width={24} height={24}/>
                                <span>{props.email}</span>
                            </p>
                            <p style={{ marginTop: 20, color: "#FFFFFF" }}>
                                <img style={{ marginRight: 0 }} src="/image/svg/telephone.svg" alt="" width={24} height={24} />{" "}
                                <span>+91 {props.phone}</span>{" "}
                            </p>
                        </div>
                    </div>
                    <div
                        className="row align-items-center"
                        style={{
                            marginTop: 5,
                            marginRight: 0,
                            marginLeft: 0,
                            paddingTop: 18,
                            paddingBottom: 30,
                            borderTop: "1px solid #71777D"
                        }}
                    >
                        <div
                            className="col-6"
                            style={{
                                whiteSpace: "nowrap",
                                fontWeight: 400,
                                fontSize: 14,
                                lineHeight: '20px',
                                color: "#FFFFFF"
                            }}
                        >
                            Copyright © 2024 Calidig. All Rights Reserved.
                        </div>
                        {/* <div className="col-6 d-flex justify-content-end">
                            {
                                props.social && props.social.map((s,index) => 
                                <a href={s.link} target="_blank" key={index}>
                                    <img
                                        src={baseURL+"/"+s.logo}
                                        className="social_links img-fluid pe-2"
                                        alt=""
                                        width={25}
                                    />
                                </a>
                                )
                            }
                            
                             
                        </div> */}
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer