import React from 'react'
import Header from './header'
import Sidebar from './sidebar' 

const Layouts = (props) => { 
  return (
    <>
      <div id="viewport">
        <Sidebar/>
        <div id="content">
          <Header />
          <div id="content_box">
          {props.children}
          </div>
        </div>
      </div>

    </>
  )
}

export default Layouts