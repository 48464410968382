import React, { useRef } from 'react'
import baseURL from '../../utils/baseURL'
import XError from '../../common/xError'
import Layouts from '../layout/layouts'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import technologyService from '../../../services/technologyService'
import Swal from 'sweetalert2'
import DOMPurify from 'dompurify';


const EditTechnology = () => {

    const { id } = useParams();
    const token = localStorage.getItem('token')  
    const [isLoading, setIsLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [formData, setFormData] = useState({
        name: '',  
        image: null, // To store the selected image file
      });

      const [errors, setError] = useState({
        name: '', 
        image: null, // To store the selected image file
    });
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    // Function to handle image selection
    const handleImageChange = (event) => {
        const file = event.target.files[0]; 
        if (file && allowedFile(file.name)) {
        setFormData({ ...formData, image: event.target.files[0]});
        setSelectedImage(URL.createObjectURL(file));
        }else{
            setSelectedImage(null); 
            setError({...errors,image:"Please select jpg,png and jpeg file."})
        }
    };
    // set input data
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: DOMPurify.sanitize(value) }));
    };

    useEffect(() =>{ 
        getTechnologyDetails();
    },[])

    const getTechnologyDetails = async()=>{
        await technologyService.detailTechnology(token,id).then(res => { 
            setFormData({
                name: res.data.result[0].name,  
                image: res.data.result[0].image,
            }) 
            setSelectedImage(null)
            onReset()
          })
    }

    const onSubmit = async(event) => {
        setIsLoading(true)
        event.preventDefault();
        if (checkValidation()) {
            const token = localStorage.getItem('token');
            const postData = new FormData();
            postData.append('id', id); 
            postData.append('name', formData.name); 
            postData.append('image', formData.image); 

            await technologyService.updateTechnology(token,postData).then(async (res) => { 
                // console.log(res.data?.result);
                setIsLoading(false)
                Swal.fire("Technology updated successfully.");
                navigate("/technology-list")
            }).catch((err) => {
                setIsLoading(false)
                Swal.fire("Technology not updated.");
            })
       }else{
        setIsLoading(false);
      }
        
    };
    const allowedFile = (filename) => {
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const extension = filename.split('.').pop().toLowerCase();
        return allowedExtensions.includes(extension);
      };
    const checkValidation = () => {
        //  console.log("validation",state);
        let error = {
            name: '', 
            image: null,
        };
    
        const { name, image } = formData;
    
        let flag = true;
    
        if (name === "" || name === null || name === undefined || name.trim() === '') {
          error.name = "The name field is required.";
          flag = false;
        } 
        if (image === "" || image === null || image === undefined) {
            error.image = "The file field is required.";
            flag = false;
        }
        setError({ ...error });
        // console.log(errors)
        return flag;
    };
    const onReset = ()=>{  
            setSelectedImage("");  
            fileInputRef.current.value = '';
            setError({
            name: '',  
            image: null, // To store the selected image file
        });
    }
  return (
    <Layouts>
    <div className='admin-portfolio'>
        <div className="content-header">
            <div className="container-fluid">
                <div className="row">
                <div className="col-sm-12s"> 
                    <Link to="/technology-list" className='add-link-color'>
                        <h4 className="dash-title">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M4.78645 10.7139L13.7804 19.7048C14.175 20.0984 14.8144 20.0984 15.21 19.7048C15.6047 19.3111 15.6047 18.6717 15.21 18.2781L6.92952 10.0005L15.209 1.72302C15.6037 1.32937 15.6037 0.689976 15.209 0.295329C14.8144 -0.098321 14.174 -0.098321 13.7794 0.295329L4.78545 9.28616C4.39687 9.67575 4.39687 10.3252 4.78645 10.7139Z" fill="#1F4168"/>
                        </svg>
                        <span className='ps-3'>Update Technology</span></h4>
                    </Link>
                </div>  
                </div>
            </div>
        </div>
        <div className='add-portfolio-body'>
            <div className="container-fluid">
                <div className='row'>
                    <div className='col-12'>
                        <div className='card add-portfolio-card'>
                            <div className='card-body'>
                                <form>
                                <div className='slectd-image pb-4'>
                                    {selectedImage && selectedImage? <img src={selectedImage} loading='lazy' alt="Selected" width={120} height={106}/>:<img src={formData.image ?baseURL+"/"+formData.image:""} loading='lazy' alt="Selected" width={120} height={106}/>}
                                </div>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                            <div className="mb-4">
                                                <label className="form-label">Upload Image</label>
                                                <input type="file" name='image' className="form-control hint" ref={fileInputRef} accept=".jpg, .jpeg, .png" onChange={handleImageChange}
                                                /> 
                                                <XError message={errors.image} />
                                            </div>
                                    </div>  
                                    <div className='col-sm-6'>
                                            <div className="mb-4">
                                                <label className="form-label">Technology Name</label>
                                                <input type="text" className="form-control" name="name" value={formData.name} onChange={handleChange}
                                                placeholder="Enter name" maxLength={30} /> 
                                                <XError message={errors.name} />
                                            </div>
                                    </div> 
                                </div>  
                                <div className='footer-button pt-2'>
                                    <button type='button' onClick={getTechnologyDetails} className='cancel-button'>Cancel</button> 
                                    {isLoading ? (
                                    <button type='button' className='submit-button'>Loading...</button> 
                                    ):(
                                        <button type='button' onClick={onSubmit} className='submit-button'>Update</button>
                                    )}
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</Layouts>
  )
}

export default EditTechnology