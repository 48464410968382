import axios from "axios";
import baseURL from "../components/utils/baseURL.js"

class OurServices { 

    // create Our Complete
    async createComplete(token,data) {   
        return await axios.post(`${baseURL}/complete`,data,{
            headers: {
                'Authorization': `Bearer ${token}`  
            }
            
        });
    } 

     // get Our Complete
     async getComplete(token) {  
        return await axios.get(`${baseURL}/complete`,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
      }
 
    
} 
    
export default new OurServices()