import React from 'react'
import './css/mobile_app.css';
import { NavLink } from 'react-router-dom';
import Layouts from './layout/layout';
import baseURL from './utils/baseURL';
import RecentWork from './Home/RecentWork';

const MobileApp = (props) => {

    return (
        <>
        <Layouts>
            <div className="mobile-service">
                {/* first section */}
                <section className="part-1">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6">
                                <div className="d-flex flex-column">
                                    <div
                                        className="online-part"
                                        data-aos="fade-left"
                                        data-aos-duration={1000}
                                    >
                                        <p className="online-span">Help Shaping Tomorrow Through Apps</p>
                                    </div>
                                    <div
                                        className="heading-1"
                                        data-aos="fade-up"
                                        data-aos-duration={1000}
                                    >
                                        {" "}
                                        <span className="heading-2">Enhance Your </span> Digital
                                        Footprints
                                    </div>
                                    <div
                                        className="_description text-left-justify"
                                        data-aos="fade-right"
                                        data-aos-duration={1000}
                                    >
                                        We specialize in crafting native, cross-platform, and progressive web applications. Our focus is on ensuring stable performance, human-centered design, and a unique selling proposition, helping your app stand out in a competitive market.
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-12 col-lg-6"
                                data-aos="fade-left"
                                data-aos-duration={1000}
                            >
                                <div className="group_people">
                                    <img
                                        src="/image/mobile/Group 40834.svg"
                                        alt=""
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* second section */}
                <section className="part-2" style={{ paddingTop: 100, paddingBottom: 100 }}>
                    <div className="container">
                        <div className="row" data-aos="fade-left" data-aos-duration={1000}>
                            <div className="col-12 col-md-6 col-xl-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-image">
                                            <img
                                                src="/image/mobile/native.svg"
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <h3 className="card-title">Native Apps</h3>
                                        <p className="card-p">
                                            We help you to turn your best ideas into functional,
                                            feature-rich smartphone &amp; tablet solutions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-image">
                                            <img
                                                src="/image/mobile/cross.svg"
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <h3 className="card-title">Cross-Platform Apps</h3>
                                        <p className="card-p">
                                            Our proficient team can create intuitive and reliable apps for
                                            your organization in hybrid technologies.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-image">
                                            <img
                                                src="/image/mobile/app.svg"
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <h3 className="card-title">Apps Maintenance</h3>
                                        <p className="card-p">
                                            Corrective and Proactive maintenance of the apps to keep the
                                            zero downtime for users.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-image">
                                            <img
                                                src="/image/mobile/upgrade.svg"
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <h3 className="card-title">Apps Upgradation</h3>
                                        <p className="card-p">
                                            Enhancing the features in the app or updating to latest OS
                                            changes, we offer regular solutions to our customers.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Third section */}
                <section className="part-3" style={{ paddingTop: 100, paddingBottom: 100 }}>
                    <div className="container">
                        <div className="row" data-aos="fade-right" data-aos-duration={1000}>
                            <div className="col-12 col-md-4 col-lg-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-image">
                                            <img
                                                src="/image/mobile/android.svg"
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <h3 className="card-title">Android Apps</h3>
                                        <p className="card-p">
                                            We leverage Java and Kotlin to deliver high performing Android
                                            phone apps.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-image">
                                            <img
                                                src="/image/mobile/apple.svg"
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <h3 className="card-title">iOS Apps</h3>
                                        <p className="card-p">
                                            Leveraging Swift, we deliver the apps that iPhone users love.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-image">
                                            <img
                                                src="/image/mobile/flutter.svg"
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <h3 className="card-title">Flutter &amp; React Native Apps</h3>
                                        <p className="card-p">
                                            We offer low cost and easily manageable apps via React Native
                                            and Flutter frameworks.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* fourth section */}
                <section className="part-4">
                    <div className="app_process">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-12 col-lg-6">
                                    <div className="app-card">
                                        <div
                                            className="app_p_title text-center"
                                            data-aos="fade-up"
                                            data-aos-duration={1000}
                                        >
                                            <h2 className="app-title">
                                                Our <span className="inside">Mobile App</span> Process
                                            </h2>
                                        </div>
                                        <div
                                            className="app_p_desc text-center"
                                            data-aos="fade-up"
                                            data-aos-duration={1000}
                                        >
                                            <p>
                                               
                                                Innovative mobile apps developed in native and hybrid technologies to engage your audience.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-12 col-lg-6"
                                    data-aos="fade-left"
                                    data-aos-duration={1000}
                                >
                                    <div className="app_image">
                                        <img
                                            src="/image/mobile/Group 40858.svg"
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Recent Work */}
                <RecentWork recentWork={props}/>
            </div>
        </Layouts>
        </>
    )
}

export default MobileApp