import axios from "axios";
import baseURL from "../components/utils/baseURL.js"

class portfolioDetailsService{

     // get portfolio 
     async getPortfoliosDetails(token) { 
        return await axios.get(`${baseURL}/portfoliosDetails/`,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
      }


      async createPortfolioDetails(token,data) {  
        return await axios.post(`${baseURL}/portfoliosDetails/`,data,{
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "multipart/form-data" 
            }
            
        });
    }

    async deletePortfolio(token,data) {  
        return await axios.post(`${baseURL}/portfoliosDetails/delete`,data,{
            headers: {
                'Authorization': `Bearer ${token}`
                
            }
            
        });
    }
      
    async editPortfolio(token,id) {  
        return await axios.get(`${baseURL}/portfoliosDetails/edit?id=`+id,{
            headers: {
                'Authorization': `Bearer ${token}` 
            }
        });
    }

    async updatePortfolio(token,data) {  
        console.log(data)
        return await axios.post(`${baseURL}/portfoliosDetails/update`,data,{
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "multipart/form-data" 
            }
            
        });    
    }

    async imagesDelete(token,id,type) {  

        return await axios.get(`${baseURL}/portfoliosDetails/deleted/image?id=`+id+`&type=`+type,{
            headers: {
                'Authorization': `Bearer ${token}` 
            }
        });
    }
}

export default new portfolioDetailsService()