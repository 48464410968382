import React, { useRef, useState } from 'react';
import './css/apply_for_job.css';
import XError from './common/xError';
import Swal from "sweetalert2";
import Layouts from './layout/layout';
import { useLocation, useNavigate } from 'react-router-dom';
import webService from '../services/webService';
import { useEffect } from 'react';

const Apply = () => {
    const locations = useLocation();
    const selectedJob = locations.state && locations.state.jobDetails; 
    const selectedstatus = locations.state && locations.state.status; 
    const [selectedfile,setSelectedFile] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [iscard,setIsCard] = useState(false);
    const history = useNavigate();
    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
        attech: ""
    });
    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
        attech: ""
    }); 

    const fileInputRef = useRef(null);

    const handleImageChange = (event) => { 
        const file = event.target.files[0]; 
        // if (file) {
        //     setState({ ...state, attech: event.target.files[0]});
        // setSelectedFile(URL.createObjectURL(file));
        // }
        if (file) {
            if (file.type === "application/pdf") {
                setState({ ...state, attech: event.target.files[0] });
                setSelectedFile(URL.createObjectURL(file));
            } else { 
                setErrors({...errors,attech:"Please select a PDF file."})
                event.target.value = ''; // Clear the input field
            }
        }
    };

    // submit form data
    const onSubmit = async() => { 
        setIsLoading(true)
        if (checkValidation()) { 
            const postData = new FormData(); 
            postData.append('name', state.firstName); 
            postData.append('lastname', state.lastName); 
            postData.append('email', state.email);
            postData.append('phone', state.phone);
            postData.append('message', state.message);
            postData.append('attech', state.attech); 
            postData.append('referenceCode', selectedJob.referenceCode);
            postData.append('title',selectedJob.jobTitles?.title );

            await webService.applyJob(postData).then(async (res) => { 
                // console.log(res.data?.result);
                setIsLoading(false)
                Swal.fire("Email sent successfully"); 
                setState({
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    message: "",
                    attech: ""
                })
                fileInputRef.current.value = '';
                // setSelectedFile(null)
            }).catch((err) => {
                setIsLoading(false)
                Swal.fire("Email not sent");
            })
        } else {
            setIsLoading(false)

        }
    }

    useEffect(() => {
        // Your logic for applying or handling the application page
    
        // Clear the jobDetails state when navigating away
        return () => {
          history('/careers'); // Redirect to /career URL
        };
      }, [history]);

    // check form validation
    const checkValidation = () => {
        let error = {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            message: "",
            attech: ""
        };

        const {
            firstName,
            lastName,
            email,
            phone,
            message,
            attech
        } = state;

        let flag = true;
        if (firstName === "" || firstName === null || firstName === undefined || firstName.trim()==='') {
            error.firstName = "The first name field is required.";
            flag = false;
        }
        if (lastName === "" || lastName === null || lastName === undefined || lastName.trim()==='') {
            error.lastName = "The last name field is required.";
            flag = false;
        }
        const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if (email === "" || email === null || email === undefined || email.trim()==='') {
            error.email = "The email field is required.";
            flag = false;
        } else {
            if (!validateEmail(email)) {
                error.email = "The email must be a valid email address.";
                flag = false;
            }
        }
        
        if (phone === "" || phone === null || phone === undefined || phone.trim()==='') {
            error.phone = "The phone field is required.";
            flag = false;
        } else {
            if (!validatePhoneNumber(phone)) {
                error.phone = 'Please enter a valid 10-digit phone number.';
                flag = false;
            }
        }

        if (message === "" || message === null || message === undefined || message.trim()==='') {
            error.message = "The message field is required.";
            flag = false;
        }
        if (attech === "" || attech === null || attech === undefined) {
            error.attech = "The file field is required.";
            flag = false;
        }
        setErrors({ ...error });
        return flag;

    }
    const validatePhoneNumber = (phoneNumber) => { 
        const phonePattern = /^\d{10}$/; // Assuming 10 digits for a mobile number
        return phonePattern.test(phoneNumber);
      };

     // check email validation
     const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    
    return (
        <>
        <Layouts>
            <div className='apply_job'>
                <section className="part-1">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <div
                                    className="d-flex flex-column"
                                    data-aos="fade-down"
                                    data-aos-duration={1000}
                                >
                                    <div className="heading-1">
                                        Apply For <span style={{ color: "#F58634" }}>Job</span>
                                    </div>
                                    <div className="_description">
                                        Please fill in the form below, and one of us will get in touch
                                        with you or respond to your queries soon.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="part-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div
                                    className="row top_info_row"
                                    data-aos="fade-up"
                                    data-aos-duration={1000}
                                >
                                    <div className="col-12 col-sm-12 col-md-6 col-xl-2">
                                        <div className="top_info">
                                            <div className="light_text">Job ID</div>
                                            <div className="dark_text">{selectedJob.referenceCode || ""}</div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-xl-3">
                                        <div className="top_info">
                                            <div className="light_text">Job Title</div>
                                            <div className="dark_text">{selectedJob.jobTitles?.title || ""}</div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-xl-2">
                                        <div className="top_info">
                                            <div className="light_text">Work Experience</div>
                                            <div className="dark_text">{selectedJob.experience || ""} years</div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-xl-2">
                                        <div className="top_info">
                                            <div className="light_text">Open Positions</div>
                                            <div className="dark_text">{selectedJob.position || ""}</div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-xl-3">
                                        <div className="top_info">
                                            <div className="light_text">Location</div>
                                            <div className="dark_text">{selectedJob.location || ""}</div>
                                            {/* <div className="dark_text">{Array.isArray(selectedJob.location) ?selectedJob.location.join(', ') :JSON.parse(selectedJob.location).join(', ') || ""}</div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div
                                    className="middle_info_row"
                                    data-aos="fade-up"
                                    data-aos-duration={1000}
                                >
                                   <div
                                        dangerouslySetInnerHTML={{
                                            __html: selectedJob.jobDetails
                                        }}></div>
                                     
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="part-3">
                    <div className="container">
                        <div className='row'>
                            <div className='col-12'>
                                <div className="view_portfolio_apply_button">
                                    {
                                         !iscard && selectedstatus != "1" ?<button onClick={() => setIsCard(true)}>Apply Now</button>:""
                                    }
                                    
                                </div>  
                            </div>
                        </div>
                        {
                            iscard || selectedstatus == "1" ? <div className="row">
                            <div className="col-12">
                                <div className="heading" data-aos="fade-up" data-aos-duration={1000}>
                                    Apply <span style={{ color: "#F58634" }}>Now</span>
                                </div>
                            </div>
                            <div className="col-12">
                                <form data-aos="fade-up" data-aos-duration={1000}>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <input type="text" placeholder="First name"
                                                value={state.firstName}
                                                onChange={(event) =>
                                                    setState({ ...state, firstName: event.target.value })
                                                } />
                                            <div className="error-msg">
                                                <XError message={errors.firstName} />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <input type="text" placeholder="Last name"
                                                value={state.lastName}
                                                onChange={(event) =>
                                                    setState({ ...state, lastName: event.target.value })
                                                } />
                                            <div className="error-msg">
                                                <XError message={errors.lastName} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <input type="email" placeholder="Email"
                                                value={state.email}
                                                onChange={(event) =>
                                                    setState({ ...state, email: event.target.value })
                                                } />
                                            <div className="error-msg">
                                                <XError message={errors.email} />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <input type="text" placeholder="Phone"
                                                value={state.phone}
                                                onChange={(event) =>
                                                    setState({ ...state, phone: event.target.value })
                                                } />
                                            <div className="error-msg">
                                                <XError message={errors.phone} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <textarea rows={4} placeholder="Message"
                                                value={state.message}
                                                onChange={(event) =>
                                                    setState({ ...state, message: event.target.value })
                                                } />
                                            <div className="error-msg">
                                                <XError message={errors.message} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <label>Resume Upload ( Only PDF )</label>
                                            <br />
                                            <input
                                                type="file"
                                                accept="application/pdf"
                                                className="form-control-file"
                                                onChange={handleImageChange}
                                                name='attech'
                                                ref={fileInputRef}
                                            />
                                            <div className="error-msg">
                                                <XError message={errors.attech} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-submit-button">
                                        {
                                            isLoading ? <button type="button">Loading...</button>
                                            : <button type="button" onClick={onSubmit}>Submit</button>
                                        }
                                        
                                    </div>
                                </form>
                            </div>
                        </div>:""
                        }
                        
                    </div>
                </section>
            </div>
        </Layouts>
        </>
    )
}

export default Apply