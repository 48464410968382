import React from "react";
import Layouts from "../layout/layouts";
import XError from "../../common/xError";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import rolePermissionService from "../../../services/rolePermissionService";
import { permissions } from "../adminUtils/permissions";

const AddRole = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    permission: [],
  });

  const [errors, setError] = useState({
    name: "",
    permission: [],
  });
  const navigate = useNavigate();


  // set input data
  const handleChange = (event) => {
    const { name, value, checked } = event.target;

    if (name === "permission") {
      // Update permissions array
      setFormData((prev) => ({
        ...prev,
        permission: checked
          ? [...prev.permission, value]
          : prev.permission.filter((perm) => perm !== value),
      }));
    } else {
      // Update other fields
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const onSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();

    if (checkValidation()) {
      const token = localStorage.getItem("token");
      const postData = new FormData();
      postData.append("name", formData.name);

      formData.permission.forEach((permission) => {
        postData.append("permission", permission); // `permission[]` is the name that denotes an array
      });


        await rolePermissionService.addNewRole(token, postData)
          .then(async (res) => {
            console.log(res.data?.result);
            setIsLoading(false);
            Swal.fire("Role added successfully.");
            navigate("/role-permission");
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
            
            Swal.fire("Role not added.");
          });
    } else {
      setIsLoading(false);
    }
  };
  const checkValidation = () => {
    //  console.log("validation",state);
    let error = {
      name: "",
      permission: [],
    };

    const { name, permission } = formData;

    let flag = true;

    if (
      name === "" ||
      name === null ||
      name === undefined ||
      name.trim() === ""
    ) {
      error.name = "The name field is required.";
      flag = false;
    }
    if (
      permission === "" ||
      permission === null ||
      permission === undefined ||
      permission.length === 0
    ) {
      error.aboutUs = "The permission field is required.";
      flag = false;
    }

    setError({ ...error });
    // console.log(errors)
    return flag;
  };

  const onReset = () => {
    setFormData({
      name: "",
      permission: [],
    });

    setError({
      name: "",
      permission: [],
    });
  };

  return (
    <Layouts>
      <div className="admin-portfolio">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12s">
                <Link to="/role-permission" className="add-link-color">
                  <h4 className="dash-title">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M4.78645 10.7139L13.7804 19.7048C14.175 20.0984 14.8144 20.0984 15.21 19.7048C15.6047 19.3111 15.6047 18.6717 15.21 18.2781L6.92952 10.0005L15.209 1.72302C15.6037 1.32937 15.6037 0.689976 15.209 0.295329C14.8144 -0.098321 14.174 -0.098321 13.7794 0.295329L4.78545 9.28616C4.39687 9.67575 4.39687 10.3252 4.78645 10.7139Z"
                        fill="#1F4168"
                      />
                    </svg>
                    <span className="ps-3">Add New Role</span>
                  </h4>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="add-portfolio-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card add-portfolio-card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        {/* Role Name Input */}
                        <div className="col-sm-6">
                          <div className="mb-4">
                            <label className="form-label">Role Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              placeholder="Enter new role"
                              maxLength={25}
                            />
                            <XError message={errors.name} />
                          </div>
                        </div>

                        {/* Permissions Checkboxes */}
                        <div className="col-sm-6">
                          <div className="mb-4 ">
                            <label className="form-label ">Permission</label>
                            {permissions.map((permission) => (
                              <div
                                className="bg-light mb-2 w-auto"
                                key={permission.value}
                              >
                                <input
                                  className="form-check-input me-4"
                                  type="checkbox"
                                  value={permission.value}
                                  id={`permission${permission.value}`}
                                  name="permission"
                                  checked={formData.permission.includes(
                                    permission.value
                                  )}
                                  onChange={handleChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`permission${permission.value}`}
                                >
                                  {permission.label}
                                </label>
                              </div>
                            ))}
                            <XError message={errors.role} />
                          </div>
                        </div>
                      </div>

                      {/* Footer Buttons */}
                      <div className="footer-button pt-2">
                        <button
                          type="button"
                          onClick={onReset}
                          className="cancel-button"
                        >
                          Cancel
                        </button>
                        {isLoading ? (
                          <button type="button" className="submit-button">
                            Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={onSubmit}
                            className="submit-button"
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
};

export default AddRole;
