import React from 'react'
import Layouts from '../layout/layouts'
import { useState } from 'react';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import XError from '../../common/xError';

const AddLogo = () => {
    const [selectedImage, setSelectedImage] = useState(null); 
    const [formData, setFormData] = useState({ 
        image: null, // To store the selected image file
      });

    const [errors, setError] = useState({ 
        image: null, // To store the selected image file
    });
    const fileInputRef = useRef(null); 
    // Function to handle image selection
    const handleImageChange = (event) => {
        const file = event.target.files[0]; 
        if (file && allowedFile(file.name)) {
          setFormData({ ...formData, image: event.target.files[0]});
          setSelectedImage(URL.createObjectURL(file));
        }else{
          setSelectedImage(null); 
          setError({...errors,image:"Please select jpg,png and jpeg file"})
        }
    };
    const allowedFile = (filename) => {
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const extension = filename.split('.').pop().toLowerCase();
        return allowedExtensions.includes(extension);
      };
  return (
    <Layouts>
        <div className='admin-portfolio'>
        <div className="content-header">
            <div className="container-fluid">
                <div className="row">
                <div className="col-sm-12s"> 
                <Link to="/job-title-list" className='add-link-color'>
                    <h4 className="dash-title">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M4.78645 10.7139L13.7804 19.7048C14.175 20.0984 14.8144 20.0984 15.21 19.7048C15.6047 19.3111 15.6047 18.6717 15.21 18.2781L6.92952 10.0005L15.209 1.72302C15.6037 1.32937 15.6037 0.689976 15.209 0.295329C14.8144 -0.098321 14.174 -0.098321 13.7794 0.295329L4.78545 9.28616C4.39687 9.67575 4.39687 10.3252 4.78645 10.7139Z" fill="#1F4168"/>
                    </svg>
                    <span className='ps-3'>Client Logo</span></h4>
                </Link>
                </div>  
                </div>
            </div>
        </div>
        <div className='add-portfolio-body'>
            <div className="container-fluid">
                <div className='row'>
                    <div className='col-12'>
                        <div className='card add-portfolio-card'>
                            <div className='card-body'>
                                <form>
                                <div className='slectd-image pb-4'>
                                    {selectedImage && <img src={selectedImage} alt="Selected" width={120} height={106} loading='lazy'/>}
                                </div>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className="mb-4">
                                            <label className="form-label">Upload Logo</label>
                                            <input type="file" name='image' className="form-control hint" ref={fileInputRef} accept=".jpg, .jpeg, .png" onChange={handleImageChange}
                                            /> 
                                            <XError message={errors.image} />
                                        </div>
                                    </div> 
                                    <div className='col-sm-6'>
                                        <div className="mt-3">
                                        <div className='footer-button pt-2'>
                                            <button type='button' className='cancel-button'>Cancel</button>  
                                                <button type='button' className='submit-button'>Submit</button>  
                                        </div>
                                        </div>
                                    </div>
                                </div>  
                                
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </Layouts>
  )
}

export default AddLogo