import React, { useState } from 'react'
import '../css/login.css'
import XError from '../../common/xError';
import userService from '../../../services/userService';
import Swal from 'sweetalert2';

const ForgotPassword = () => {
    const [email,setEmail] = useState("");
    const [error,setError] = useState("");
	const [isLoading, setIsLoading] = useState(false);
    const [message,setMessage] = useState({
		type:"",
		message:""
	});

	const handleChange = (event) => {
		setError("")
        setEmail(event.target.value)
    };

    const onSubmit = async (event) => { 
		event.preventDefault();
		setIsLoading(true) 
		//check validation
		if(validationCheck()){ 
			//call services api
			await userService.forgotPassword({
				email: email 
			}).then(res => { 
				console.log(res)
				Swal.fire(res.data.result);
				setError("");
				setIsLoading(false)
			}).catch(err => { 
				 
				let error = ""
				console.log("error---",err?.response.data.data)
				const {email} = err?.response?.data.data;

				if(email !== undefined || email !== "" || email !== null){
					error =  email;
				} 

				setError(error);
				setIsLoading(false)
			})
		}else{
			setIsLoading(false)
		}
    }
    const validationCheck = () => {
		
		let flag = true;

		if(email === "" || email === null || email === undefined){
			setError("The email field is required.");
			flag = false;
		}else{
			if(!validateEmail(email)){
				setError("The email must be a valid email address.");
				flag = false;
			}
		}

		return flag;
	}
    // check email validation
	const validateEmail = (email) => {
		return String(email)
		  .toLowerCase()
		  .match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		  );
	  };
  return (
    <div className="authincation-content">
		<section className="login-part">
			<div className="container">
				<div className='login'>
				<div className='login-card'>
					<div className='card'> 
					<form>
						<div className='login-header text-center'>
							<img src='../image/calidig-logo.png' alt='no_image' className="img-fluid"/>
						</div>
						<div className='card-body'>
							<div className="mb-4">
								<label htmlFor="email" className="form-label">Email</label>
								<input type="text" className="form-control"
								 id="email" placeholder="Enter email"
								 onChange={handleChange}
								 value={email}
								 autoComplete="off"/>
								 <XError message={error} />
							</div>
							<div className="form-login-button pb-5">
								{
									!isLoading ?
									<button type="button" onClick={onSubmit}>Send Reset Link</button>
									:
									<button type="button">Loading...</button>
									
								}
							</div> 
						</div>
					</form>
				    </div>
				</div>
				</div>
				
			</div>
		</section>
	</div>
  )
}

export default ForgotPassword