import {jwtDecode} from 'jwt-decode';

// Get the token from localStorage
export const getToken = () => {
    return localStorage.getItem('token');
};

// Check if the token is expired
const isTokenExpired = (token) => {
    if (!token) return true;

    try {
        const decodedToken = jwtDecode(token); // Decode the token to get the payload
        const currentTime = Date.now() / 1000; // Current time in seconds

        // If the current time is greater than the token's expiration time, it's expired
        return decodedToken.exp < currentTime;
    } catch (error) {
        console.error('Error decoding token', error);
        return true; // In case decoding fails, consider the token as expired
    }
};

// Check if the user is authenticated
export const isAuthenticated = () => {
    const token = getToken(); // Retrieve the token

    // If there is no token or the token is expired, return false
    if (!token || isTokenExpired(token)) {
        return false;
    }

    // If the token is valid (i.e., not expired), return true
    return true;
};
