import React, { useEffect, useRef } from 'react';
import './css/careers.css';
import { NavLink } from 'react-router-dom';
import Layouts from './layout/layout';
import webService from '../services/webService';
import { useState } from 'react';

const Careers = () => {

    const jobsRef = useRef(null);
    const [applyjob, setApplyJob] = useState([])  
    const [loader, setLoader] = useState(false)
    // part-3 image click 
    useEffect(() => {
        const images = document.getElementsByClassName('img_box'); 
        for (let i = 0; i < images.length; i++) {
            images[i].addEventListener('click', function () {
                // Remove 'selected' class from all images
                for (let j = 0; j < images.length; j++) {
                    images[j].classList.remove('active_img');
                    images[j].classList.remove('effect_img');
                }

                // Add 'selected' class to the clicked image
                this.classList.add('active_img');
                this.classList.add('effect_img');
            });
        }
    }, [])

    // Scroll to Jobs
    const scrollToJobs = () => {
        jobsRef.current.scrollIntoView()
    }

    useEffect(() => {
        getjobDetails(); 
      },[])
      const getjobDetails = async () => { 
        await webService.getjob().then(res => { 
        //   console.log("jobs",res.data.result) 
          setApplyJob(res.data?.result)
          setLoader(true)
        })
      }

    return (
        <>
        <Layouts>
            <div className='careers'>
                <section className="part-1">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6" data-aos="fade-down"
                                    data-aos-duration={1000}>
                                <div
                                    className="d-flex flex-column"
                                    data-aos="fade-down"
                                    data-aos-duration={1000}
                                >
                                    <div className="heading-1">
                                        Join our Team at <span style={{ color: "#F58634" }}>Calidig</span>
                                    </div>
                                    <div className="_description">
                                      
                                        At Calidig, we value ownership, innovation, and teamwork. Join us in 
                                        shaping the future of digital solutions and be part of a supportive 
                                        and inspiring work environment. Together, let's push boundaries 
                                        and create meaningful experiences for our stakeholders.
                                    </div>
                                    <div className="view_open_jobs">
                                        <button onClick={scrollToJobs}>View Open Jobs</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div
                                    className="group_people"
                                    data-aos="fade-left"
                                    data-aos-duration={1000}
                                >
                                    <img src="./image/careers/svg/people.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="part-2">
                    <div className="container">
                        <div className="row justify-content-between align-items-center flexible_container">
                            <div
                                className="col-12 col-xl-6"
                                data-aos="fade-up"
                                data-aos-duration={1000}
                            >
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="card_box top_row">
                                            <span className="img_box">
                                                <img src="image/careers/svg/benefits_1.svg" alt="no images" />
                                            </span>
                                            <div className="card_box_title">Remote Workspace</div>
                                            <div className="card_box_text text-left-justify">
                                            Our flexible remote workspace empowers you to work from anywhere, ensuring freedom and convenience for sustained productivity, no matter your location!
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="card_box top_row upper_box">
                                            <span className="img_box">
                                                <img src="image/careers/svg/benefits_2.svg" alt="no images" />
                                            </span>
                                            <div className="card_box_title">Great Team</div>
                                            <div className="card_box_text text-left-justify">
                                            Our company is driven by a terrific team of bright individuals who collaborate to produce incredible outcomes and deliver exceptional service to our clients.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="card_box bottom_row">
                                            <span className="img_box">
                                                <img src="image/careers/svg/benefits_3.svg" alt="no images" />
                                            </span>
                                            <div className="card_box_title">Networking Opportunities</div>
                                            <div className="card_box_text text-left-justify">
                                            Collaborating with clients and partners creates valuable networking opportunities, enhancing employees' career prospects.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="card_box bottom_row upper_box">
                                            <span className="img_box">
                                                <img src="image/careers/svg/benefits_4.svg" alt="no images" />
                                            </span>
                                            <div className="card_box_title">Work-Life Balance</div>
                                            <div className="card_box_text text-left-justify">
                                            We prioritize work-life balance, promoting a culture where employees excel professionally and enjoy fulfilling personal lives, promoting happiness and productivity.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-5">
                                <div className="details">
                                    <div
                                        className="heading"
                                        data-aos="fade-up"
                                        data-aos-duration={1000}
                                    >
                                        <span style={{ color: "#F58634" }}>Benefits</span> of working with
                                        Calidig
                                    </div>
                                    <p className="text-left-justify" data-aos="fade-left" data-aos-duration={1000}>
                                    You get to learn and grow with smart and supportive coworkers, experience a work-life balance, and have access to incredible training and career opportunities. Furthermore, there are other advantages to keeping you happy and healthy while doing what you enjoy! 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="part-3" hidden>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="heading" data-aos="fade-up" data-aos-duration={1000}>
                                    We are located in{" "}
                                    <span style={{ color: "#F58634" }}>Chandigarh</span>
                                </div>
                                <p data-aos="fade-up" data-aos-duration={1000}>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting
                                    industry Lorem Ipsum has been the industry's
                                </p>
                            </div>
                            <div className="col-12" data-aos="fade-up" data-aos-duration={1000}>
                                <div className="img_container_wrapper">
                                    <div className="img_box">
                                        <img src="image/careers/img_1.png" alt="" />
                                    </div>
                                    <div className="img_box center_img">
                                        <img src="image/careers/img_2.png" alt="" />
                                    </div>
                                    <div className="img_box">
                                        <img src="image/careers/img_3.png" alt="" />
                                    </div>
                                    <div className="img_box">
                                        <img src="image/careers/img_4.png" alt="" />
                                    </div>
                                    <div className="img_box center_img">
                                        <img src="image/careers/img_5.png" alt="" />
                                    </div>
                                    <div className="img_box">
                                        <img src="image/careers/img_6.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="part-4" ref={jobsRef}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="heading" data-aos="fade-up" data-aos-duration={1000}>
                                    Current <span style={{ color: "#F58634" }}>Openings</span>
                                </div>
                                <p data-aos="fade-up" data-aos-duration={1000}>
                                 We are actively seeking talented individuals to join our team at Calidig.
                                </p>
                            </div>
                            <div className="col-12" data-aos="fade-right" data-aos-duration={1000}>
                            {  loader ?
                                <div className="">
                                    {
                                        applyjob && applyjob.length > 0 ? (
                                            <div className="row justify-content-center">{
                                            applyjob.map((job) => {
                                            const isSingleRecord = applyjob.length === 1;
                                            const colClass = isSingleRecord ? "col-12 col-lg-4" : "col-12 col-lg-4";
                                       return (
                                            <div className={colClass} key={job.id}>
                                                <NavLink to='/apply' state={{ jobDetails: job }} style={{textDecoration:"none"}}>
                                                <div
                                                    className="job_box"
                                                >
                                                    <div className="job_role">{job.jobTitles?.title}</div>
                                                    <div className="job_experience">
                                                        <span>Experience :</span> {job.experience} years
                                                    </div>
                                                    <div className="job_position">
                                                        <span>Open Position :</span> {job.position}
                                                    </div>
                                                    <div className="apply_now_button">
                                                    <NavLink to='/apply' state={{jobDetails: job, status: "1" }} style={{textDecoration:"none"}}>
                                                            <button>Apply Now</button>
                                                    </NavLink>
                                                    </div>
                                                </div>
                                                </NavLink>
                                            </div>
                                        )})
                                    }</div>
                                        ):(
                                              <div style={{textAlign:"center"}}>
                                                <img src="/image/svg/notfound.svg" alt="" width={200}/>
                                                <h4><b>Oops!</b></h4>
                                                <span>Data Not Found</span>
                                              </div>
                                          )
                                    }
                                    
                                </div>
                                : '' }
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layouts>
        </>

    )
}

export default Careers