import React from 'react'
import './css/recent_work.css';
import { NavLink } from 'react-router-dom';
import Layouts from './layout/layout';

const Recent = () => {

    return (
        <>
        <Layouts>
            <div className="recent_work">
                {/* part-1 */}
                <section className="part-1">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-12 col-lg-5">
                                <div className="d-flex flex-column">
                                    <div
                                        className="heading-2"
                                        data-aos="fade-up"
                                        data-aos-duration={1000}
                                    >
                                        UI/UX <span className="heading-1">Design</span>{" "}
                                    </div>
                                    <div
                                        className="_description"
                                        data-aos="fade-right"
                                        data-aos-duration={1000}
                                    >
                                        At vero eos et accusamus iusto odio dignissimos ducimus qui
                                        blanditiis praesentium voluptatum deleniti atque corrupti quos at
                                        dolores molestias excepturi sint occaecati cupiditate.
                                    </div>
                                    <div
                                        className="more_features_button d-flex align-items-center"
                                        data-aos="fade-up"
                                        data-aos-duration={1000}
                                    >
                                        <button>View More Details</button>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-12 col-lg-6"
                                data-aos="fade-left"
                                data-aos-duration={1000}
                            >
                                <div className="group_people">
                                    <img
                                        src="./image/Recent_work/recent_part1.png"
                                        alt=""
                                        className="img-fluid bigger"
                                    />
                                    <img
                                        src="/image/Recent_work/recent_part1_small.png"
                                        alt=""
                                        className="img-fluid smaller"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* part-2 */}
                <section className="part-11">
                    <div className="container">
                        <div className="row main-row">
                            <div className="col-12 col-lg-6">
                                <div className="d-flex flex-column">
                                    <div
                                        className="heading-2"
                                        data-aos="fade-up"
                                        data-aos-duration={1000}
                                    >
                                        UI/UX <span className="heading-1">Design</span>{" "}
                                    </div>
                                    <div
                                        className="_description"
                                        data-aos="fade-right"
                                        data-aos-duration={1000}
                                    >
                                        At vero eos et accusamus iusto odio dignissimos ducimus qui
                                        blanditiis praesentium voluptatum deleniti atque corrupti quos at
                                        dolores molestias excepturi sint occaecati cupiditate.
                                    </div>
                                    <div
                                        className="more_features_button d-flex align-items-center"
                                        data-aos="fade-up"
                                        data-aos-duration={1000}
                                    >
                                        <button>View More Details</button>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-12 col-lg-6"
                                data-aos="fade-left"
                                data-aos-duration={1000}
                            >
                                <div className="row sub-row">
                                    <div className="col-lg-6">
                                        <img
                                            src="./image/Recent_work/recent_part1.png"
                                            className="big img-fluid"
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <img
                                            src="./image/Recent_work/recent_part1_small.png"
                                            className="small img-fluid"
                                            alt=""
                                        />
                                    </div>
                                </div>
                                {/* <div class="group_people">
                        <img  src="./image/Recent_work/recent_part1.png" alt="" class="img-fluid bigger">
                        <img  src="/image/Recent_work/recent_part1_small.png" alt="" class="img-fluid smaller">
                    </div> */}
                            </div>
                        </div>
                    </div>
                </section>
                {/* part-3 */}
                <section className="part-2">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div
                                className="col-lg-5"
                                data-aos="fade-right"
                                data-aos-duration={1000}
                            >
                                <div className="uiux">
                                    <div
                                        className="more_features_button d-flex align-items-center"
                                        data-aos="fade-up"
                                        data-aos-duration={1000}
                                    >
                                        <button>UI/UX Design</button>
                                    </div>
                                    <h3>Default Slider</h3>
                                    <p>
                                        At vero eos et accusamus iusto odio dignissimos ducimus qui
                                        blanditiis praesentium voluptatum deleniti atque corrupti quos at
                                        dolores molestias excepturi sint occaecati cupiditate.
                                    </p>
                                    <p>
                                        At vero eos et accusamus iusto odio dignissimos ducimus qui
                                        blanditiis praesentium voluptatum deleniti atque corrupti quos at
                                        dolores molestias excepturi sint.
                                    </p>
                                    <div
                                        className="more_features_button2 d-flex align-items-center"
                                        data-aos="fade-up"
                                        data-aos-duration={1000}
                                    >
                                        <button>View Live Website</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6" data-aos="fade-left" data-aos-duration={1000}>
                                <div className="uiux2">
                                    <h3>How We Completed</h3>
                                    <p>
                                        At vero eos et accusamus iusto odio dignissimos ducimus qui
                                        blanditiis praesentium voluptatum deleniti atque corrupti quos at
                                        dolores molestias excepturi sint occaecati cupiditate.
                                    </p>
                                    <div
                                        className="accordion accordion-flush"
                                        id="accordionFlushExample"
                                    >
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingOne">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseOne"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseOne"
                                                >
                                                    The Mission
                                                </button>
                                            </h2>
                                            <div
                                                id="flush-collapseOne"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="flush-headingOne"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Your content goes here. Edit or remove this text inline or
                                                    in the module Content settings. You can also style every
                                                    aspect of this content in the module Design settings and
                                                    even apply custom CSS to this text in the module Advanced
                                                    settings.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingTwo">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapseTwo"
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseTwo"
                                                >
                                                    Final Result
                                                </button>
                                            </h2>
                                            <div
                                                id="flush-collapseTwo"
                                                className="accordion-collapse collapse"
                                                aria-labelledby="flush-headingTwo"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    Your content goes here. Edit or remove this text inline or
                                                    in the module Content settings. You can also style every
                                                    aspect of this content in the module Design settings and
                                                    even apply custom CSS to this text in the module Advanced
                                                    settings.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* part-4 */}
                <section className="part-3">
                    <div className="container">
                        <div className="row main-row">
                            <div
                                className="col-md-2 center"
                                data-aos="fade-up"
                                data-aos-duration={1000}
                            >
                                <div className="content">
                                    <img src="image/Recent_work/part3_img1.png" alt="" />
                                    <h3>Launched</h3>
                                    <p>2018 q1</p>
                                </div>
                            </div>
                            <div
                                className="col-md-2 center"
                                data-aos="zoom-in"
                                data-aos-duration={1000}
                            >
                                <div className="content center_logo">
                                    <img src="image/Recent_work/part3_img2.png" alt="" />
                                    <h3>Platform</h3>
                                    <p>Wordpress</p>
                                </div>
                            </div>
                            <div
                                className="col-md-2 center"
                                data-aos="fade-up"
                                data-aos-duration={1000}
                            >
                                <div className="content">
                                    <img src="image/Recent_work/part3_img3.png" alt="" />
                                    <h3>Customer</h3>
                                    <p>OWN Co.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* part-5 */}
                <section className="part-4">
                    <div className="container">
                        <div className="row main-row">
                            <div className="col-12 col-lg-4">
                                <div className="headings">
                                    <h2 data-aos="fade-down" data-aos-duration={1000}>
                                        Check Out Main <span>Highlights</span>
                                    </h2>
                                    <p data-aos="fade-right" data-aos-duration={1000}>
                                        At vero eos et accusamus iusto odio dignissimos ducimus qui
                                        blanditiis praesentium voluptatum deleniti atque corrupti quos at
                                        dolores molestias excepturi sint occaecati cupiditate.
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-lg-8">
                                <div className="row">
                                    <div
                                        className="col-12 col-lg-4"
                                        data-aos="fade-left"
                                        data-aos-duration={1000}
                                    >
                                        <div className="two-img">
                                            <img
                                                src="image/Recent_work/recent_part1.png"
                                                className="img1 img-fluid"
                                                alt=""
                                            />
                                            <img
                                                src="image/Recent_work/part4_imgII.png"
                                                className="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="col-lg-4"
                                        data-aos="zoom-in"
                                        data-aos-duration={1000}
                                    >
                                        <img
                                            src="image/Recent_work/part4_img2.png"
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        className="col-lg-4"
                                        data-aos="fade-right"
                                        data-aos-duration={1000}
                                    >
                                        <img
                                            src="image/Recent_work/part4_img3.png"
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Recent Work */}
                <section style={{ backgroundColor: "#FCFAF8", marginBottom: 100 }}>
                    <div className="work-div" style={{ paddingTop: 100, paddingBottom: 100 }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h4 data-aos="fade-up" data-aos-duration={1000}>
                                        Recent <span style={{ color: "#F58634" }}>Works </span>
                                    </h4>
                                    <p
                                        style={{
                                            fontSize: 16,
                                            fontWeight: 500,
                                            lineHeight: '20px',
                                            textAlign: "center",
                                            marginBottom: 40
                                        }}
                                        data-aos="fade-up"
                                        data-aos-duration={1000}
                                    >
                                        Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry Lorem Ipsum has been the industry's.
                                    </p>
                                </div>
                            </div>
                            <div
                                className="row justify-content-between"
                                data-aos="fade-left"
                                data-aos-duration={1000}
                            >
                                <div className="col-12 col-lg-4">
                                    <div className="slide">
                                        <div className="slide_content">
                                            <NavLink to="/recent_work">
                                                <button>View Details</button>
                                            </NavLink>
                                        </div>
                                        <img src="image/recent_works_food.png" />
                                    </div>
                                    <div className="slide_bottom_container">
                                        <h4>E-Food Website</h4>
                                        <div className="chip_container">
                                            <div className="chip">UI/UX Design</div>
                                            <div className="chip">WordPress</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <div className="slide">
                                        <div className="slide_content">
                                            <NavLink to="/recent_work">
                                                <button>View Details</button>
                                            </NavLink>
                                        </div>
                                        <img src="image/recent_works_pizza.png" />
                                    </div>
                                    <div className="slide_bottom_container">
                                        <h4>John’s Pizza Delivery App</h4>
                                        <div className="chip_container">
                                            <div className="chip">UI/UX Design</div>
                                            <div className="chip">IOS</div>
                                            <div className="chip">Android</div>
                                            <div className="chip">Node.js</div>
                                            <div className="chip">MySQL</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <div className="slide">
                                        <div className="slide_content">
                                            <NavLink to="/recent_work">
                                                <button>View Details</button>
                                            </NavLink>
                                        </div>
                                        <img src="image/recent_works_food.png" />
                                    </div>
                                    <div className="slide_bottom_container">
                                        <h4>E-Food Website</h4>
                                        <div className="chip_container">
                                            <div className="chip">UI/UX Design</div>
                                            <div className="chip">WordPress</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layouts>
        </>
    )
}

export default Recent