import React, { useState } from 'react'
import Layouts from '../layout/layouts'
import '../css/setting.css'
import settingService from '../../../services/settingService';
import Swal from 'sweetalert2';
import XError from '../../common/xError';
import { useEffect } from 'react';
import baseURL from '../../utils/baseURL';
import { useRef } from 'react';
import DOMPurify from 'dompurify';
const Setting = () => {

    const [logoimage, setLogoImage] = useState(null);
    const [faviconimage, setFaviconImage] = useState(null);
    const [fields, setFields] = useState({ company_logo: null, favicon: null, email: '', phone: '', address: [] });
    const [errors, setError] = useState({ company_logo: null, favicon: null, email: '', phone: '' });
    const token = localStorage.getItem('token');
    const [addressfield, setAddressFields] = useState(['']); 
    const [fieldValidations, setFieldValidations] = useState([true]); 
    const fileInputRef = useRef(null);
    const faviInputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const addaddressField = () => {
        setAddressFields([...addressfield, '']);
        setFieldValidations([...fieldValidations, false])
    };
    const removeField = (index) => {   
        const newFields = addressfield.filter((_, i) => i !== index);
        const newValidations = fieldValidations.filter((_, i) => i !== index);  
        setAddressFields(newFields);   
        newValidations.splice(index, 1); // Remove the validation status for the removed field
        setFieldValidations(newValidations);
    };

    const handlefieldChange = (index, event) => {
        const sanitizedValue = DOMPurify.sanitize(event.target.value); // Sanitize the input
        const newFields = [...addressfield];
        newFields[index] = sanitizedValue; // Set sanitized value
        setAddressFields(newFields);
        setFields({ ...fields, address: newFields });
    
        const newValidations = newFields.map(field => field.trim() !== '');
        setFieldValidations(newValidations);
    };
    

    // set input data
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFields((prevData) => ({ ...prevData, [name]:DOMPurify.sanitize(value)}));
    };

    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        if (file && allowedFile(file.name)) {
            setFields({ ...fields, company_logo: event.target.files[0] ? event.target.files[0] : fields.company_logo });
            setLogoImage(URL.createObjectURL(file));
        }else{
            setLogoImage(null); 
            setError({...errors,company_logo:"Please select jpg,png and jpeg file."})
        }
    }
    const handlefaviconChange = (event) => {
        const file = event.target.files[0];
        if (file && allowedFile(file.name)) {
            setFields({ ...fields, favicon: event.target.files[0] ? event.target.files[0] : fields.favicon });
            setFaviconImage(URL.createObjectURL(file));
        }else{
            setFaviconImage(null); 
            setError({...errors,favicon:"Please select jpg,png and jpeg file."})
        }
    }
    useEffect(() => {
        getCompanyDetails(false); 
    }, [])
    const getCompanyDetails = async (status) => {
        // console.log(status)
        await settingService.getCompany(token).then(res => {
            // console.log(res.data?.result)
            setFields({
                company_logo: res.data?.result[0]?.logo ? res.data?.result[0].logo : "",
                favicon: res.data?.result[0]?.favicon ? res.data?.result[0].favicon : "",
                email: res.data?.result[0]?.email ? res.data?.result[0].email : "",
                phone: res.data?.result[0]?.phone ? res.data?.result[0].phone : "", 
            })
            const address = res.data?.result[0]?.addresses && res.data?.result[0]?.addresses.map((add) =>{ 
                return add ? add.address : "" ;
            })
            setAddressFields(address || [])
            setLogoImage(null); 
            setFaviconImage(null) 
            if(status){
                onReset()
            }  
        })
    }

    const onCompany = async (event) => {
        event.preventDefault();  
        setIsLoading(true) 
        if (checkValidation()) {
            const postData = new FormData();
            postData.append('company_logo', fields.company_logo);
            postData.append('favicon', fields.favicon);
            postData.append('email', fields.email);
            postData.append('phone', fields.phone);
            if(addressfield.length > 0){
                postData.append('address', JSON.stringify(addressfield));
            }else {
                postData.append('address', '[]');
            }
             

            await settingService.createComapny(token, postData).then(async (res) => { 
                setIsLoading(false)
                Swal.fire("Settings saved successfully."); 
            }).catch((err) => {
                setIsLoading(false)
                Swal.fire("Setting not saved.");
            })
        }else{
            setIsLoading(false);
        }
    }

    const checkValidation = () => {
        //  console.log("validation",state);
        let error = {
            company_logo: null, favicon: null, email: '', phone: '', address: ''
        };

        const { company_logo, favicon, email, phone,address } = fields;

        let flag = true;

        if (company_logo === "" || company_logo === null || company_logo === undefined) {
            error.company_logo = "The logo field is required.";
            flag = false;
        }

        if (favicon === "" || favicon === null || favicon === undefined) {
            error.favicon = "The favicon field is required.";
            flag = false;
        }
        if (email === "" || email === null || email === undefined) {
            error.email = "The email field is required.";
            flag = false;
        } else {
            if (!validateEmail(email)) {
                error.email = "The email must be a valid email address.";
                flag = false;
            }
        }
        if (phone === "" || phone === null || phone === undefined) {
            error.phone = "The phone field is required.";
            flag = false;
        }else {
            if (!validatePhoneNumber(phone)) {
                error.phone = 'Please enter a valid 10-digit phone number.';
                flag = false;
            }
        }
        
        // Check if at least one address field is filled
        // const isAtLeastOneAddressFilled = addressfield.some(addr => addr.trim() !== '');
        // if (!isAtLeastOneAddressFilled) {
        //     error.address = "Please fill at least one address field.";
        //     flag = false;
        // } 
        
         
        // const hasInvalidFields = fieldValidations.some((validation) => !validation);
       
        // if (hasInvalidFields) {
        //     error.address = "Please fill in all required address fields.";
        //     flag = false; 
        // }
 
        setError({ ...error });
        // console.log(errors)
        return flag;
    };
     
    
    const onReset = ()=>{ 
          fileInputRef.current.value = '';
          faviInputRef.current.value = '';
          setError({
            company_logo: null,
            favicon: null,
            email: '', 
            phone: '',
            address:''
        });
      }
    const allowedFile = (filename) => {
        const allowedExtensions = ['jpg', 'jpeg', 'png', 'svg'];
        const extension = filename.split('.').pop().toLowerCase();
        return allowedExtensions.includes(extension);
    };

    // check email validation
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    // Function to validate the phone number format
    const validatePhoneNumber = (phoneNumber) => { 
        const phonePattern = /^\d{10}$/; // Assuming 10 digits for a mobile number
        return phonePattern.test(phoneNumber);
    };
    return (
        <Layouts>
            <div className='admin-portfolio'>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h4 className="dash-title">Settings</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='setting-body'>
                    <div className="container-fluid">
                        <div className='row'>
                            <div className='col-12'>
                                <div className='card setting general-card'> 
                                    <div className='card-body'>
                                        <form onSubmit={onCompany}>
                                            <div className='row'>
                                                <div className='col-sm-6'>
                                                    <div className="mb-4">
                                                        <label className="form-label">Logo</label>
                                                        <input type="file" className="form-control hint" name="company_logo" ref={fileInputRef} accept=".jpg, .jpeg, .png"
                                                            onChange={handleLogoChange}
                                                        />
                                                        <XError message={errors.company_logo} />
                                                        <div className='slectd-image pt-1 ps-4'>
                                                            {logoimage && logoimage ? <img src={logoimage} alt="Selected" width={200} height={106} /> : <img src={fields.company_logo ?baseURL + "/" + fields.company_logo:""} alt="Selected" width={120} height={100} />}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className="mb-4">
                                                        <label className="form-label">Favicon</label>
                                                        <input type="file" name='favicon' className="form-control hint"  ref={faviInputRef} accept=".jpg, .jpeg, .png"
                                                            onChange={handlefaviconChange}
                                                        />
                                                        <XError message={errors.favicon} />
                                                        <div className='slectd-image pt-1 ps-4'>
                                                            {faviconimage && faviconimage ? <img src={faviconimage} alt="Selected" width={120} height={106} /> : <img src={fields.favicon ?baseURL + "/" + fields.favicon:""} alt="Selected" width={120} height={106} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-6'>
                                                    <div className="mb-4">
                                                        <label className="form-label">Email</label>
                                                        <input type="text" className="form-control" value={fields.email} name="email" onChange={handleChange}
                                                            placeholder="Enter email"  maxLength={50}/>
                                                        <XError message={errors.email} />
                                                    </div>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <div className="mb-4">
                                                        <label className="form-label">Phone Number</label>
                                                        <input type="text" className="form-control" value={fields.phone} name="phone" onChange={handleChange}
                                                            placeholder="Enter phone number" maxLength={10}/>
                                                        <XError message={errors.phone} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                    {addressfield.map((field, index) => (
                                                        <div className='col-sm-6'>
                                                        <div key={index}>
                                                            <div className="mb-4">
                                                                <label className="form-label">Address</label>
                                                                <input type="text" className="form-control" name="address" value={field} onChange={(e) => handlefieldChange(index, e)}
                                                                    placeholder="Enter address" maxLength={150} required/>
                                                            </div> 
                                                            <p className='remove_service' onClick={() => removeField(index)}>Remove</p>
                                                        </div>
                                                        </div> 
                                                    ))} 
                                                   <XError message={errors.address} /> 
                                                <p className='add-service-btn pt-2' onClick={addaddressField}>Add Address</p>
                                                    
                                                <div className='genaral-btn pb-2 pt-3'> 
                                                    <button type='button'onClick={()=>getCompanyDetails(true)} className='cancel-button'>Cancel</button> 
                                                    {isLoading ? (
                                                        <button type='button' className='submit-button'>Loading...</button> 
                                                    ):(
                                                        <button type='submit' className='submit-genarl-button'>Save</button>
                                                    )}
                                                   
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layouts>
    )
}

export default Setting