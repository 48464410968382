import React from "react";

const DigitalExperience = ({bgcolor="#FCFAF8"}) => {
  return (
    <>
      <section style={{ backgroundColor: bgcolor }}>
        <div
          className="digital-experience"
          style={{ paddingTop: 100, paddingBottom: 100 }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-7 box">
                <h4 data-aos="fade-up" data-aos-duration={1000}>
                  Digital <span style={{ color: "#F58634" }}>Experience </span>
                </h4>
                <p
                  className="card-text text-left-justify"
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                    lineHeight: "20px",
                    color: "#707070",
                    marginBottom: 12,
                  }}
                  data-aos="fade-right"
                  data-aos-duration={1000}
                >
                  Our journey is a testament to our commitment to delivering
                  top-notch digital experiences. So, elevate your business by
                  choosing us with intuitive user interfaces, robust
                  cybersecurity, and scalable cloud infrastructure.
                </p>
                <p
                  className="card-text text-left-justify"
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                    lineHeight: "20px",
                    color: "#707070",
                    marginBottom: 12,
                  }}
                  data-aos="fade-right"
                  data-aos-duration={1000}
                >
                  Additionally, transform your digital landscape with our
                  expertise, where every click opens doors to efficiency and
                  quality.
                </p>
                {/* <div data-aos="fade-up" data-aos-duration={1000}>
                  <button
                    id="expand_collapse_btn"
                    style={{
                      borderRadius: 24,
                      fontSize: 18,
                      fontWeight: 500,
                      color: "#FFFFFF",
                      padding: "10px 20px",
                      margin: "20px 0 32px 0",
                    }}
                  >
                    Learn More
                  </button>
                </div> */}
              </div>
              <div
                className="col-12 col-lg-5"
                data-aos="fade-left"
                data-aos-duration={1000}
              >
                <img
                  src="image/svg/Digital-Exp.svg"
                  alt=""
                  style={{ width: "100%" }}
                  className="img-fluid"
                  height={370}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DigitalExperience;
