import axios from "axios";
import baseURL from "../components/utils/baseURL.js";


class RoleAndPermission {

    async allRoles(token) {
        return await axios.get(`${baseURL}/role`,{
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    async addNewRole(token,data){
        return await axios.post(`${baseURL}/role`,data,{
            headers:{
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        });

    }

    async deleteRole(token,id){
        return await axios.delete(`${baseURL}/role/${id}`,{
            headers:{
                Authorization: `Bearer ${token}`,
            },
        });
    }

    async editRole(token,id,data){
        return await axios.put(`${baseURL}/role/${id}`,data,{
            headers:{
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        });
    }

    async fetchRole(token,id){
        return await axios.get(`${baseURL}/role/${id}`,{
            headers:{
                Authorization: `Bearer ${token}`,
            },
        });
    }

    async allPermissions(token){
        return await axios.get(`${baseURL}/role/permission`,{
            headers:{
                Authorization: `Bearer ${token}`,
            },
        });
    }
}

export default new RoleAndPermission();