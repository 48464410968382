import React, { useEffect, useRef } from "react";
import "./css/services.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { NavLink } from "react-router-dom";
import Layouts from "./layout/layout";
import { useState } from "react";
import webService from "../services/webService";
import baseURL from "./utils/baseURL";
import "swiper/css/virtual";
import { Navigation, Autoplay, Virtual } from "swiper/modules";
import SwiperCore from "swiper";
import DigitalExperience from "./Home/DigitalExperience";
import RecentWork from "./Home/RecentWork";

// Install the Swiper modules
SwiperCore.use([Virtual, Autoplay]);
const Services = (props) => {
  const swiperRef = useRef(null);
  const servicesRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const [service, setService] = useState([]);
  const [logo, setLogo] = useState([]);

  // Fetching logo and service data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [logoRes, serviceRes] = await Promise.all([
          getLogo(),
          getService(),
        ]);
        setLogo(logoRes.data?.result || []);
        setService(serviceRes.data?.result || []);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoader(true);
      }
    };

    fetchData();
  }, []);

  // Get client logos
  const getLogo = async () => {
    return await webService.getClientLogo();
  };

  // Get services
  const getService = async () => {
    return await webService.getService();
  };

  // Swiper event listener setup
  useEffect(() => {
    const swiper = swiperRef.current?.swiper;

    if (swiper) {
      const handleSlideChange = () => {
        const isBeginning = swiper.isBeginning;
        const isEnd = swiper.isEnd;

        // Handle left button state
        const leftButton = document.getElementsByClassName("swiper-left")[0];
        if (leftButton) {
          leftButton.style.opacity = isBeginning ? 0.5 : 1;
          leftButton.style.cursor = isBeginning ? "not-allowed" : "pointer";
        }

        // Handle right button state
        const rightButton = document.getElementsByClassName("swiper-right")[0];
        if (rightButton) {
          rightButton.style.opacity = isEnd ? 0.5 : 1;
          rightButton.style.cursor = isEnd ? "not-allowed" : "pointer";
        }
      };

      swiper.on("slideChange", handleSlideChange);

      // Cleanup event listener
      return () => {
        swiper.off("slideChange", handleSlideChange);
      };
    }
  }, []);
  const getServiceLink = (service) => {
    switch (service?.categories?.name) {
      case "Platform":
        return "/platform";
      case "Mobile":
        return "/mobile";
      case "UI/UX Design":
        return "/uiux";
      case "Startup":
        return "/startup";
      default:
        return "/services";
    }
  };
  // Scroll to Services Section
  const scrollToServices = () => {
    servicesRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Layouts>
        <div className="services">
          {/* part-1 */}
          <section className="part-1">
            <div className="container">
              <div className="row align-items-center">
                <div
                  className="col-12 col-lg-6"
                  data-aos="fade-down"
                  data-aos-duration={1000}
                >
                  <div className="d-flex flex-column">
                    <div
                      className="heading-1"
                      data-aos="fade-down"
                      data-aos-duration={1000}
                    >
                      Services <span className="heading-2">We Offer!</span>
                    </div>
                    <div
                      className="_description text-left-justify"
                      data-aos="fade-right"
                      data-aos-duration={1000}
                    >
                      Explore a spectrum of customized services designed to
                      address your unique digital requirements. From website and
                      mobile app development to cloud solutions and database
                      management, our team is here to transform your ideas into
                      successful, tech-powered realities. Let us be your partner
                      on the journey to digital excellence!
                    </div>
                    <div
                      className="more_features_button d-flex"
                      data-aos="fade-down"
                      data-aos-duration={1000}
                    >
                      <button onClick={scrollToServices}>View Services</button>
                    </div>
                  </div>
                </div>
                <div
                  className="col-12 col-lg-6"
                  data-aos="fade-left"
                  data-aos-duration={1000}
                >
                  <div className="group_people">
                    <img
                      src="/image/services/svg/offer_img.svg"
                      alt="Service Offering"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* part-2 */}
          <section className="part-2">
            <div className="container first_container">
              <div className="row justify-content-between align-items-center">
                <div className="col-12 col-lg-5">
                  <div
                    className="online_bussines"
                    data-aos="fade-right"
                    data-aos-duration={1000}
                  >
                    <img
                      src="/image/services/svg/online_bussines.svg"
                      alt="Online Business"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="d-flex flex-column">
                    <div
                      className="heading"
                      data-aos="fade-up"
                      data-aos-duration={1000}
                    >
                      We Provide a Complete Solution for your{" "}
                      <span className="highlighted">Online Business</span>
                    </div>
                    <div
                      className="_description"
                      data-aos="fade-left"
                      data-aos-duration={1000}
                    >
                      At Calidig, we've got you covered with
                      everything your online business needs to thrive - a
                      complete package for your success!
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container second_container second_container_section">
              <div className="row justify-content-between align-items-center">
                <div className="col-12 col-lg-6">
                  <div className="d-flex flex-column">
                    <div
                      className="heading"
                      data-aos="fade-up"
                      data-aos-duration={1000}
                    >
                      We’re a Team of{" "}
                      <span className="highlighted">Professional People </span>
                       around the World
                    </div>
                    <div
                      className="_description text-left-justify"
                      data-aos="fade-right"
                      data-aos-duration={1000}
                    >
                      At Calidig, our team is made up of
                      skilled professionals from different parts of the world.
                      Together, we work collaboratively to bring you the best
                      solutions for your business needs. Our diverse backgrounds
                      and expertise allow us to tackle challenges with
                      creativity and deliver top-notch results.
                    </div>
                    <div
                      className="_description text-left-justify "
                      data-aos="fade-right"
                      data-aos-duration={1000}
                    >
                      With a shared passion for technology and innovation, we
                      are committed to helping your business thrive in the
                      global marketplace.
                    </div>
                  </div>
                </div>
                <div
                  className="col-12 col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration={1000}
                >
                  <div className="online_bussines">
                    <img
                      src="/image/services/svg/team_meeting.svg"
                      alt="Team of Professionals"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* part-3 */}
          {/* <section className="part-33">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-12 col-xl-10">
                  <div className="d-flex flex-column align-items-center">
                    <div className="services_intro text-center col-12 col-lg-10">
                      <h2
                        className="heading"
                        data-aos="fade-up"
                        data-aos-duration={1000}
                      >
                        Our Most Popular Features to make your <br />
                        Life easier than ever before
                      </h2>
                    </div>
                    <div
                      className="services_info container"
                      data-aos="fade-up"
                      data-aos-duration={1000}
                    >
                      <div className="row justify-content-between">
                        {[
                          {
                            title: "Managed Bills and Costs",
                            detail:
                              "We ensure efficient IT expense management while optimizing service value.",
                            imgSrc: "/image/services/svg/card_img.svg",
                          },
                          {
                            title: "Custom Client Portal",
                            detail:
                              "We have designed a hub to streamline communication, collaboration, and access to vital IT resources, setting your business apart in the digital era.",
                            imgSrc: "/image/services/svg/card_img2.svg",
                          },
                          {
                            title: "Team to Handle requests",
                            detail:
                              "Our expert team is ready to manage all your requests with precision and expertise.",
                            imgSrc: "/image/services/svg/card_img3.svg",
                          },
                          {
                            title: "Safe and Secure Payments",
                            detail:
                              "We ensure the protection of your financial transactions and data through robust encryption and authentication protocols.",
                            imgSrc: "/image/services/svg/card_img4.svg",
                          },
                        ].map((service, index) => (
                          <div
                            key={index}
                            className={`service ${
                              index < 2 ? "upper_service" : "bottom_service"
                            } d-flex align-items-center col-12 col-lg-6`}
                          >
                            <div className="img">
                              <img
                                src={service.imgSrc}
                                alt={service.title}
                                loading="lazy"
                                width="100%"
                                height="auto"
                              />
                            </div>
                            <div className="details">
                              <h3 className="title">{service.title}</h3>
                              <p className="detail">{service.detail}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          {/* <section className="ellipse_bottom"></section> */}
          {/* our services */}
          <section ref={servicesRef}>
      <div
        className="service-div"
        style={{
          paddingTop: 100,
          paddingBottom: 100,
          background: "#ffffff",
        }}
        key="2"
      >
        <div className="container second-div">
          <div className="row">
            <div className="col-12">
              <h4 data-aos="fade-up" data-aos-duration={1000}>
                Our <span style={{ color: "#F58634" }}>Services</span>
              </h4>
              <p data-aos="fade-up" data-aos-duration={1000}>
                Elevate your digital presence with our comprehensive services.
              </p>
            </div>
          </div>

          <div className="row card-hover">
            <div className="col-12 d-flex" style={{ position: "relative" }}>
              <div className="swiper-button-box-new swiper-left">
                <img
                  src="image/svg/left-arrow (2).svg"
                  alt="Left arrow"
                  className="swiper-button-prev-new"
                />
              </div>

              <Swiper
                className="mySwiper"
                ref={swiperRef}
                modules={[Navigation]}
                breakpoints={{
                  320: { slidesPerView: 1, spaceBetween: 0 },
                  992: { slidesPerView: 3, spaceBetween: 0 },
                }}
                navigation={{
                  nextEl: ".swiper-button-next-new",
                  prevEl: ".swiper-button-prev-new",
                }}
              >
                {service.map((service, index) => (
                  <SwiperSlide key={index}>
                    <NavLink to={getServiceLink(service)}>
                      <div className="slider-pt">
                        <div className="card" data-aos="fade-left" data-aos-duration={1000}>
                          <div className="card-body">
                            <div className="card-image" style={{ height: "76px" }}>
                              <img
                                src={service.logo ? `${baseURL}/${service.logo}` : ''}
                                alt={service.name || "No images available"}
                                className="img-fluid"
                                width={76}
                                height={76}
                              />
                            </div>
                            <h3 className="card-title">{service?.name}</h3>
                            <p className="card-text pb-3" style={{ fontWeight: 400, fontSize: 14, height: "7em" }}>
                              {service?.aboutUs}
                            </p>
                            <p className="card-link">
                              Learn More{" "}
                              <span style={{ paddingLeft: 5 }}>
                                <img
                                  src="image/svg/arrow-right.svg"
                                  alt="Arrow right"
                                  className="img-fluid"
                                  style={{ width: 20, height: 20 }}
                                />
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="swiper-button-box-new swiper-right">
                <img
                  src="image/svg/right-arrow.svg"
                  alt="Right arrow"
                  className="swiper-button-next-new"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

          {/* Recent Work */}

          <RecentWork recentWork={props}/>
          {/* google icons */}
          {/* <section className="part-44">
            <div className="container">
              <div
                className=" justify-content-between align-items-center icons"
                data-aos="fade-up"
                data-aos-duration={1000}
                style={{ height: "50px" }}
              >
                <Swiper
                  // modules={[Virtual]}
                  spaceBetween={50}
                  slidesPerView={2}
                  // virtual
                  // autoplay={{
                  //   delay: 1000,
                  //   disableOnInteraction: false,
                  //   reverseDirection: true,
                  // }}
                  // direction="horizontal"
                  breakpoints={{
                    // Define breakpoints for different screen sizes
                    992: {
                      slidesPerView: 2, // Number of slides to show on tablets
                      spaceBetween: 10,
                    },
                  }}
                >
                  {logo.map((slideContent, index) => (
                    <SwiperSlide key={slideContent} virtualIndex={index}>
                      <img
                        src={
                          slideContent.logo
                            ? baseURL + "/" + slideContent.logo
                            : ""
                        }
                        alt="no images"
                        className="img-fluid centered-image"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </section> */}
          {/* Digital Experience */}
          <DigitalExperience bgcolor="#fff"/>
        </div>
      </Layouts>
    </>
  );
};

export default Services;
