import React, { useState, useEffect, useRef } from "react";
import Layouts from "../layout/layouts";
import "../css/portfolioaddDetails.css";
import Select from "react-select";
import portfolioService from "../../../services/portfolioService";
import technologyService from "../../../services/technologyService";
import portfolioDetailService from "../../../services/portfolioDetailService";
import Swal from "sweetalert2";
import baseURL from "../../utils/baseURL.js";
import { Link, useNavigate, useParams } from "react-router-dom";
import XError from "../../common/xError";
import DOMPurify from "dompurify";

const PortfolioaddDetails = () => {
  const [portfolio, setPortfolio] = useState([]);
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(false);
  const [technology, setTechnology] = useState([]);
  const navigate = useNavigate();
  const ref = useRef();
  const [images, setImages] = useState([]);
  const [multiimages, setMultiimages] = useState([]);
  const { id } = useParams();
  const [inputFields, setInputFields] = useState([
    {
      options: "",
      link: "",
    },
  ]);
  const [mainimage, setMainimage] = useState("");
  const [subimages, setSubimages] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);
  // const handleClick = () =>{
  //     setView(!view);
  // };
  useEffect(() => {
    TechnologyList();
    portfolioDetailsList();

    getportfolioDetails();
  }, []);

  const [formData, setFormData] = useState({
    id: "",
    portfolioId: "",
    image: null,
    shortDescription: "",
    aboutUs: "",
    completitionDetails: "",
    businessProblem: "",
    solution: "",
    technology: "",
    customerName: "",
    launchedYear: "",
    platform: "",
    //links: ''
  });

  const [errors, setError] = useState({
    shortDescription: "",
    aboutUs: "",
    // completitionDetails: "",
    businessProblem: "",
    solution: "",
    technology: "",
    customerName: "",
    launchedYear: "",
    platform: "",
  });
  //     const handleClick2 = () => {
  //     setLinks([...links, <div className='row'>
  //       <div className="col-sm-6">
  //       <select class="form-select form-select-lg"  onChange={handleSelectOption}>
  //           <option selected className='select-option'>Link</option>
  //           <option value="1">Android</option>
  //           <option value="2">IOS</option>
  //           <option value="3">Web</option>
  //           <option value="4">Others</option>
  //       </select>
  //       </div>
  //       <div className="col-sm-6"><input name='image2' className='form-control mb-2' type="text" /></div>

  //        </div> ]);
  //  };

  const portfolioDetailsList = async () => {
    await portfolioService.getPortfolio(token).then((res) => {
      const options = res.data?.result.map((d) => ({
        id: d.id,
        name: d.title,
      }));
      setPortfolio(options);
    });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file && allowedFile(file.name)) {
      setFormData({
        ...formData,
        image: event.target.files[0] ? event.target.files[0] : formData.image,
      });
      setSelectedImage(URL.createObjectURL(file));
    } else {
      setSelectedImage(null);
      setError({ ...errors, image: "Please select jpg,png and jpeg file" });
    }
  };

  const allowedFile = (filename) => {
    const allowedExtensions = ["jpg", "jpeg", "png"];
    const extension = filename.split(".").pop().toLowerCase();
    return allowedExtensions.includes(extension);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]:DOMPurify.sanitize(value)}));
  };
  const onSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    if (checkValidation()) {
      const token = localStorage.getItem("token");
      const postData = new FormData();

      //console.log(formData.portfolioId)
      postData.append("id", formData.id);
      postData.append("portfolioId", id);
      console.log(formData.image);
      if (formData.image != undefined) {
        postData.append("image", formData.image);
      }

      postData.append("shortDescription", formData.shortDescription);
      postData.append("businessProblem", formData.businessProblem);
      postData.append("solution", formData.solution);
      postData.append("technology", formData.technology);
      postData.append("customerName", formData.customerName);
      postData.append("launchedYear", formData.launchedYear);
      if (typeof formData.platform === "object") {
        postData.append("platform", formData.platform.value);
      } else {
        postData.append("platform", formData.platform);
      }

      postData.append("aboutUs", formData.aboutUs);
      postData.append("completitionDetails", formData.completitionDetails);

      inputFields.forEach((list, index) => {
        postData.append(`link`, list.link);
        postData.append(`options`, list.options);
      });
      images.forEach((img, index) => {
        postData.append(`images`, img);
      });

      await portfolioDetailService
        .updatePortfolio(token, postData)
        .then(async (res) => {
          setIsLoading(false);
          Swal.fire("Portfolio details updated successfully.");
          navigate("/admin-portfolios");
        })
        .catch((err) => {
          setIsLoading(false);
          Swal.fire("Portfolio details not updated.");
        });
    } else {
      setIsLoading(false);
    }
  };

  const TechnologyList = async () => {
    await technologyService.getTechnology(token).then((res) => {
      const options = res.data?.result.map((d) => ({
        id: d.id,
        name: d.name,
      }));
      setTechnology(options);
    });
  };

  const handleImageChangeMulti = async (e) => {
    const selectedImages = Array.from(e.target.files);
    setImages(selectedImages);

    const selectedFIles = [];
    const targetFiles = e.target.files;
    const targetFilesObject = [...targetFiles];
    targetFilesObject.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file));
    });

    setMultiimages(selectedFIles);
  };

  // const handleSelect = (data) =>{

  //   setFormData({ ...formData, portfolioId: data.value})
  // }

  // const handleSelectOption = (e) =>{
  //   console.log(e)
  // }

  const handleSelectPlatform = (data) => {
    // console.log("hii-",data)
    // plat =  { value: content.id, label: content.name }

    setFormData({ ...formData, platform: data });
  };

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        options: "",
        link: "",
      },
    ]);
  };
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  const RemoveImage = (id, type) => {
    Swal.fire({
      title: "Are you sure",
      text: "you want to delete this?",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      customClass: {
        confirmButton: "custom-confirm-button",
        cancelButton: "custom-cancel-button",
        popup: "custom-swal-popup",
      },
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await portfolioDetailService
          .imagesDelete(token, id, type)
          .then(async () => {
            getportfolioDetails();
            Swal.fire("Image deleted successfully");
          })
          .catch((err) => {
            Swal.fire("Not delete");
          });
      }
    });
  };
  const handleChange2 = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  };
  // Get selected options
  const selectedOptions = inputFields.map((field) => field.options);

  // Define the available options for the dropdown
  const allOptions = [
    { value: "1", label: "Android" },
    { value: "2", label: "IOS" },
    { value: "3", label: "Web" },
    { value: "4", label: "Others" },
  ];
  const getportfolioDetails = async () => {
    await portfolioDetailService.editPortfolio(token, id).then((res) => {
      console.log(res.data.result);
      if(res.data?.result.length > 0){
   
     
      const content = res.data.techlist.find(
        (item) => item.id === res.data.result[0].platform
      );
      const portflo = res.data.portfolioslist.find(
        (item2) => item2.id === res.data.result[0].portfolioId
      );
      let plat = "";
      if (content !== undefined) {
        if (content.id != null && content.id !== undefined && content.id !=="") {
          plat = { value: content.id, label: content.name };
        }
      }
      setFormData({
        id: res.data.result[0].id,
        portfolioId: { value: portflo.id, label: portflo.title },
        shortDescription: res.data.result[0].shortDescription,
        aboutUs: res.data.result[0].aboutUs,
        completitionDetails: res.data.result[0].completitionDetails,
        businessProblem: res.data.result[0].businessProblem,
        solution: res.data.result[0].solution,
        technology: res.data.result[0].technology,
        customerName: res.data.result[0].customerName,
        launchedYear: res.data.result[0].launchedYear,
        platform: plat,
      });
      setSelectedImage(null);
      if (res.data.result[0].image != "" && res.data.result[0].image != null) {
        setMainimage(baseURL + "/" + res.data.result[0].image);
      }

      if (
        res.data.result[0].portfoliosImageLink != "" &&
        res.data.result[0].portfoliosImageLink != null
      ) {
        setSubimages(res.data.result[0].portfoliosImageLink);
      } else {
        setSubimages([]);
      }

      if (res.data.result[0].portfolioslink.length > 0) {
        const list = [...inputFields];
        list[0]["link"] = res.data.result[0].portfolioslink[0].link;
        setInputFields(list);
      }

      if (res.data.result[0].portfolioslink.length > 0) {
        const arrayInput = [];
        res.data.result[0].portfolioslink.forEach((item, index) => {
          arrayInput.push({
            options: item.type,
            link: item.link,
          });
        });
        setInputFields(arrayInput);
      }
    }
    });
  };

  const checkValidation = () => {
    //  console.log("validation",state);
    let error = {
      shortDescription: "",
      aboutUs: "",
      // completitionDetails: "",
      businessProblem: "",
      solution: "",
      technology: "",
      customerName: "",
      launchedYear: "",
      platform: "",
    };

    const {
      shortDescription,
      aboutUs,
      completitionDetails,
      image,
      businessProblem,
      solution,
      technology,
      customerName,
      launchedYear,
      platform,
    } = formData;

    let flag = true;

    if (
      shortDescription === "" ||
      shortDescription === null ||
      shortDescription === undefined ||
      shortDescription.trim() === ""
    ) {
      error.shortDescription = "The short description field is required.";
      flag = false;
    }
    // if (
    //   completitionDetails === "" ||
    //   completitionDetails === null ||
    //   completitionDetails === undefined ||
    //   completitionDetails.trim() === ""
    // ) {
    //   error.completitionDetails = "The completition details field is required.";
    //   flag = false;
    // }
    if (
      businessProblem === "" ||
      businessProblem === null ||
      businessProblem === undefined ||
      businessProblem.trim() === ""
    ) {
      error.businessProblem = "The business problem field is required.";
      flag = false;
    }
    if (
      aboutUs === "" ||
      aboutUs === null ||
      aboutUs === undefined ||
      aboutUs.trim() === ""
    ) {
      error.aboutUs = "The description field is required.";
      flag = false;
    }
    if (
      solution === "" ||
      solution === null ||
      solution === undefined ||
      solution.trim() === ""
    ) {
      error.solution = "The solution field is required.";
      flag = false;
    }
    if (
      technology === "" ||
      technology === null ||
      technology === undefined ||
      technology.trim() === ""
    ) {
      error.solution = "The technology field is required.";
      flag = false;
    }
    if (
      customerName === "" ||
      customerName === null ||
      customerName === undefined ||
      customerName.trim() === ""
    ) {
      error.customerName = "The customer name field is required.";
      flag = false;
    }
    if (
      launchedYear === "" ||
      launchedYear === null ||
      launchedYear === undefined ||
      launchedYear.trim() === ""
    ) {
      error.launchedYear = "The launched year field is required.";
      flag = false;
    }
    if (platform === "" || platform === null || platform === undefined) {
      error.platform = "The platform field is required.";
      flag = false;
    }

    setError({ ...error });
    return flag;
  };

  return (
    <>
      <Layouts>
        <div className="add-portfolioDetails">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12s">
                  <Link to="/admin-portfolios" className="add-link-color">
                    <h4 className="dash-title">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M4.78645 10.7139L13.7804 19.7048C14.175 20.0984 14.8144 20.0984 15.21 19.7048C15.6047 19.3111 15.6047 18.6717 15.21 18.2781L6.92952 10.0005L15.209 1.72302C15.6037 1.32937 15.6037 0.689976 15.209 0.295329C14.8144 -0.098321 14.174 -0.098321 13.7794 0.295329L4.78545 9.28616C4.39687 9.67575 4.39687 10.3252 4.78645 10.7139Z"
                          fill="#1F4168"
                        />
                      </svg>
                      <span className="ps-3">Edit Portfolio Details</span>
                    </h4>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="add-portfolio-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card add-portfolio-card">
                    <form className="card-body">
                      <div className="slectd-image pb-4">
                        {selectedImage != null ? (
                          <div className="custom-image-list">
                            <img src={selectedImage} alt="Selected" />{" "}
                          </div>
                        ) : mainimage != "" ? (
                          <div className="custom-image-list">
                            {" "}
                            <img src={mainimage} alt=""></img>
                            <div className="custom-image-button">
                              {" "}
                              <button
                                type="button"
                                className="btn btn-outline- btn-close-custom mt-3 mb-5"
                                hidden
                                onClick={(evnt) =>
                                  RemoveImage(formData.id, "main")
                                }
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="mb-4">
                            <label className="form-label">Main Image</label>
                            <input
                              type="file"
                              name="image"
                              className="form-control hint"
                              accept=".jpg, .jpeg, .png"
                              ref={ref}
                              onChange={handleImageChange}
                            />
                          </div>
                        </div>
                        {/* <div className="col-sm-6">
                   <div className="mb-4 image-custom-list">

                   {(selectedImage != null) ? <div className="custom-image-list">  <img src={selectedImage} alt="Selected" /> </div>: (mainimage != '')? <div className="custom-image-list"> <img src={mainimage}></img>  <div className="custom-image-button"> <button type="button" className="btn btn-outline- btn-close-custom mt-3 mb-5" onClick={(evnt)=>RemoveImage(formData.id, 'main')}>Delete</button> </div></div> :''}
                    </div>
                   </div> */}
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="mb-4">
                            <label
                              for="exampleFormControlTextarea1"
                              class="form-label"
                            >
                              Project Short Description
                            </label>
                            <textarea
                              class="form-control"
                              placeholder="Enter project short description"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              name="shortDescription"
                              value={formData.shortDescription}
                              onChange={handleChange}
                            ></textarea>
                            <XError message={errors.shortDescription} />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb-4">
                            <label
                              for="exampleFormControlTextarea1"
                              class="form-label"
                            >
                              About an app
                            </label>
                            <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              placeholder="Enter about us"
                              rows="3"
                              name="aboutUs"
                              value={formData.aboutUs}
                              onChange={handleChange}
                              maxlength="1000"
                            ></textarea>
                            <XError message={errors.aboutUs} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="mb-4">
                            <label
                              for="exampleFormControlTextarea1"
                              class="form-label"
                            >
                              How We Completed Details
                            </label>
                            <textarea
                              class="form-control"
                              placeholder="Enter we completed details"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              name="completitionDetails"
                              onChange={handleChange}
                              value={formData.completitionDetails}
                            ></textarea>
                            <XError message={errors.completitionDetails} />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb-4">
                            <label
                              for="exampleFormControlTextarea1"
                              class="form-label"
                            >
                              Business Problem
                            </label>
                            <textarea
                              class="form-control"
                              placeholder="Enter business detail"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              name="businessProblem"
                              onChange={handleChange}
                              value={formData.businessProblem}
                            ></textarea>
                            <XError message={errors.businessProblem} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="mb-4">
                            <label
                              for="exampleFormControlTextarea1"
                              class="form-label"
                            >
                              The Solution
                            </label>
                            <textarea
                              class="form-control"
                              placeholder="Enter solution details"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              name="solution"
                              onChange={handleChange}
                              value={formData.solution}
                              maxlength="255"
                            ></textarea>
                            <XError message={errors.solution} />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb-4">
                            <label
                              for="exampleFormControlTextarea1"
                              class="form-label"
                            >
                              Technology
                            </label>
                            <textarea
                              class="form-control"
                              placeholder="Enter technologies detail"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              name="technology"
                              onChange={handleChange}
                              value={formData.technology}
                              maxlength="255"
                            ></textarea>
                            <XError message={errors.technology} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="mb-4">
                            <label
                              for="exampleFormControlTextarea1"
                              class="form-label"
                            >
                              Launched Year
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="launchedYear"
                              value={formData.launchedYear}
                              onChange={(event) => {
                                const input = event.target.value;
                                const numericInput = input.replace(
                                  /[^\d.]/g,
                                  ""
                                ); // Remove all non-numeric characters
                                // Check if the input contains only numbers or not
                                const isNumeric = /^(\d*\.)?\d*$/.test(
                                  numericInput
                                );
                                if (isNumeric) {
                                  setFormData({
                                    ...formData,
                                    launchedYear: numericInput,
                                  });
                                }
                              }}
                              placeholder="Enter experience"
                              maxLength={4}
                              readOnly={
                                !/^(\d*\.)?\d*$/.test(formData.launchedYear)
                              }
                            />
                            {/* <textarea class="form-control" placeholder='Enter launch year' id="exampleFormControlTextarea1"
                      rows="1" name="launchedYear" onChange={handleChange} value={formData.launchedYear}></textarea> */}
                            <XError message={errors.launchedYear} />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb-4">
                            <label
                              for="exampleFormControlTextarea1"
                              class="form-label"
                            >
                              Customer Name
                            </label>
                            <textarea
                              class="form-control"
                              placeholder="Enter customer name"
                              id="exampleFormControlTextarea1"
                              rows="1"
                              name="customerName"
                              onChange={handleChange}
                              value={formData.customerName}
                              maxLength={20}
                            ></textarea>
                            <XError message={errors.customerName} />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb-4">
                            <label className="form-label">Platform</label>
                            <Select
                              options={technology.map((tech) => ({
                                value: tech.id,
                                label: tech.name,
                              }))}
                              placeholder="Select platform"
                              className="custom-select"
                              name="platform"
                              onChange={handleSelectPlatform}
                              value={formData.platform}
                            />
                            <XError message={errors.platform} />
                          </div>
                        </div>
                      </div>
                      <div>
                        {inputFields.map((data, index) => {
                          // Filter out options that are already selected by other fields
                          const availableOptions = allOptions.filter(
                            (option) =>
                              !selectedOptions.includes(option.value) ||
                              option.value === data.options
                          );

                          return (
                            <div className="row m-b-5" key={index}>
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <label className="form-label">
                                    Select Option {data.options}
                                  </label>
                                  <select
                                    className="form-select form-select-lg mb-5"
                                    aria-label=".form-select-lg example"
                                    name="options"
                                    onChange={(evnt) =>
                                      handleChange2(index, evnt)
                                    }
                                    value={data.options}
                                  >
                                    <option value="">Select an option</option>
                                    {availableOptions.map((option) => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="mb-2">
                                  <label className="form-label">Link</label>
                                  <input
                                    type="text"
                                    onChange={(evnt) =>
                                      handleChange2(index, evnt)
                                    }
                                    name="link"
                                    className="form-control"
                                    placeholder="Enter link"
                                    value={data.link}
                                  />
                                </div>
                              </div>

                              <div className="col-md-12 col-cm-6">
                                {inputFields.length !== 1 ? (
                                  <p
                                    className="add-service-btn btn-edit-delete pt-2"
                                    id="del"
                                    onClick={() => removeInputFields(index)}
                                  >
                                    Delete
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })}

                        <div className="row">
                          <div className="col-sm-12 text-right">
                            <p
                              type="button"
                              className="add-service-btn"
                              onClick={addInputField}
                            >
                              Add New
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <div className="row">
                 <div className="col-sm-6"></div>
                 <div className="col-sm-6">
                   <div>{view && <div className='img_box'></div>} </div>
                 </div>
               </div> */}
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="mb-4">
                            <label className="form-label ">
                              Select Multiple Images
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              multiple
                              accept=".jpg, .jpeg, .png"
                              onChange={handleImageChangeMulti}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            {subimages.map((data, index) => {
                              return (
                                <div className="col-12 col-lg-3">
                                  <div className="custom-image-list mb-4">
                                    <img
                                      src={baseURL + "/" + data.image}
                                      alt=""
                                    ></img>
                                    <div className="custom-image-button">
                                      {" "}
                                      <button
                                        type="button"
                                        className="btn btn-outline- btn-close-custom"
                                        onClick={(evnt) =>
                                          RemoveImage(data.id, "sub")
                                        }
                                      >
                                        Delete
                                      </button>{" "}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="row">
                            {multiimages != null
                              ? multiimages.map((url) => {
                                  return (
                                    <div className="col-12 col-lg-3">
                                      <div className="custom-image-list mb-4">
                                        <img src={url} alt="Selected" />
                                      </div>
                                    </div>
                                  );
                                })
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="footer-button pt-2">
                        <button
                          type="button"
                          onClick={getportfolioDetails}
                          className="cancel-button"
                        >
                          Cancel
                        </button>
                        {isLoading ? (
                          <button type="button" className="submit-button">
                            Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={onSubmit}
                            className="submit-button"
                          >
                            Update
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layouts>
    </>
  );
};

export default PortfolioaddDetails;
