import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';



const Header = () => {

    const [show, setShow] = useState(false);
    const refPopup = useRef(null);
    const refPopupBtn = useRef(null);
    const collapseRef = useRef(null);
    const location = useLocation();
  
    // Close Popup when click outside of it
    useEffect(() => { 
        const handleOutsideClick = (event) => {
            if (refPopup.current && !refPopup.current.contains(event.target)) {
                setShow(false);
            }
            else if (refPopupBtn.current && refPopupBtn.current.contains(event.target)) {
                setShow(!show);
            }
        };
        
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);
    
    
   
    // active parent menu click on child menu
    const activeClass = (route) => {
        if (typeof route === "string") {
            if (location.pathname === route) { 
                return "nav-link active";
            }
        }

        if (typeof route === "object") {
            let classType = "nav-link";
            route.map((r) => {
                const pathSegments = location.pathname.split("/");
                const second = r.split("/");
                const secondurl = second[1];
                const targetSegment = pathSegments[1];
                if (targetSegment === secondurl) {
                    classType = "nav-link active";
                }
                // if (location.pathname === r) {
                //     classType = "nav-link active";
                // }
            });

            return classType;
        }

        return "nav-link";
    }

    

    // Close Popup when click on links
    const handleServicesClick = () => {
        setShow(!show);
    }

    // Close Nav Popup when click on links
    const hideBars = () => {
        collapseRef.current.setAttribute("class", "navbar-collapse collapse");
    }

    return (
        <header className="header_wrapper">
            <nav className="navbar navbar-expand-lg">
                <div className="container">
                    <NavLink to="/" className="navbar-brand">
                        <span style={{display:"inline-block",width:"11em"}}>
                            <img
                                src="/image/calidig-logo.svg"
                                alt=""
                                className="img-fluid site-logo"
                                data-aos="zoom-in"
                                data-aos-duration={1000}
                                data-aos-once="true"
                                width={220}
                                height={28}
                                loading='eager'
                                
                            />
                        </span> 
                    </NavLink>
                    <div id="animation2">
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarText"
                            aria-controls="navbarText"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon" />
                        </button>
                    </div>
                    <div className="collapse navbar-collapse " id="navbarText" ref={collapseRef}>
                        <ul className="navbar-nav mb-lg-0">
                            <li
                                className="nav-item"
                                data-aos="fade-down"
                                data-aos-duration={1000}
                                data-aos-once="true"
                            >
                                <NavLink to='/' className="nav-link"  exact="true" activeclassname="active"
                                    onClick={hideBars}>
                                    Home
                                </NavLink>

                            </li>
                           
                            <li
                                className="nav-item"
                                data-aos="fade-down"
                                data-aos-duration={1000}
                                data-aos-once="true"
                            >
                                <NavLink className={activeClass([
                                    "/services",
                                    "/platform",
                                    "/mobile",
                                    "/uiux",
                                    "/startup"
                                ])} to='/services' exact="true" activeclassname="active"
                                    onClick={hideBars}>
                                    Services
                                </NavLink>
                            </li>
                            <li
                                className="nav-item"
                                data-aos="fade-down"
                                data-aos-duration={1000}
                                data-aos-once="true"
                            >
                                <NavLink className={activeClass([
                                    "/portfolio",
                                    "/recent_work",
                                    "/portfolio_details"
                                ])} to='/portfolio' exact="true" activeclassname="active"
                                    onClick={hideBars}>
                                    Portfolio
                                </NavLink>
                            </li>
                            <li
                                className="nav-item"
                                data-aos="fade-down"
                                data-aos-duration={1000}
                                data-aos-once="true"
                            >
                                <NavLink className={activeClass([
                                    "/careers",
                                    "/apply"
                                ])} to='/careers' exact="true" activeclassname="active"
                                    onClick={hideBars}>
                                    Careers
                                </NavLink>
                            </li>
                            <li
                                className="nav-item"
                                data-aos="fade-down"
                                data-aos-duration={1000}
                                data-aos-once="true"
                            >
                                <NavLink to='/about' className="nav-link"  exact="true" activeclassname="active"
                                    onClick={hideBars}>
                                    About Us
                                </NavLink>
                            </li>
                            <li
                                className="nav-item"
                                data-aos="fade-down"
                                data-aos-duration={1000}
                                data-aos-once="true"
                            >
                                <NavLink className="nav-link" to='/contact' exact="true" activeclassname="active"
                                    onClick={hideBars}>
                                    Contact Us
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="popup" id="popupp">
                        <div ref={refPopupBtn} id="animation">
                            <img
                                className="img-fluid"
                                id="Nav-btn"
                                src="../image/svg/PopUp.svg"
                                alt=""
                                data-aos="zoom-in"
                                data-aos-duration={1000}
                                data-aos-once="true"
                                width={30}
                                height={22}
                            />
                        </div>
                        {
                            show && (
                                <div ref={refPopup} className="row popuptext" id="myPopup">
                                    <div className="row gx-0">
                                        <div className="col-md-6 change">
                                            <NavLink className="service-menu" to='/platform' exact="true"
                                                onClick={handleServicesClick}>
                                                <img src="../image/header/platform_service.svg" alt="" />
                                                <h2>Platform Development</h2>
                                                <p>Creating versatile digital platforms efficiently.</p>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-6 change">
                                            <NavLink className="service-menu" to='/mobile' exact="true"
                                                onClick={handleServicesClick}>
                                                <img src="../image/header/mobile_service.svg" alt="" />
                                                <h2>Mobile App</h2>
                                                <p>Transforming Ideas into User-Friendly Apps.</p>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="row gx-0">
                                        <div className="col-md-6 change">
                                            <NavLink className="service-menu" to='/uiux' exact="true"
                                                onClick={handleServicesClick}>
                                                <img src="../image/header/UI_service.svg" alt="" />
                                                <h2>UI/UX Design</h2>
                                                <p>Elevate User Experiences with Us.</p>
                                            </NavLink>
                                        </div>
                                        <div className="col-md-6 change">
                                            <NavLink className="service-menu" to='/startup' exact="true"
                                                onClick={handleServicesClick}>
                                                <img src="../image/header/startup_service.svg" alt="" />
                                                <h2>Startup Solutions</h2>
                                                <p>Empowering Startups with Innovative Solutions.</p>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: 10, marginBottom: 10 }}>
                                        <NavLink to='/services' exact="true"
                                            onClick={handleServicesClick}>
                                            <button className="btn" id="btn">
                                                <span>VIEW ALL SERVICES</span>
                                            </button>
                                        </NavLink>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </nav>
        </header>

    )
}

export default Header