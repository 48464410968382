import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./css/about_us.css";
import Layouts from "./layout/layout";
import webService from "../services/webService";
import baseURL from './utils/baseURL'; 

const About = () => {
  const [service, setService] = useState([]);

  // Counter Animation
  useEffect(() => {
    // Get all progress-bar elements
    const progressContainers = document.querySelectorAll(".progress-bar");

    // Set up the Intersection Observer
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // When the progress-bar is in the viewport
          const progressBar = entry.target.querySelector(".percentage");
          const percentage = entry.target.querySelector(".percent");

          // Get the target count value from the data-count attribute in percentage
          const targetCount = parseInt(percentage.dataset.count, 10);

          // Start the animation for the current counter element
          animateCounter(progressBar, percentage, targetCount);

          // Stop observing the current counter element
          // observer.unobserve(counter);
        }
      });
    });

    // Start observing each progress-bar element
    progressContainers.forEach((progress) => {
      observer.observe(progress);
    });
    getService();
  }, []);

  // Animation function
  const animateCounter = (progressBar, percentage, targetCount) => {
    let currentCount = 1;
    const increment = targetCount / 100; // Increment per animation frame

    // Update the counter value every animation frame
    const updateCount = () => {
      if (currentCount < targetCount) {
        currentCount += increment;
        progressBar.style.width = `${Math.floor(currentCount)}%`;
        percentage.textContent = `${Math.floor(currentCount)}%`;
        requestAnimationFrame(updateCount);
      } else {
        progressBar.style.width = `${targetCount}%`;
        percentage.textContent = `${targetCount}%`;
      }
    };

    // Start the counter animation
    requestAnimationFrame(updateCount);
  };

  // function to fetch service data
  const getService = async () => {
    await webService.getService().then((res) => {
      setService(res.data?.result);
      console.log(res.data?.result);
    });
  };

  
  return (
    <>
      <Layouts>
        <div className="about_us">
          <section className="part-1">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-lg-6">
                  <div
                    className="d-flex flex-column"
                    data-aos="fade-down"
                    data-aos-duration={1000}
                  >
                    <div
                      className="heading-1"
                      data-aos="fade-down"
                      data-aos-duration={1000}
                    >
                      Want to Know a Little
                      <span style={{ color: "#F58634" }}> About Us?</span>
                    </div>
                    <div
                      className="_description text-left-justify"
                      data-aos="fade-right"
                      data-aos-duration={1000}
                    >
                     
                      We are passionate about leveraging technology to empower
                      businesses, offering comprehensive solutions that drive
                      growth and success in the digital era.
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div
                    className="group_people"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img src="/image/about_us/svg/group_people.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="part-2">
            <div className="container first_container">
              <div className="row justify-content-between align-items-center">
                <div className="col-12 col-lg-6">
                  <div className="d-flex flex-column" style={{ width: "95%" }}>
                    <div
                      className="heading"
                      data-aos="fade-up"
                      data-aos-duration={1000}
                    >
                      Forming <span>strong and trusted connections</span> with{" "}
                      <span>our clients</span>
                    </div>

                    <div
                      className="solution_types d-flex flex-column"
                      data-aos="fade-right"
                      data-aos-duration={1000}
                    >
                      <div className="corporate type d-flex align-items-center">
                        <div className="img">
                          <img src="/image/about_us/svg/corporate.svg" alt="" />
                        </div>
                        <div className="details">
                          <div className="title">Corporate Solutions</div>
                          <div className="detail">
                            Our corporate solutions help businesses connect and
                            collaborate effectively.
                          </div>
                        </div>
                      </div>
                      <div className="startup type d-flex align-items-center">
                        <div className="img">
                          <img src="/image/about_us/svg/startup.svg" alt="" />
                        </div>
                        <div className="details">
                          <div className="title">Startup Solutions</div>
                          <div className="detail">
                            Our startup solutions empower new businesses to
                            succeed and grow.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="solution_cent d-flex flex-column"
                      data-aos="fade-right"
                      data-aos-duration={1000}
                    >
                      <div className="cent d-flex flex-column">
                        <div className="title">Corporate Solutions</div>
                        <div className="progress-bar">
                          <span className="percentage corporate_bar" />
                          <span
                            className="percent corporate_cent"
                            data-count={40}
                          >
                            40%
                          </span>
                        </div>
                      </div>
                      <div className="cent d-flex flex-column">
                        <div className="title">Startup Solutions</div>
                        <div className="progress-bar">
                          <span className="percentage startup_bar" />
                          <span
                            className="percent startup_cent"
                            data-count={60}
                          >
                            60%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-5">
                  <div
                    className="two_people"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img src="/image/about_us/svg/two_people.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="container second_container">
              <div className="row justify-content-between align-items-center">
                <div className="col-12 col-lg-5">
                  <div
                    className="one_people"
                    data-aos="fade-right"
                    data-aos-duration={1000}
                  >
                    <img src="/image/about_us/svg/one_people.svg" alt="" />
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="d-flex flex-column">
                    <div
                      className="heading"
                      data-aos="fade-up"
                      data-aos-duration={1000}
                    >
                      Let us tell you <span>About Us</span>
                    </div>
                    {/* <div
                                        className="_description"
                                        data-aos="fade-left"
                                        data-aos-duration={1000}
                                    >
                                        Lorem ipsum is simply dummy text of printing and typesetting
                                        industry.
                                    </div> */}
                    <ul data-aos="fade-left" data-aos-duration={1000}>
                      <li>
                        <img src="/image/about_us/svg/tick.svg" alt="" />
                        <span>Innovative Works</span>
                      </li>
                      <li>
                        <img src="/image/about_us/svg/tick.svg" alt="" />
                        <span>Unique & Clean Designing</span>
                      </li>
                      <li>
                        <img src="/image/about_us/svg/tick.svg" alt="" />
                        <span>Secure and Safe Code</span>
                      </li>
                      <li>
                        <img src="/image/about_us/svg/tick.svg" alt="" />
                        <span>Delivery Excellence</span>
                      </li>
                      <li>
                        <img src="/image/about_us/svg/tick.svg" alt="" />
                        <span>Optimum Costs</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="part-3">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div
                    className="d-flex flex-column"
                    data-aos="fade-up"
                    data-aos-duration={1000}
                  >
                    <div className="heading">
                      <span className="countries_number">20+</span> locations
                      where we have <br /> successfully delivered solutions
                    </div>
                    <div className="map_loc">
                      <img src="/image/about_us/map_loc.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="part-4">
            <div className="container">
              <div className="row justify-content-evenly">
                <div className="col-12 col-lg-6">
                  <div className="tech1 d-flex flex-column">
                    <div
                      className="heading"
                      data-aos="fade-up"
                      data-aos-duration={1000}
                    >
                      <span>Technology</span> &amp; Skills
                    </div>
                    <div
                      className="_description text-left-justify"
                      data-aos="fade-right"
                      data-aos-duration={1000}
                    >
                      <p>
                        Our skill set spans a variety of latest technologies,
                        ensuring we meet your every digital need. From the
                        foundations of HTML, CSS, JavaScript, Angular, React and
                        Vue for stunning web experiences to robust server-side
                        technologies like Node.js and PHP, we've got the
                        expertise to build scalable and dynamic applications.
                        Whether you're dreaming of a powerful mobile app for iOS
                        or Android, we've got you covered.
                      </p>
                      <p>
                        Our commitment to innovation extends to cloud computing,
                        where we harness the power of AWS, Azure, Google Cloud,
                        and more. Data is at the core of our solutions, and we
                        excel in databases like MySQL, MongoDB, and PostgreSQL.
                      </p>
                      <p>
                        We thrive on staying ahead in the tech game, bringing
                        you solutions that not only meet but exceed
                        expectations. Let's embark on a technological journey
                        together!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div
                    className="tech_cent d-flex flex-column"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <div className="cent d-flex flex-column">
                      <div className="title">Mobile</div>
                      <div className="progress-bar">
                        <span className="percentage mobile_bar" />
                        <span className="percent mobile_cent" data-count={80}>
                          80%
                        </span>
                      </div>
                    </div>
                    <div className="cent d-flex flex-column">
                      <div className="title">Backend</div>
                      <div className="progress-bar">
                        <span className="percentage backend_bar" />
                        <span className="percent backend_cent" data-count={67}>
                          67%
                        </span>
                      </div>
                    </div>
                    <div className="cent d-flex flex-column">
                      <div className="title">Frontend Development</div>
                      <div className="progress-bar">
                        <span className="percentage frontend_bar" />
                        <span className="percent frontend_cent" data-count={98}>
                          98%
                        </span>
                      </div>
                    </div>
                    <div className="cent d-flex flex-column">
                      <div className="title">UI/UX Design</div>
                      <div className="progress-bar">
                        <span className="percentage ui_ux_bar" />
                        <span className="percent ui_ux_cent" data-count={90}>
                          90%
                        </span>
                      </div>
                    </div>
                    <div className="cent d-flex flex-column">
                      <div className="title">Quality Assurance</div>
                      <div className="progress-bar">
                        <span className="percentage testing_bar" />
                        <span className="percent testing_cent" data-count={70}>
                          70%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="part-5">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-lg-9">
                  <div className="d-flex flex-column align-items-center">
                    <div className="services_intro d-flex flex-column col-12 col-lg-8 text-center">
                      <div
                        className="heading"
                        data-aos="fade-up"
                        data-aos-duration={1000}
                      >
                        Our <span>Services</span>
                      </div>
                      <div
                        className="_description"
                        data-aos="fade-up"
                        data-aos-duration={1000}
                      >
                        Elevate your digital presence with our comprehensive
                        services.
                      </div>
                    </div>
                    <div
                      className="services_info container"
                      data-aos="fade-up"
                      data-aos-duration={1000}
                    >
                      <div className="row d-flex  justify-content-between  ">
                      {service &&
                        service.map((service,index) => (
                          <>
                              <div className="service d-flex align-items-center col-12 col-lg-5"  key={index}>
                                <div className="img">
                                  <img
                                    src={service.logo?baseURL+"/"+service.logo:""}
                                    alt="service_image"
                                    width={50}
                                  />
                                </div>
                                <div className="details">
                                  <div className="title">
                                   {service.name}                   
                                  </div>
                                  <div className="detail">
                                    {service.aboutUs}
                                  </div>
                                </div>
                              </div>
                          </>
                        ))}
                            </div>
                      {/* <div className="row justify-content-between">
                        <div className="service d-flex align-items-center col-12 col-lg-5">
                          <div className="img">
                            <img
                              src="/image/about_us/platform_dev.png"
                              alt=""
                            />
                          </div>
                          <div className="details">
                            <div className="title">Platform Development</div>
                            <div className="detail">
                              Creating versatile digital platforms efficiently.
                            </div>
                          </div>
                        </div>
                        <div className="service d-flex align-items-center col-12 col-lg-5">
                          <div className="img">
                            <img src="/image/about_us/mobile_app.png" alt="" />
                          </div>
                          <div className="details">
                            <div className="title">Mobile App</div>
                            <div className="detail">
                              Transforming Ideas into User-Friendly Apps.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-between">
                        <div className="service d-flex align-items-center col-12 col-lg-5">
                          <div className="img">
                            <img
                              src="/image/about_us/ui_ux_design.png"
                              alt=""
                            />
                          </div>
                          <div className="details">
                            <div className="title">UI/UX Design</div>
                            <div className="detail">
                              Elevate User Experiences with Us.
                            </div>
                          </div>
                        </div>
                        <div className="service d-flex align-items-center col-12 col-lg-5">
                          <div className="img">
                            <img src="/image/about_us/startup_sol.png" alt="" />
                          </div>
                          <div className="details">
                            <div className="title">Startup Solutions</div>
                            <div className="detail">
                              Empowering Startups with Innovative Solutions.
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="part-6">
            <div className="container first_container">
              <div className="row justify-content-center">
                <div className="col-12 col-lg-10">
                  <div className="d-flex flex-column align-items-center">
                    <div className="features_intro d-flex flex-column col-12 col-lg-8 text-center">
                      <div
                        className="heading"
                        data-aos="fade-up"
                        data-aos-duration={1000}
                      >
                        Key <span>Features</span>
                      </div>
                      <div
                        className="_description"
                        data-aos="fade-up"
                        data-aos-duration={1000}
                      >
                     
                        Discover Our Digital Solutions - "That Drive Business
                        Success".
                      </div>
                    </div>
                    <div
                      className="features_info container"
                      data-aos="fade-up"
                      data-aos-duration={1000}
                    >
                      <div className="row justify-content-between">
                        <div className="feature d-flex align-items-center col-12 col-lg-4">
                          <div className="img">
                            <img
                              src="/image/about_us/svg/full_responsive.svg"
                              alt=""
                            />
                          </div>
                          <div className="details">
                            <div className="title">Innovation</div>
                          </div>
                        </div>
                        <div className="feature d-flex align-items-center col-12 col-lg-4">
                          <div className="img">
                            <img
                              src="/image/about_us/svg/full_responsive.svg"
                              alt=""
                            />
                          </div>
                          <div className="details">
                            <div className="title">Customer-centric</div>
                          </div>
                        </div>
                        <div className="feature d-flex align-items-center col-12 col-lg-4">
                          <div className="img">
                            <img src="/image/about_us/svg/retina.svg" alt="" />
                          </div>
                          <div className="details">
                            <div className="title"> Performance</div>
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-between">
                        <div className="feature d-flex align-items-center col-12 col-lg-4">
                          <div className="img">
                            <img src="/image/about_us/svg/wordpress.svg" alt="" />
                          </div>
                          <div className="details">
                            <div className="title">Security</div>
                          </div>
                        </div>
                        <div className="feature d-flex align-items-center col-12 col-lg-4">
                          <div className="img">
                            <img
                              src="/image/about_us/svg/fast_loading.svg"
                              alt=""
                            />
                          </div>
                          <div className="details">
                            <div className="title">Customization</div>
                          </div>
                        </div>
                        <div className="feature d-flex align-items-center col-12 col-lg-4">
                          <div className="img">
                            <img
                              src="/image/about_us/svg/lovely_design.svg"
                              alt=""
                            />
                          </div>
                          <div className="details">
                            <div className="title">Support</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container second_container">
              <div className="row justify-content-center align-items-center">
                <div className="col-11">
                  <div
                    className="hiring_poster row"
                    data-aos="zoom-out-left"
                    data-aos-duration={1000}
                  >
                    <div
                      className="col-12 col-md-7"
                      data-aos="fade-up"
                      data-aos-duration={1500}
                    >
                      <p className="joining_line">Want to join us?</p>
                      <h4>We’re Hiring</h4>
                      <p>
                        We’d love to have you in our Team click the button below
                        to view Open position.
                      </p>
                      <div className="more_features_button">
                        <NavLink to="/careers">
                          <button>View Open Positions</button>
                        </NavLink>
                      </div>
                    </div>
                    <div
                      className="col-12 col-md-5"
                      data-aos="fade-up"
                      data-aos-duration={1500}
                    >
                      <img src="image/about_us/svg/hiring_img.svg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layouts>
    </>
  );
};

export default About;
