import axios from "axios";
import baseURL from "../components/utils/baseURL.js"

class DashboardServices { 

     // get jobs count
     async getCount(token) {  
        return await axios.get(`${baseURL}/dashboard`,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
      }

        // get review count
     async getJobCount(token) {  
        return await axios.get(`${baseURL}/dashboard/reviewcount`,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
      }
 
    
} 
    
export default new DashboardServices()