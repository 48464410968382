import React from 'react';
import './css/service_UIUX.css';
import { NavLink } from 'react-router-dom';
import Layouts from './layout/layout';
import baseURL from './utils/baseURL';
import RecentWork from './Home/RecentWork';

const Uiux = (props) => {
    return (
        <>
        <Layouts>
            <div className='service-UIUX'>
                {/* part-1 */}
                <section className="part-1">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6">
                                <div className="d-flex flex-column">
                                    <div
                                        className="online-part"
                                        data-aos="fade-left"
                                        data-aos-duration={1000}
                                    >
                                        <p className="online-span">UI/UX Design Services</p>
                                    </div>
                                    <div
                                        className="heading"
                                        data-aos="fade-up"
                                        data-aos-duration={1000}
                                    >
                                        Drive Conversions{" "}
                                        <span className="heading-2">Through UI and UX</span>
                                    </div>
                                    <div
                                        className="_description text-left-justify"
                                        data-aos="fade-right"
                                        data-aos-duration={1000}
                                    >
                                         Our goal is to make your software not only functional but also a joy to use. Trust us to transform your user experience and drive business success.
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-12 col-lg-6"
                                data-aos="fade-left"
                                data-aos-duration={1000}
                            >
                                <div className="group_people">
                                    <img
                                        src="/image/UI_UX/hero_img.svg"
                                        alt=""
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*part-2 */}
                <section className="part-2">
                    <div className="container">
                        <div className="row" data-aos="fade-left" data-aos-duration={1000}>
                            <div className="col-12 col-md-6 col-xl-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-image">
                                            <img src="/image/UI_UX/1.svg" alt="" className="img-fluid" />
                                        </div>
                                        <h3 className="card-title">Product Design</h3>
                                        <p className="card-p">
                                            Craft beautiful and engaging digital products that impress your
                                            customers from the first moment.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-image">
                                            <img src="/image/UI_UX/2.svg" alt="" className="img-fluid" />
                                        </div>
                                        <h3 className="card-title">User Experience Design</h3>
                                        <p className="card-p">
                                            Humanize your software user experience and provide additional
                                            value to your customers and your business.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-image">
                                            <img src="/image/UI_UX/3.svg" alt="" className="img-fluid" />
                                        </div>
                                        <h3 className="card-title">Illustration</h3>
                                        <p className="card-p">
                                            Establish a unique and unmistakable visual language of your
                                            brand and product through custom-built illustrations.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-image">
                                            <img src="/image/UI_UX/4.svg" alt="" className="img-fluid" />
                                        </div>
                                        <h3 className="card-title">User Interface Design</h3>
                                        <p className="card-p">
                                            Turn potential visitors to customers by delivering responsive
                                            and accessible user interface designs for product.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* part-3 */}
                <section className="part-3">
                    <div className="container">
                        <div className="row main-row">
                            <div
                                className="col-lg-5"
                                data-aos="fade-right"
                                data-aos-duration={1000}
                            >
                                <div className="interface_design">
                                    <img src="/image/UI_UX/interface_design.svg" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="content">
                                    <div
                                        className="heading-1"
                                        data-aos="fade-up"
                                        data-aos-duration={1000}
                                    >
                                        User Interface <span className="heading-2">Design</span>
                                    </div>
                                    <div
                                        className="_description"
                                        data-aos="fade-left"
                                        data-aos-duration={1000}
                                    >
                                        <p>
                                        Our keen eye for detail and intuitive design create visually stunning interfaces, enhancing user interactions and elevating the overall experience.
                                        </p>
                                    </div>
                                    <div>
                                        <div
                                            className="create"
                                            data-aos="fade-left"
                                            data-aos-duration={1000}
                                        >
                                            <div className="home-process-option">
                                                <div className="option_pic d-flex flex-column">
                                                    <img src="/image/UI_UX/design_pic1.svg" alt="no images" />
                                                    <span className="flex-grow-1 dotted_line" />
                                                </div>
                                                <div className="option-details">
                                                    <h5 className="option-header">Create Usable Products</h5>
                                                    <p className="option-p">
                                                        Make digital interactions fluid and ensure simple,
                                                        intuitive access to the content your users want.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="home-process-option">
                                                <div className="option_pic d-flex flex-column">
                                                    <img src="/image/UI_UX/design_pic2.svg" alt="no images" />
                                                    <span className="flex-grow-1 dotted_line" />
                                                </div>
                                                <div className="option-details">
                                                    <h5 className="option-header">
                                                        Leverage Predictable Elements
                                                    </h5>
                                                    <p className="option-p">
                                                        Functional consistency, delivered with modern UI
                                                        components, ensures predictability and trust.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="home-process-option">
                                                <div className="option_pic d-flex flex-column">
                                                    <img src="/image/UI_UX/design_pic3.svg" alt="no images" />
                                                    <span className="flex-grow-1 dotted_line" />
                                                </div>
                                                <div className="option-details">
                                                    <h5 className="option-header">
                                                        Give Users Personalized and Immersive Services
                                                    </h5>
                                                    <p className="option-p">
                                                        Drive customer engagement by offering services and
                                                        products unique to them.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="home-process-option">
                                                <div className="option_pic d-flex flex-column">
                                                    <img src="/image/UI_UX/design_pic4.svg" alt="no images" />
                                                    <span className="flex-grow-0" />
                                                </div>
                                                <div className="option-details">
                                                    <h5 className="option-header">
                                                        Communicate Brand Values and Establish Trust
                                                    </h5>
                                                    <p className="option-p">
                                                        Define your brand differentiators and build trust among
                                                        your target market with reliable, consistent designs.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* part-4 */}
                <section className="part-4">
                    <div className="container">
                        <div className="row main-row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <div
                                        className="heading-1"
                                        data-aos="fade-up"
                                        data-aos-duration={1000}
                                    >
                                        User Experience <span className="heading-2">Design</span>
                                    </div>
                                    <div
                                        className="_description"
                                        data-aos="fade-right"
                                        data-aos-duration={1000}
                                    >
                                        <p>
                                        Create unforgettable user experiences with our UI/UX design expertise.
                                        </p>
                                    </div>
                                    <div>
                                        <div
                                            className="create"
                                            data-aos="fade-right"
                                            data-aos-duration={1000}
                                        >
                                            <div className="home-process-option">
                                                <div className="option_pic d-flex flex-column">
                                                    <img src="/image/UI_UX/design_pic1.svg" alt="no images" />
                                                    <span className="flex-grow-1 dotted_line" />
                                                </div>
                                                <div className="option-details">
                                                    <h5 className="option-header">
                                                        Wireframe Development Services
                                                    </h5>
                                                    <p className="option-p text-left-justify">
                                                        We transform your ideas into amazing wireframe design
                                                        mockups. Our UI/UX design company utilizes the best user
                                                        research methods based on the nature of the business and
                                                        application.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="home-process-option">
                                                <div className="option_pic d-flex flex-column">
                                                    <img src="/image/UI_UX/design_pic2.svg" alt="no images" />
                                                    <span className="flex-grow-1 dotted_line" />
                                                </div>
                                                <div className="option-details">
                                                    <h5 className="option-header">Wireframe to Design</h5>
                                                    <p className="option-p text-left-justify">
                                                        Do you have wireframes for your MVP (minimum viable
                                                        product)? If not, get in touch with our UI/UX design
                                                        company to build a secured app. Our UI/UX design provides
                                                        an opportunity to keep telling the story rather than sum
                                                        it all up.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="home-process-option">
                                                <div className="option_pic d-flex flex-column">
                                                    <img src="/image/UI_UX/design_pic3.svg" alt="no images" />
                                                    <span className="flex-grow-0 dotted_line" />
                                                </div>
                                                <div className="option-details">
                                                    <h5 className="option-header">
                                                        Analyze and Optimize Existing Wireframe
                                                    </h5>
                                                    <p className="option-p text-left-justify">
                                                        To get rid of rough wireframe sketches, you need to
                                                        analyze and optimize your already existing wireframes. We
                                                        can guide you in this part as well being one of the best
                                                        UI/UX design companies in the USA.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5" data-aos="fade-left" data-aos-duration={1000}>
                                <div className="interface_design">
                                    <img src="/image/UI_UX/Group_40898.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* part-5 */}
                <section className="part-5">
                    <div className="container">
                        <div className="row">
                            <div
                                className="col-12 col-md-12 col-lg-10 headings"
                                data-aos="fade-up"
                                data-aos-duration={1000}
                            >
                                <span>
                                    <h2>
                                        UI/UX Design <span className="black">Services For</span> Mobile{" "}
                                        <span className="black">and</span> Web{" "}
                                        <span className="black">Platforms</span>
                                    </h2>
                                    <p>
                                    Our exceptional designs blend strategy, experience, artistry, and expertise.
                                    </p>
                                </span>
                            </div>
                            {/* Three cards section */}
                            <div className="col-12" data-aos="fade-right" data-aos-duration={1000}>
                                <div className="row cards_row">
                                    <div className="col-12 col-lg-4">
                                        <div className="job_box">
                                            <div className="job_img">
                                                <img src="/image/UI_UX/card_img1.svg" alt="" />
                                            </div>
                                            <div className="job_title">Mobile UI/UX Design Company</div>
                                            <div className="job_desc">
                                            Get a flexible user interface for your mobile applications. Let’s build a Powerful & Friendly Mobile UI for Your App Users.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <div className="job_box">
                                            <div className="job_img">
                                                <img src="/image/UI_UX/card_img2.svg" alt="" />
                                            </div>
                                            <div className="job_title">UI/UX Design – Web Solutions</div>
                                            <div className="job_desc">
                                            We deliver diverse web solutions, from enterprise SaaS to specialized portals.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <div className="job_box">
                                            <div className="job_img">
                                                <img src="/image/UI_UX/card_img3.svg" alt="" />
                                            </div>
                                            <div className="job_title">UX Design Optimization</div>
                                            <div className="job_desc">
                                            Maximize user satisfaction with Our Expert UX Design Optimization Services.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* part-6 */}
                <section className="part-6">
                    <div className="container">
                        <div className="row main-row">
                            <div
                                className="col-12 col-md-12 col-lg-8 heading"
                                data-aos="fade-up"
                                data-aos-duration={1000}
                            >
                                <h3>
                                    Our <span className="orange"> UI/UX Design</span> Process
                                </h3>
                                <p>
                                    {/* Lorem Ipsum is simply dummy text of the printing and typesetting
                                    industry Lorem Ipsum has been the industry's. */}
                                    Creating smooth, user-focused experiences with our thorough UI/UX design approach
                                </p>
                            </div>
                            <div className="col-12">
                                <div className="row" data-aos="fade-up" data-aos-duration={1000}>
                                    {/* first colum */}
                                    <div className="col-lg-4" style={{ marginTop: 100 }}>
                                        <div className="ui_content" id="one">
                                            <img src="/image/UI_UX/ui_research.svg" alt="" />
                                            <h3>1. Research</h3>
                                            <p>
                                            We gain insights to enhance your experience by analyzing competitors' features and functionalities, in addition to UI/UX.
                                            </p>
                                        </div>
                                        <div className="ui_content ui_content2">
                                            <img src="/image/UI_UX/ui_mockups.svg" alt="" />
                                            <h3>4. Initial Mockups</h3>
                                            <p>
                                            Beginning with visually appealing mockups, concepts are enhanced and integrated into user-friendly experiences.
                                            </p>
                                        </div>
                                    </div>
                                    {/* second colum */}
                                    <div className="col-lg-4">
                                        <div className="ui_content" id="two">
                                            <img src="/image/UI_UX/ui_strategy.svg" alt="" />
                                            <h3>2. Strategy</h3>
                                            <p>
                                            It ultimately involves a clear vision: aligning business goals with user needs and data-driven insights for impactful design.
                                            </p>
                                        </div>
                                        <div className="ui_content ui_content2">
                                            <img src="/image/UI_UX/ui_wireframes.svg" alt="" />
                                            <h3>5. App Screen Wireframes</h3>
                                            <p>
                                            We create wireframes as a blueprint for apps, ensuring easy use and smooth information flow in early development.
                                            </p>
                                        </div>
                                    </div>
                                    {/* third colum */}
                                    <div className="col-lg-4" style={{ marginTop: 100 }}>
                                        <div className="ui_content" id={3}>
                                            <img src="/image/UI_UX/ui_flowchrat.svg" alt="" />
                                            <h3>3. UI/UX Flow charts</h3>
                                            <p>
                                            Integral technique for mapping out the user path and interactions, allowing us to visualize and optimize the user experience at every touchpoint.
                                            </p>
                                        </div>
                                        <div className="ui_content ui_content2">
                                            <img src="/image/UI_UX/ui_interactive.svg" alt="" />
                                            <h3>6. Interactive Prototype</h3>
                                            <p>
                                            Bringing design concepts to life, allowing stakeholders and users to experience app functionality, enabling valuable feedback and precision refinement.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* for width less then 992 */}
                {/* part-7 */}
                <section className="part-7">
                    <div className="container">
                        <div className="row main-row">
                            <div
                                className="col-12 col-md-12 col-lg-8 heading"
                                data-aos="fade-up"
                                data-aos-duration={1000}
                            >
                                <h3>
                                    Our <span className="orange"> UI/UX Design</span> Process
                                </h3>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting
                                    industry Lorem Ipsum has been the industry's.
                                </p>
                            </div>
                            {/* first colum */}
                            <div className="col-12">
                                <div className="row" data-aos="fade-up" data-aos-duration={1000}>
                                    <div className="col-lg-4">
                                        <div className="ui_content">
                                            <img src="/image/UI_UX/ui_research.svg" alt="" />
                                            <h3>1. Research</h3>
                                            <p>
                                                Perform competitive UI UX analysis to understand the
                                                experience offered by company’s competitors
                                            </p>
                                        </div>
                                        <div className="ui_content2 ui_content">
                                            <img src="/image/UI_UX/ui_strategy.svg" alt="" />
                                            <h3>2. Strategy</h3>
                                            <p>
                                                Perform competitive UI UX analysis to understand the
                                                experience offered by company’s competitors
                                            </p>
                                        </div>
                                    </div>
                                    {/* second colum */}
                                    <div className="col-lg-4">
                                        <div className="ui_content">
                                            <img src="/image/UI_UX/ui_flowchrat.svg" alt="" />
                                            <h3>3. UI/UX Flow charts</h3>
                                            <p>
                                                Perform competitive UI UX analysis to understand the
                                                experience offered by company’s competitors
                                            </p>
                                        </div>
                                        <div className="ui_content2 ui_content">
                                            <img src="/image/UI_UX/ui_mockups.svg" alt="" />
                                            <h3>4. Initial Mockups</h3>
                                            <p>
                                                Perform competitive UI UX analysis to understand the
                                                experience offered by company’s competitors
                                            </p>
                                        </div>
                                    </div>
                                    {/* third colum */}
                                    <div className="col-lg-4">
                                        <div className="ui_content">
                                            <img src="/image/UI_UX/ui_wireframes.svg" alt="" />
                                            <h3>5. App Screen Wireframes</h3>
                                            <p>
                                                Perform competitive UI UX analysis to understand the
                                                experience offered by company’s competitors
                                            </p>
                                        </div>
                                        <div className="ui_content2 ui_content">
                                            <img src="/image/UI_UX/ui_interactive.svg" alt="" />
                                            <h3>6. Interactive Prototype</h3>
                                            <p>
                                                Perform competitive UI UX analysis to understand the
                                                experience offered by company’s competitors
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Recent Work */}
             <RecentWork recentWork={props}/>
            </div>
        </Layouts>
        </>

    )
}

export default Uiux