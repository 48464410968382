import React from "react";
import Layouts from "../layout/layouts";
import XError from "../../common/xError";
import { useEffect } from "react";
import jobService from "../../../services/jobService";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import { useState } from "react";
import JoditEditor from "jodit-react";
import Swal from "sweetalert2";
import DOMPurify from "dompurify";

const EditJob = () => {
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const editor = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [jobtitle, setJobTitle] = useState([]);
  const [state, setState] = useState({
    referenceCode: "",
    title: "",
    position: "",
    experience: "",
    location: "",
    jobDetails: "",
  });
  const [error, setError] = useState({
    referenceCode: "",
    title: "",
    position: "",
    experience: "",
    location: "",
    jobDetails: "",
  });
  useEffect(() => {
    getLocation();
    getJobDetails();
    getJobTitle();
  }, []);

  const getLocation = async () => {
    const getPlace = [];
    await jobService.getLocation(token).then((res) => {
      const resData = res.data?.result;
      // setLocation(res.data?.result);
      for (let index = 0; index < resData.length; index++) {
        getPlace.push(resData[index].name);
      }
    });
  };
  const getJobTitle = async () => {
    await jobService.getJobTitle(token).then((res) => {
      const resData = res.data?.result;
      setJobTitle(resData);
    });
  };
  const getJobDetails = async () => {
    await jobService.editJob(token, id).then((res) => {
      setState({
        id: id,
        referenceCode: res.data?.result[0]?.referenceCode || "",
        title: res.data?.result[0]?.title || "",
        position: res.data?.result[0]?.position || "",
        experience: res.data?.result[0]?.experience || "",
        location: res.data?.result[0]?.location || "",
        jobDetails: res.data?.result[0]?.jobDetails || "",
      });
      onReset();
    }).catch((err)=>{
console.log(err);
Swal.fire(err.response.data.message)

    });
    //   navigate("/job-list")
  };
  // const onSelect =(event) =>{
  //     setState({...state,location:event})
  //     console.log("select",event)
  // }
  // const onRemove =(event) =>{
  //     setState({...state,location:event})
  //     console.log("remove",event)
  // }

  const handleCancel = () => {
    navigate("/job-list");
  };

  const onSubmit = async () => {
    setIsLoading(true);
    if (checkValidation()) {
      await jobService
        .updateJob(token, state)
        .then(async (res) => {
          setIsLoading(false);
          Swal.fire("Job updated successfully.");
          navigate("/job-list");
        })
        .catch((err) => {
          setIsLoading(false);
          Swal.fire("Job not updated.");
        });
    } else {
      setIsLoading(false);
    }
  };

  const checkValidation = () => {
    //  console.log("validation",state);
    let error = {
      referenceCode: "",
      title: "",
      position: "",
      experience: "",
      location: "",
      jobDetails: "",
    };

    const { referenceCode, title, position, experience, location, jobDetails } =
      state;

    let flag = true;

    if (
      referenceCode === "" ||
      referenceCode === null ||
      referenceCode === undefined
    ) {
      error.referenceCode = "The job id field is required.";
      flag = false;
    }
    if (title === "" || title === null || title === undefined) {
      error.title = "The title field is required.";
      flag = false;
    }
    if (
      position === "" ||
      position === null ||
      position === undefined ||
      position.trim() === ""
    ) {
      error.position = "The open positions field is required.";
      flag = false;
    } else {
      const isPositiveNumeric = /^[1-9]\d*$/.test(position);
      if (!isPositiveNumeric) {
        error.position = "Value must be greater than zero.";
        flag = false;
      }
    }
    if (
      experience === "" ||
      experience === null ||
      experience === undefined ||
      experience.trim() === ""
    ) {
      error.experience = "The experience field is required.";
      flag = false;
    } else {
      const isPositiveNumeric = /^[1-9]\d*\.?\d*$/.test(experience);
      if (!isPositiveNumeric) {
        error.experience = "Value must be greater than zero.";
        flag = false;
      }
    }
    if (
      location === 0 ||
      location === null ||
      location === undefined ||
      location.trim() === ""
    ) {
      error.location = "The location field is required.";
      flag = false;
    }
    if (jobDetails === "" || jobDetails === null || jobDetails === undefined) {
      error.jobDetails = "The more details field is required.";
      flag = false;
    }
    setError({ ...error });
    // console.log(errors)
    return flag;
  };
  const onReset = () => {
    setError({
      referenceCode: "",
      title: "",
      position: "",
      experience: "",
      location: "",
      jobDetails: "",
    });
  };
  return (
    <Layouts>
      <div className="admin-portfolio">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12s">
                <Link to="/job-list" className="add-link-color">
                  <h4 className="dash-title">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M4.78645 10.7139L13.7804 19.7048C14.175 20.0984 14.8144 20.0984 15.21 19.7048C15.6047 19.3111 15.6047 18.6717 15.21 18.2781L6.92952 10.0005L15.209 1.72302C15.6037 1.32937 15.6037 0.689976 15.209 0.295329C14.8144 -0.098321 14.174 -0.098321 13.7794 0.295329L4.78545 9.28616C4.39687 9.67575 4.39687 10.3252 4.78645 10.7139Z"
                        fill="#1F4168"
                      />
                    </svg>
                    <span className="ps-3">Update Job</span>
                  </h4>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="add-portfolio-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card add-portfolio-card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="mb-4">
                            <label className="form-label">Job ID</label>
                            <input
                              type="text"
                              className="form-control"
                              name="referenceCode"
                              value={state.referenceCode}
                              onChange={(event) =>
                                setState({
                                  ...state,
                                  referenceCode: event.target.value,
                                })
                              }
                              readOnly
                              placeholder="Enter job iD"
                              maxLength={30}
                            />
                            <XError message={error.referenceCode} />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb-4">
                            <label className="form-label">Job Title</label>
                            <select
                              class="form-select form-select-lg"
                              aria-label=".form-select-lg example"
                              name="title"
                              value={state.title}
                              onChange={(event) =>
                                setState({
                                  ...state,
                                  title: event.target.value,
                                })
                              }
                            >
                              <option value="">Select job title</option>
                              {jobtitle &&
                                jobtitle.map((title) => {
                                  return (
                                    <option value={title.id} key={title.id}>
                                      {title?.title}
                                    </option>
                                  );
                                })}
                            </select>
                            {/* <label className="form-label">Job Title</label>
                                        <input type="text" className="form-control" name="title" 
                                        value={state.title}
                                        onChange={(event) =>
                                           setState({ ...state, title: event.target.value })
                                         }
                                        placeholder="Enter job title" maxLength={25}/>  
                                        <XError message={error.title} /> */}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="mb-4">
                            <label className="form-label">
                              Experience (years)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="experience"
                              value={state.experience}
                              onChange={(event) => {
                                const input = event.target.value;
                                const numericInput = input.replace(
                                  /[^\d.]/g,
                                  ""
                                ); // Remove all non-numeric characters
                                // Check if the input contains only numbers or not
                                const isNumeric = /^(\d*\.)?\d*$/.test(
                                  numericInput
                                );
                                if (isNumeric) {
                                  setState({
                                    ...state,
                                    experience: numericInput,
                                  });
                                }
                              }}
                              placeholder="Enter experience"
                              maxLength={2}
                              readOnly={!/^(\d*\.)?\d*$/.test(state.experience)}
                            />
                            <XError message={error.experience} />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb-4">
                            <label className="form-label">Open Positions</label>
                            <input
                              type="text"
                              className="form-control"
                              name="position"
                              value={state.position}
                              onChange={(event) => {
                                const input = event.target.value;
                                const numericInput = input.replace(/\D/g, ""); // Remove all non-numeric characters
                                // Check if the input contains only numbers or not
                                const isNumeric = /^\d*$/.test(input);
                                if (isNumeric) {
                                  setState({
                                    ...state,
                                    position: numericInput,
                                  });
                                }
                              }}
                              placeholder="Enter no of positions"
                              maxLength={2}
                              readOnly={!/^\d*$/.test(state.position)}
                            />
                            <XError message={error.position} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6 location-select">
                          <div className="mb-4">
                            <label className="form-label">Location</label>
                            {/* <Multiselect 
                                        isObject={false}
                                        showCheckbox
                                        options={location}
                                        selectedValues={state.location}
                                        placeholder="Select location"
                                        onRemove={(event) => onRemove(event)}
                                        onSelect={(event) => onSelect(event)}
                                        /> */}
                            <input
                              type="text"
                              className="form-control"
                              name="location"
                              value={state.location}
                              onChange={(event) => {
                                // Sanitize the input before updating the state
                                const sanitizedValue = DOMPurify.sanitize(
                                  event.target.value
                                );
                                setState({
                                  ...state,
                                  location: sanitizedValue,
                                });
                              }}
                              placeholder="Enter location"
                              maxLength={50}
                            />
                            <XError message={error.location} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="mb-4">
                            <label className="form-label">
                              Add More Details
                            </label>
                            <JoditEditor
                              ref={editor}
                              value={state.jobDetails}
                              onChange={(newContent) => {
                                // Sanitize the editor content
                                const sanitizedContent =
                                  DOMPurify.sanitize(newContent);
                                setState({
                                  ...state,
                                  jobDetails: sanitizedContent,
                                });
                              }}
                            />
                            <XError message={error.jobDetails} />
                          </div>
                        </div>
                      </div>
                      <div className="footer-button pt-2">
                        <button
                          type="button"
                          onClick={handleCancel}
                          className="cancel-button"
                        >
                          Cancel
                        </button>
                        {isLoading ? (
                          <button type="button" className="submit-button">
                            Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={onSubmit}
                            className="submit-button"
                          >
                            Update
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
};

export default EditJob;
