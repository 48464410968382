import React, { useEffect } from 'react'
import './css/service_platform.css';
import { NavLink } from 'react-router-dom';
import Layouts from './layout/layout';
import baseURL from './utils/baseURL';
import RecentWork from './Home/RecentWork';

const Platform = (props) => {


    useEffect(() => {
        // Array to hold tab ids and associated content ids
        const tabs = [
            { tabId: "tab1", activeIds: ["tab-active1"], contentId: "tab-des1", imgId: "img-style1" },
            { tabId: "tab2", activeIds: ["tab-active1", "tab-active2"], contentId: "tab-des2", imgId: "img-style2" },
            { tabId: "tab3", activeIds: ["tab-active1", "tab-active2", "tab-active3"], contentId: "tab-des3", imgId: "img-style3" },
            { tabId: "tab4", activeIds: ["tab-active1", "tab-active2", "tab-active3", "tab-active4"], contentId: "tab-des4", imgId: "img-style4" },
            { tabId: "tab5", activeIds: ["tab-active1", "tab-active2", "tab-active3", "tab-active4", "tab-active5"], contentId: "tab-des5", imgId: "img-style5" },
        ];
    
        // Function to handle tab click events
        const handleTabClick = (activeIds, contentId, imgId) => {
            // Reset active class on all tabs
            for (let i = 1; i <= 5; i++) {
                document.getElementById(`tab-active${i}`).classList.remove("active");
                document.getElementById(`tab-des${i}`).style.display = "none";
                document.getElementById(`img-style${i}`).classList.remove("animation");
            }
    
            // Set active classes for the current tab
            activeIds.forEach(id => document.getElementById(id).classList.add("active"));
            document.getElementById(contentId).style.display = "inline";
            document.getElementById(imgId).classList.add("animation");
        };
    
        // Add event listeners for each tab
        tabs.forEach(({ tabId, activeIds, contentId, imgId }) => {
            const tabElement = document.getElementById(tabId);
            if (tabElement) {
                tabElement.addEventListener("click", () => handleTabClick(activeIds, contentId, imgId));
            }
        });
    
        // Cleanup function to remove event listeners
        return () => {
            tabs.forEach(({ tabId }) => {
                const tabElement = document.getElementById(tabId);
                if (tabElement) {
                    tabElement.removeEventListener("click", handleTabClick);
                }
            });
        };
    }, []);
    

    return (
        <>
        <Layouts>
            <div className='platform-service'>
                <section className="part-1">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6">
                                <div className="d-flex flex-column">
                                    <div
                                        className="online-part"
                                        data-aos="fade-left"
                                        data-aos-duration={1000}
                                    >
                                        <p className="online-span">
                                            Online Presence Via Interactive Websites
                                        </p>
                                    </div>
                                    <div
                                        className="heading-1"
                                        data-aos="fade-up"
                                        data-aos-duration={1000}
                                    >
                                        Elevate Your <span className="heading-2">Platform's Growth</span>{" "}
                                    </div>
                                    <div
                                        className="_description text-left-justify"
                                        data-aos="fade-right"
                                        data-aos-duration={1000}
                                    >
                                        With our platform development services and your ambitious vision, we can build a thriving network of interconnected solutions and service providers. Enjoy the benefits of efficiency without being burdened by operational expenses. Allow us to transform your concepts into an engaging and user-friendly online experience.
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-12 col-lg-6"
                                data-aos="fade-left"
                                data-aos-duration={1000}
                            >
                                <div className="group_people">
                                    <img
                                        src="/image/platform/Group 40834.svg"
                                        alt="hero"
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* second section */}
                <section className="part-2">
                    <div className="container">
                        <div className="row justify-content-between align-items-center row-plat">
                            <div
                                className="col-12 col-lg-5"
                                data-aos="fade-left"
                                data-aos-duration={1000}
                            >
                                <div className="group_people">
                                    <img
                                        src="/image/platform/Group 40143.svg"
                                        alt=""
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 col-plat">
                                <div className="d-flex flex-column">
                                    <div
                                        className="heading"
                                        data-aos="fade-up"
                                        data-aos-duration={1000}
                                    >
                                        Amazing and
                                        <span> Responsive</span> Designs
                                    </div>
                                    <div
                                        className="_description"
                                        data-aos="fade-left"
                                        data-aos-duration={1000}
                                    >
                                        
                                        Transform Your Online Presence with Amazing and Responsive Design.

                                    </div>
                                    <ul data-aos="fade-left" data-aos-duration={1000}>
                                        <li>
                                            <img src="/image/about_us/svg/tick.svg" alt="" />
                                            <span>Visually Striking</span>
                                        </li>
                                        <li>
                                            <img src="/image/about_us/svg/tick.svg" alt="" />
                                            <span>Seamless Mobile Experience</span>
                                        </li>
                                        <li>
                                            <img src="/image/about_us/svg/tick.svg" alt="" />
                                            <span>Intuitive Navigation</span>
                                        </li>
                                        <li>
                                            <img src="/image/about_us/svg/tick.svg" alt="" />
                                            <span>Consistency and Branding</span>
                                        </li>
                                        <li>
                                            <img src="/image/about_us/svg/tick.svg" alt="" />
                                            <span>Fast Loading Speed</span>
                                        </li>
                                        <li>
                                            <img src="/image/about_us/svg/tick.svg" alt="" />
                                            <span>Engaging Call-to-Action (CTA)</span>
                                        </li>
                                        <li>
                                            <img src="/image/about_us/svg/tick.svg" alt="" />
                                            <span>User-Centric Approach</span>
                                        </li>
                                        <li>
                                            <img src="/image/about_us/svg/tick.svg" alt="" />
                                            <span>Cross-Browser Compatibility</span>
                                        </li>
                                        <li>
                                            <img src="/image/about_us/svg/tick.svg" alt="" />
                                            <span>Scalability and Future-Proofing</span>
                                        </li>
                                        <li>
                                            <img src="/image/about_us/svg/tick.svg" alt="" />
                                            <span>Ongoing Support and Updates</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Our Platform Development */}
                <section className="part-3" style={{ paddingBottom: 100 }}>
                    <div className="container">
                        <div className="platform-main">
                            <div className="row">
                                <div className="col-12">
                                    <p
                                        className="heading-1"
                                        data-aos="fade-up"
                                        data-aos-duration={1000}
                                    >
                                        Our <span style={{ color: "#F58634" }}>Platform Development</span>{" "}
                                        Process
                                    </p>
                                    <p
                                        className="_description "
                                        data-aos="fade-up"
                                        data-aos-duration={1000}
                                    >
                                       
                                         Developing a platform requires careful planning, execution, <br />
                                        and iteration to ensure a success.
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div
                                        className="paging-tab"
                                        data-aos="fade-up"
                                        data-aos-duration={1000}
                                    >
                                        <div className="same-line" id="tab1">
                                            <img
                                                src="/image/platform/Group 40639.svg"
                                                alt=""
                                                className="img-fluid animation img-size"
                                                id="img-style1"
                                            />
                                            <p className="p-title active" id="tab-active1">
                                                Analysis
                                            </p>
                                        </div>
                                        <div className="same-line">
                                            <img
                                                src="/image/platform/Vector.svg"
                                                alt=""
                                                className="arrow-style"
                                            />
                                        </div>
                                        <div className="same-line" id="tab2">
                                            <img
                                                src="/image/platform/problem.svg"
                                                alt=""
                                                className="img-fluid img-size"
                                                id="img-style2"
                                            />
                                            <p className="p-title" id="tab-active2">
                                                Problem Definition
                                            </p>
                                        </div>
                                        <div className="same-line">
                                            <img
                                                src="/image/platform/Vector.svg"
                                                alt=""
                                                className="arrow-style"
                                            />
                                        </div>
                                        <div className="same-line" id="tab3">
                                            <img
                                                src="/image/platform/design.svg"
                                                alt=""
                                                className="img-fluid img-size"
                                                id="img-style3"
                                            />
                                            <p className="p-title" id="tab-active3">
                                                Design
                                            </p>
                                        </div>
                                        <div className="same-line">
                                            <img
                                                src="/image/platform/Vector.svg"
                                                alt=""
                                                className="arrow-style"
                                            />
                                        </div>
                                        <div className="same-line" id="tab4">
                                            <img
                                                src="/image/platform/list.svg"
                                                alt=""
                                                className="img-fluid img-size"
                                                id="img-style4"
                                            />
                                            <p className="p-title" id="tab-active4">
                                                Implementation
                                            </p>
                                        </div>
                                        <div className="same-line">
                                            <img
                                                src="/image/platform/Vector.svg"
                                                alt=""
                                                className="arrow-style"
                                            />
                                        </div>
                                        <div className="same-line" id="tab5">
                                            <img
                                                src="/image/platform/list.svg"
                                                alt=""
                                                className="img-fluid img-size"
                                                id="img-style5"
                                            />
                                            <p className="p-title" id="tab-active5">
                                                Testing
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-details">
                                <div id="tab-des1">
                                    <div className="row justify-content-between">
                                        <div
                                            className="col-12 col-lg-5"
                                            data-aos="fade-right"
                                            data-aos-duration={1000}
                                        >
                                            <img
                                                src="/image/platform/Rectangle 214.png"
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div
                                                className="_description-tab"
                                                data-aos="fade-up"
                                                data-aos-duration={1000}
                                            >
                                                <div>
                                                    <span className="circle">1</span>
                                                </div>
                                                <h2 className="title-h2">Analysis</h2>
                                                <p className="tab1-p text-left-justify">
                                                We study the requirements, market trends, potential risks, and technical feasibility to make informed decisions. Through analysis, we gather valuable insights that guide us in creating a successful and effective platform that meets the needs of users and aligns with business goals.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="tab-des2" style={{ display: "none" }}>
                                    <div className="row justify-content-between">
                                        <div
                                            className="col-12 col-lg-5"
                                            data-aos="fade-right"
                                            data-aos-duration={1000}
                                        >
                                            <img
                                                src="/image/platform/Rectangle 214.png"
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div
                                                className="_description-tab"
                                                data-aos="fade-up"
                                                data-aos-duration={1000}
                                            >
                                                <div>
                                                    <span className="circle">2</span>
                                                </div>
                                                <h2 className="title-h2 text-left-justify">Problem definition</h2>
                                                <p className="tab1-p">Identifying and understanding challenges the platform aims to solve leads to effective solutions. It keeps development focused and goal-oriented, creating valuable solutions for users by addressing specific needs and pain points.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="tab-des3" style={{ display: "none" }}>
                                    <div className="row justify-content-between">
                                        <div
                                            className="col-12 col-lg-5"
                                            data-aos="fade-right"
                                            data-aos-duration={1000}
                                        >
                                            <img
                                                src="/image/platform/Rectangle 214.png"
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div
                                                className="_description-tab"
                                                data-aos="fade-up"
                                                data-aos-duration={1000}
                                            >
                                                <div>
                                                    <span className="circle">3</span>
                                                </div>
                                                <h2 className="title-h2">Design</h2>
                                                <p className="tab1-p text-left-justify">
Designing a platform involves creating a blueprint for its look, feel, and function, covering visuals like layout and colors, and user experience. A thoughtful design boosts usability, engagement, and user satisfaction, considering mobile responsiveness and accessibility. Prioritizing design results in visually appealing, user-friendly platforms that deliver a delightful experience.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="tab-des4" style={{ display: "none" }}>
                                    <div className="row justify-content-between">
                                        <div
                                            className="col-12 col-lg-5"
                                            data-aos="fade-right"
                                            data-aos-duration={1000}
                                        >
                                            <img
                                                src="/image/platform/Rectangle 214.png"
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div
                                                className="_description-tab"
                                                data-aos="fade-up"
                                                data-aos-duration={1000}
                                            >
                                                <div>
                                                    <span className="circle">4</span>
                                                </div>
                                                <h2 className="title-h2">Implementation</h2>
                                                <p className="tab1-p text-left-justify">
                                                It transforms plans into a functional system. Like constructing a house from a blueprint, it requires coding, testing, and ensuring seamless operation, making the platform ready for users to enjoy.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="tab-des5" style={{ display: "none" }}>
                                    <div className="row justify-content-between">
                                        <div
                                            className="col-12 col-lg-5"
                                            data-aos="fade-right"
                                            data-aos-duration={1000}
                                        >
                                            <img
                                                src="/image/platform/Rectangle 214.png"
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div
                                                className="_description-tab"
                                                data-aos="fade-up"
                                                data-aos-duration={1000}
                                            >
                                                <div>
                                                    <span className="circle">5</span>
                                                </div>
                                                <h2 className="title-h2">Testing</h2>
                                                <p className="tab1-p text-left-justify">
                                                Testing rigorously examines platform functionalities, performance, and usability to guarantee seamless operation. It uncovers and resolves glitches pre-launch, encompassing component checks, integration trials, and holistic evaluations. Thorough testing guarantees a smooth, user-friendly experience, meeting user expectations, and yielding a high-quality, dependable platform, primed for its intended audience's use.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Recent Work */}
                <RecentWork recentWork={props}/>
            </div>
        </Layouts>
        </>

    )
}

export default Platform