import React, { useEffect, useState } from 'react'
import '../css/login.css'
import XError from '../../common/xError';
import { useNavigate, useParams } from 'react-router-dom';
import userService from '../../../services/userService';
import Swal from 'sweetalert2';
const PasswordReset = () => { 

    const { token } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false)
    let navigate = useNavigate();
    const [state, setState] = useState({
		password:"",
		confirmPassword:""
	});
    const [errorState,setErrorState] = useState({
		password:"",
		confirmPassword:""
	});
    
    
	const validationCheck = () => {

		let error = {
			password:"",
			confirmPassword:""
		};
		let flag = true;

		if (state.password === "" || state.password === null || state.password === undefined) {
			
			error.password = 'The password field is required.';
			flag = false;
		}
		if (state.confirmPassword === "" || state.confirmPassword === null || state.confirmPassword === undefined) {
			
			error.confirmPassword = 'The confirm password field is required.';
			flag = false;
		}

		if (state.password && state.confirmPassword && state.password !== state.confirmPassword) {

			error.password = "The password does not match.";
            flag = false;
		}

		setErrorState({...error});
		return flag;
	}

    const onSubmit = async () => { 
        setIsLoading(true)
        if(validationCheck()){
            //call services api
			await userService.resetPassword({
				token: token,
                password:state.password 
			}).then(res => {  

				Swal.fire("Password reset successfully.").then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                      if (result.isConfirmed) {
                        navigate("/cs-admin");
                      }
                  })
				setErrorState({
                    password:"",
                    confirmPassword:""
                });
               
				setIsLoading(false)
			}).catch(err => { 
				 
                Swal.fire("Password reset link expired, please resend link.");
				setIsLoading(false)
			})
        }else{
            setIsLoading(false)
        }
    }

    return (
        <div className="authincation-content">
            <section className="login-part">
                <div className="container">
                    <div className='login'>
                    <div className='login-card'>
                        <div className='card'> 
                        <form>
                            <div className='login-header text-center'>
                                <img src='/image/calidig-logo.png' alt='no_image' className="img-fluid"/>
                            </div>
                            <div className='card-body'>
                                <div className="mb-4">
                                    <label htmlFor="password" className="form-label">New Password</label>
                                    <input type={showPassword ? 'text' : 'password'} className="form-control"
                                    id="password" placeholder="Enter new password" 
                                    onChange={(event) => setState({...state,password: event.target.value})}
                                    autoComplete="off"/>
                                    <XError message={errorState.password} />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="password" className="form-label">Confirm Password</label>
                                    <input type={showPassword ? 'text' : 'password'} className="form-control"
                                    id="password" placeholder="Enter confirm password" 
                                    onChange={(event) => setState({...state,confirmPassword: event.target.value})}
                                    autoComplete="off"/>
                                     <i 
									className={`fa fa-eye${showPassword?'':'-slash'}`}
									onClick={() => setShowPassword(!showPassword)}
									aria-hidden="true"></i>
                                    <XError message={errorState.confirmPassword} />
                                </div>
                                <div className="form-login-button pb-5">
                                    {
                                        !isLoading ? <button type="button" onClick={onSubmit}>Continue</button>
                                        : <button type="button">Loading...</button>
                                    }
                                    
                                </div> 
                            </div>
                        </form>
                        </div>
                    </div>
                    </div>
                    
                </div>
            </section>
        </div>
    )
}

export default PasswordReset