import React, { useEffect } from 'react'
import Layouts from '../layout/layouts'
import { useState } from 'react';
import ourService from '../../../services/ourService'; 
import XError from '../../common/xError';
import Swal from 'sweetalert2';

const AddComplete = () => { 
    const token = localStorage.getItem('token');  
    const [isLoading, setIsLoading] = useState(false); 
    const [state, setState] = useState({
        id:"",
        service: "",
        countries: "",
        project: "",
        year: "" 
      });
      const [error, setError] = useState({
        service: "",
        countries: "",
        project: "",
        year: "" 
      });

      useEffect(() =>{
        completeList();
      },[])

      const completeList = async()=>{  
        await ourService.getComplete(token).then(res => {  
            setState({
                id: res.data?.result?.id || "",
                service: res.data?.result?.service || "",
                countries: res.data?.result?.countries || "",
                project: res.data?.result?.project || "",
                year: res.data?.result?.year || ""
            })
            onReset() 
         })
      }

      const onSubmit = async() => {
        setIsLoading(true)
        if (checkValidation()) {
            
            await ourService.createComplete(token,state).then(async (res) => { 
                // console.log(res.data?.result);
                setIsLoading(false)
                Swal.fire("Our completed work added successfully."); 
            }).catch((err) => {
                setIsLoading(false)
                Swal.fire("Our Complete not added.");
            })
       }else{
        setIsLoading(false);
      }
        
    };
      const checkValidation = () => {
        //  console.log("validation",state);
        let error = {
            service: "",
            countries: "",
            project: "",
            year: ""
        };
    
        const { service, countries, project,year} = state;
    
        let flag = true;
    
        if (service === "" || service === null || service === undefined) {
          error.service = "The service field is required.";
          flag = false;
        }else{
            const isPositiveNumeric = /^[1-9]\d*$/.test(service);
            if (!isPositiveNumeric) {
                error.service = 'Value must be greater than zero.';
                flag = false;
            }
        } 
        if (countries === "" || countries === null || countries === undefined) {
            error.countries = "The countries field is required.";
            flag = false;
        } else{
            const isPositiveNumeric = /^[1-9]\d*$/.test(countries);
            if (!isPositiveNumeric) {
                error.countries = 'Value must be greater than zero.';
                flag = false;
            }
        }
        if (project === "" || project === null || project === undefined) {
            error.project = "The projects field is required.";
            flag = false;
        }else{
            const isPositiveNumeric = /^[1-9]\d*$/.test(project);
            if (!isPositiveNumeric) {
                error.project = 'Value must be greater than zero.';
                flag = false;
            }
        } 
        if (year === "" || year === null || year === undefined) {
            error.year = "The years field is required.";
            flag = false;
        }else{
            const isPositiveNumeric = /^[1-9]\d*$/.test(year);
            if (!isPositiveNumeric) {
                error.year = 'Value must be greater than zero.';
                flag = false;
            }
        }  
        setError({ ...error });
        // console.log(errors)
        return flag;
      };
      const onReset = ()=>{  
        setError({
            service: "",
            countries: "",
            project: "",
            year: "" 
        });
      }
  return (
    <Layouts>
        <div className='admin-portfolio'>
        <div className="content-header">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">  
                        <h4 className="dash-title"> 
                        Our Completed Works</h4> 
                    </div>  
                </div>
            </div>
        </div>
        <div className='add-portfolio-body'>
            <div className="container-fluid">
                <div className='row'>
                    <div className='col-12'> 
                        <div className='card add-portfolio-card'>
                            <div className='card-body'>
                                <form> 
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className="mb-4">
                                            <label className="form-label">Top Services</label>
                                            <input type="text" className="form-control" name="services"
                                            value={state.service} 
                                            onChange={(event) => {
                                            const input = event.target.value;
                                            const numericInput = input.replace(/[^\d.]/g, ''); // Remove all non-numeric characters
                                            // Check if the input contains only numbers or not
                                            const isNumeric = /^(\d*\.)?\d*$/.test(numericInput);
                                            if (isNumeric) {
                                                setState({ ...state, service: numericInput });
                                            }
                                            }}
                                            placeholder="Enter top services" maxLength={3} readOnly={!/^(\d*\.)?\d*$/.test(state.service)}/>  
                                            <XError message={error.service} />
                                        </div>
                                    </div>  
                                    <div className='col-sm-6'>
                                        <div className="mb-4">
                                            <label className="form-label">Countries</label>
                                            <input type="text" className="form-control" name="countries"
                                            value={state.countries} 
                                            onChange={(event) => {
                                                const input = event.target.value;
                                                const numericInput = input.replace(/[^\d.]/g, ''); // Remove all non-numeric characters
                                                // Check if the input contains only numbers or not
                                                const isNumeric = /^(\d*\.)?\d*$/.test(numericInput);
                                                if (isNumeric) {
                                                    setState({ ...state, countries: numericInput });
                                                }
                                            }}
                                            placeholder="Enter countries" maxLength={3} readOnly={!/^(\d*\.)?\d*$/.test(state.countries)}/> 
                                            <XError message={error.countries} />
                                        </div>
                                    </div> 
                                </div>  
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className="mb-4">
                                            <label className="form-label">Projects</label>
                                            <input type="text" className="form-control" name="projects"
                                            value={state.project} 
                                            onChange={(event) => {
                                                const input = event.target.value;
                                                const numericInput = input.replace(/[^\d.]/g, ''); // Remove all non-numeric characters
                                                // Check if the input contains only numbers or not
                                                const isNumeric = /^(\d*\.)?\d*$/.test(numericInput);
                                                if (isNumeric) {
                                                    setState({ ...state, project: numericInput });
                                                }
                                            }}
                                            placeholder="Enter projects" maxLength={3} readOnly={!/^(\d*\.)?\d*$/.test(state.project)}/> 
                                            <XError message={error.project} /> 
                                        </div>
                                    </div>  
                                    <div className='col-sm-6'>
                                        <div className="mb-4">
                                            <label className="form-label">Years</label>
                                            <input type="text" className="form-control" name="year"
                                            value={state.year} 
                                            onChange={(event) => {
                                                const input = event.target.value;
                                                const numericInput = input.replace(/[^\d.]/g, ''); // Remove all non-numeric characters
                                                // Check if the input contains only numbers or not
                                                const isNumeric = /^(\d*\.)?\d*$/.test(numericInput);
                                                if (isNumeric) {
                                                    setState({ ...state, year: numericInput });
                                                }
                                            }}
                                            placeholder="Enter years" maxLength={2} readOnly={!/^(\d*\.)?\d*$/.test(state.year)}/>  
                                            <XError message={error.year} />
                                        </div>
                                    </div> 
                                </div> 
                                <div className='footer-button pt-2'>
                                    <button type='button' onClick={completeList} className='cancel-button'>Cancel</button>  
                                    {isLoading ? (
                                    <button type='button' className='submit-button'>Loading...</button> 
                                    ):(
                                        <button type='button' onClick={onSubmit} className='submit-button'>Save</button> 
                                    )}
                                </div>
                                </form>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
        </div>
    </Layouts>
  )
}

export default AddComplete