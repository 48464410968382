import React, { useEffect, useRef, useState } from 'react'
import Layouts from '../layout/layouts'
import '../css/adminPortfolio.css';
import XError from '../../common/xError';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import portfolioService from '../../../services/portfolioService';
import baseURL from '../../utils/baseURL';
import Swal from 'sweetalert2';
import DOMPurify from 'dompurify';



const EditPortfolio = () => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [categories,setCategories] = useState([]);
    const [tags,setTags] = useState([])
    const { id } = useParams();
    const token = localStorage.getItem('token') 
    const ref = useRef();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        title: '',
        tagIds: [],
        categoryIds:[],
        aboutUs:'',
        image: null, // To store the selected image file
      });
      const [errors, setError] = useState({
        title: '',
        tags: '',
        categories:'',
        aboutUs:'',
        image: '', 
    });
    useEffect(() =>{
        getCategory();
        getTags();
        getportfolioDetails();
    },[])
    // tags data
    const handleSelect = (data) =>{ 
        console.log(data)
        setSelectedOptions(data);
        setFormData({ ...formData, tagIds: data})
    }
    // category data
    const handleCategorySelect = (data)=>{
        setCategoryOptions(data);
        setFormData({ ...formData, categoryIds: data})
    }

    // Function to handle image selection
    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (file && allowedFile(file.name)) {
          setFormData({ ...formData, image: event.target.files[0]?event.target.files[0]:formData.image});
          setSelectedImage(URL.createObjectURL(file));
        }else{
          setSelectedImage(null); 
          setError({...errors,image:"Please select jpg,png and jpeg file."})
        }
    };

    // get category
    const getCategory = async()=>{  
        await portfolioService.getCategories().then(res => { 
          setCategories(res.data?.result); 
        })
    }
    // get tags
    const getTags = async()=>{  
        await portfolioService.getTags().then(res => { 
          setTags(res.data?.result); 
        })
    }
    // set input data
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]:DOMPurify.sanitize(value)}));
    };

    
    const getportfolioDetails = async()=>{
        await portfolioService.detailPortfolio(token,id).then(res => { 
            setFormData({
                title: res.data.result[0].title,
                tagIds: res.data.result[0].tags,
                categoryIds:res.data.result[0].categories,
                aboutUs:res.data.result[0].aboutUs,
                image: res.data.result[0].image,
            })
            const tagslist = res.data.result[0].tags.map((tagId) => {
                const tag = tags.find((tag) => tag.id === tagId); 
                return { value: tagId.id, label: tagId ? tagId.name : "" };
              });
              const catlist = res.data.result[0].categories.map((tagId) => {
                const tag = tags.find((tag) => tag.id === tagId);
                return { value: tagId.id, label: tagId ? tagId.name : ""};
              });
            setSelectedOptions(tagslist)
            setCategoryOptions(catlist) 
            onReset()
            // ref.current.value = res.data.result[0].image;
            // console.log(res.data.result[0].image.split('/').pop())
          })
    }

    const onUpdate= async(event) =>{
      setIsLoading(true)
        event.preventDefault();
        const tagslist = formData.tagIds.map((tagId) => {
            const tag = tags.find((tag) => tag.id === tagId); 
            return { value: tagId.id, label: tagId ? tagId.name : "" };
          });
          const catlist = formData.categoryIds.map((tagId) => {
            const tag = tags.find((tag) => tag.id === tagId);
            return { value: tagId.id, label: tagId ? tagId.name : ""};
          });
        setSelectedOptions(tagslist)
        setCategoryOptions(catlist) 
        if (checkValidation()) { 
            const postData = new FormData();
            postData.append('id', id); 
            postData.append('title', formData.title); 
            postData.append('image', formData.image);
            postData.append('categoryIds', JSON.stringify(categoryOptions));
            postData.append('tagIds', JSON.stringify(selectedOptions));
            postData.append('aboutUs', formData.aboutUs);  
            await portfolioService.updatePortfolio(token,postData).then(async (res) => { 
                // console.log(res.data?.result);
                setIsLoading(false)
                Swal.fire("Portfolio updated successfully.");
                navigate("/admin-portfolios")
            }).catch((err) => {
                setIsLoading(false)
                Swal.fire("Portfolio not updated.");
            })
       }else{
        setIsLoading(false);
      }
    }
    const checkValidation = () => {
        //  console.log("validation",state);
        let error = {
            title: '',
            tags: '',
            categories:'',
            aboutUs:'',
            image: null,
        };
    
        const { title, tagIds, categoryIds, aboutUs, image } = formData;
    
        let flag = true;
    
        if (title === "" || title === null || title === undefined || title.trim() === '') {
          error.title = "The title field is required.";
          flag = false;
        }
        if (tagIds.length === 0) {
            error.tags = "The tags field is required.";
            flag = false;
          }
          if (categoryIds.length === 0) {
            error.categories = "The category field is required.";
            flag = false;
        }
        if (aboutUs === "" || aboutUs === null || aboutUs === undefined || aboutUs.trim() === '') {
            error.aboutUs = "The description field is required.";
            flag = false;
        }
        // if (image === "" || image === null || image === undefined) {
        //     error.image = "The file field is required.";
        //     flag = false;
        // }
        setError({ ...error });
        // console.log(errors)
        return flag;
    };
    const allowedFile = (filename) => {
      const allowedExtensions = ['jpg', 'jpeg', 'png'];
      const extension = filename.split('.').pop().toLowerCase();
      return allowedExtensions.includes(extension);
    };

    const onReset = ()=>{  
        setSelectedImage("");
        ref.current.value = "";
        setError({
          title: '',
          tags: '',
          categories:'',
          aboutUs:'',
          image: '', 
      });
    }
    const customStyles = {
      control: (provided, state) => ({
          ...provided,
          minHeight: state.selectProps.menuIsOpen && state.selectProps.isMulti ? 'auto' : '50px', // Adjust the height values as needed
      }),
  };
  return (
    <Layouts>
    <div className='admin-portfolio'>
      <div className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12s"> 
              <Link to="/admin-portfolios" className='add-link-color'>
                <h4 className="dash-title">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M4.78645 10.7139L13.7804 19.7048C14.175 20.0984 14.8144 20.0984 15.21 19.7048C15.6047 19.3111 15.6047 18.6717 15.21 18.2781L6.92952 10.0005L15.209 1.72302C15.6037 1.32937 15.6037 0.689976 15.209 0.295329C14.8144 -0.098321 14.174 -0.098321 13.7794 0.295329L4.78545 9.28616C4.39687 9.67575 4.39687 10.3252 4.78645 10.7139Z" fill="#1F4168"/>
                </svg>
                <span className='ps-3'>Update Portfolio</span></h4>
                </Link>
              </div>  
            </div>
          </div>
      </div>
      <div className='add-portfolio-body'>
          <div className="container-fluid">
              <div className='row'>
                  <div className='col-12'>
                      <div className='card add-portfolio-card'>
                          <div className='card-body'>  
                            <form> 
                              <div className='slectd-image pb-4'>
                                    {selectedImage && selectedImage ?<img src={selectedImage} alt="Selected" width={120} height={106}/>:<img src={formData.image ? baseURL+"/"+formData.image:""} alt="Selected" width={120} height={106}/>}
                              </div>
                              <div className='row'>
                                  <div className='col-sm-6'>
                                        <div className="mb-4">
                                            <label className="form-label">Upload Image</label>
                                            <input type="file" name='image' className="form-control hint" ref={ref}  accept=".jpg, .jpeg, .png" onChange={handleImageChange}
                                            /> 
                                            <XError message={errors.image} />
                                        </div>
                                  </div>  
                                  <div className='col-sm-6'>
                                        <div className="mb-4">
                                            <label className="form-label">Title</label>
                                            <input type="text" className="form-control" name="title" value={formData?.title?formData.title:""} onChange={handleChange}
                                             placeholder="Enter title" maxLength={30}/> 
                                             <XError message={errors.title} />
                                        </div>
                                  </div> 
                              </div>
                              <div className='row'>
                                  <div className='col-sm-6'>
                                        <div className="mb-4">
                                            <label className="form-label">Tags</label>
                                             <Select options={tags.map((tag) => ({ value: tag.id, label: tag.name }))}
                                             value={selectedOptions}
                                             onChange={handleSelect}
                                             placeholder="Enter tags"
                                             isMulti
                                             className='custom-select'
                                             name="tags" 
                                             styles={customStyles}
                                             />
                                              <XError message={errors.tags} />
                                        </div>
                                  </div>  
                                  <div className='col-sm-6'>
                                        <div className="mb-4">
                                            <label className="form-label">Category</label>
                                            <Select options={categories.map((category) => ({ value: category.id, label: category.name }))}
                                             value={categoryOptions}
                                             onChange={handleCategorySelect}
                                             placeholder="Enter category" 
                                             className='custom-select'
                                             name="category"
                                             isMulti
                                             styles={customStyles}
                                             />
                                             <XError message={errors.categories} />
                                        </div>
                                  </div> 
                              </div>
                              <div className='row'>
                                  <div className='col-sm-6'>
                                        <div className="mb-4">
                                        <label for="exampleFormControlTextarea1" class="form-label">Description</label>
                                        <textarea class="form-control" name='aboutUs' value={formData.aboutUs} id="exampleFormControlTextarea1" rows="3" onChange={handleChange} 
                                        placeholder='Enter description' maxLength={150}></textarea>
                                        <XError message={errors.aboutUs} />
                                        </div>
                                  </div>  
                              </div>
                              <div className='footer-button pt-2'>
                                   <button type='button' onClick={getportfolioDetails} className='cancel-button'>Reset</button>  
                                   {isLoading ? (
                                    <button type='button' className='submit-button'>Loading...</button> 
                                    ):(
                                    <button type='button' onClick={onUpdate}className='submit-button'>Update</button> 
                                    )}
                              </div>
                            </form>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
</Layouts>
  )
}

export default EditPortfolio