import React, { useEffect, useState } from 'react'
import Layouts from '../layout/layouts'
import '../css/portfolioDetails.css';
import { Link, useNavigate } from 'react-router-dom';
import portfolioDetailService from '../../../services/portfolioDetailService';
import ReactPaginate from 'react-paginate';
import baseURL from '../../utils/baseURL';
import Swal from 'sweetalert2';


const PortfolioDetailsList = () => {

  const [portfolio, setPortfolio] = useState([]);  
  const token = localStorage.getItem('token')
  const [currentPage, setCurrentPage] = useState(0); 
  const [searchQuery, setSearchQuery] = useState(''); // State for the search query
  const [itemsPerPage, setItemsPerPage] = useState(10)  // Number of items to show per page 
  const previousLabel = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <path d="M10.3106 3.31341C10.449 3.31084 10.5848 3.35136 10.6991 3.42935C10.8135 3.50735 10.9008 3.61896 10.949 3.74875C10.9971 3.87855 11.0037 4.02009 10.9679 4.15381C10.9321 4.28753 10.8556 4.40681 10.749 4.49514L6.66961 7.98742L10.7515 11.4797C10.8261 11.5341 10.8886 11.6033 10.935 11.683C10.9815 11.7628 11.0109 11.8513 11.0215 11.9429C11.0321 12.0346 11.0235 12.1275 10.9963 12.2157C10.9692 12.3039 10.9241 12.3855 10.8638 12.4554C10.8035 12.5253 10.7294 12.5819 10.6462 12.6217C10.5629 12.6615 10.4723 12.6836 10.3801 12.6866C10.2878 12.6897 10.196 12.6735 10.1103 12.6393C10.0246 12.605 9.94693 12.5534 9.88221 12.4876L5.21575 8.49892C5.14236 8.43623 5.08344 8.35839 5.04303 8.27074C5.00263 8.18309 4.9817 8.08772 4.9817 7.9912C4.9817 7.89469 5.00263 7.79932 5.04303 7.71167C5.08344 7.62402 5.14236 7.54617 5.21575 7.48349L9.88221 3.48223C10.0005 3.37695 10.1523 3.31712 10.3106 3.31341Z" fill="#F58634"/>
                        </svg>;
  const nextLabel = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M5.68945 3.31341C5.55104 3.31084 5.41525 3.35136 5.30087 3.42935C5.1865 3.50735 5.0992 3.61896 5.05105 3.74875C5.00289 3.87855 4.99627 4.02009 5.0321 4.15381C5.06793 4.28753 5.14443 4.40681 5.25102 4.49514L9.33039 7.98742L5.2485 11.4797C5.17393 11.5341 5.11144 11.6033 5.06496 11.683C5.01849 11.7628 4.98905 11.8513 4.9785 11.9429C4.96795 12.0346 4.97652 12.1275 5.00366 12.2157C5.0308 12.3039 5.07594 12.3855 5.13621 12.4554C5.19648 12.5253 5.27059 12.5819 5.35385 12.6217C5.4371 12.6615 5.52771 12.6836 5.61994 12.6866C5.71218 12.6897 5.80404 12.6735 5.88972 12.6393C5.97541 12.605 6.05307 12.5534 6.11779 12.4876L10.7843 8.49892C10.8576 8.43623 10.9166 8.35839 10.957 8.27074C10.9974 8.18309 11.0183 8.08772 11.0183 7.9912C11.0183 7.89469 10.9974 7.79932 10.957 7.71167C10.9166 7.62402 10.8576 7.54617 10.7843 7.48349L6.11779 3.48223C5.99954 3.37695 5.84773 3.31712 5.68945 3.31341Z" fill="#F58634"/>
                  </svg>;


  let navigate = useNavigate();

  useEffect(() =>{
    portfolioDetailsList();  
  },[])

  const portfolioDetailsList = async()=>{

    await portfolioDetailService.getPortfoliosDetails(token).then(res => { 
      // console.log(res.data.result[tags])
      setPortfolio(res.data?.result); 
     })
  }

  // set current page
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

   // Filter the data based on the search query
   //const filteredData = portfolio.filter(
    //(item) =>
     // item.aboutUs.toLowerCase().includes(searchQuery.toLowerCase()) ||
     // item.links.toString().includes(searchQuery.toLowerCase())
      // || item.age.toString().includes(searchQuery) // Add other fields to search here if needed
  //);

  const filteredData = portfolio.filter(
    (item) => 
      item.portfolios.title.toLowerCase().includes(searchQuery.toLowerCase()) ||  item.aboutUs.toLowerCase().includes(searchQuery.toLowerCase()) 
  );
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(0); // Reset the current page when the search query changes
  };

  // show data function
  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    const newIndex = Math.floor((currentPage * itemsPerPage) / newItemsPerPage);
    setCurrentPage(newIndex);
}
  const offset = currentPage * itemsPerPage;
  const pageCount = Math.ceil(filteredData.length / itemsPerPage); // set page count show in pagination
  const paginatedData = filteredData.slice(offset, offset + itemsPerPage); // show paginate data

  const onDelete = (id) => { 
    Swal.fire({
      title: "Are you sure",
      text: "you want to delete this?", 
      showCancelButton: true,
      cancelButtonColor: "#d33",
      customClass: {
        confirmButton: 'custom-confirm-button',
        cancelButton: 'custom-cancel-button',
        popup: 'custom-swal-popup',
    },
      cancelButtonText:"No",
      confirmButtonText: "yes",
    }).then(async (result) => {
      if (result.isConfirmed) {

        console.log("del id", id)
        const postData = new FormData();
        postData.append('portfoliosdetailid', id); 
        await portfolioDetailService.deletePortfolio(token, {id:id})
          .then(async () => {
            portfolioDetailsList();
          })
          .catch((err) => {
             
          });
      }
    });
  };

  const onUpdate = (id) => {  
    navigate("/edit-portfolioDetails/"+id)
  }
  const handleKeyDown = (event) =>{
    if (event.key === 'Enter') {
        event.preventDefault(); // Prevent any default behavior of the "Enter" key
      }
  }
  return (
    <>
    <Layouts>
        <div className='portfolio-details'>
        <div className="content-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-6"> 
                    <h4 className="dash-title">Portfolio Page</h4>
                  </div> 
                  <div className="col-sm-6">
                    <div className="float-sm-right">
                      <Link to="/add-portfolioDetails" className='add-link-color'>
                      <button className="btn add-portfolio-btn">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <g clip-path="url(#clip0_3378_6837)">
                            <path d="M9 0C4.0371 0 0 4.0371 0 9C0 13.9629 4.0371 18 9 18C13.9629 18 18 13.9622 18 9C18 4.0378 13.9629 0 9 0ZM9 16.6057C4.80674 16.6057 1.39426 13.194 1.39426 9C1.39426 4.80604 4.80674 1.39426 9 1.39426C13.1933 1.39426 16.6057 4.80604 16.6057 9C16.6057 13.194 13.194 16.6057 9 16.6057Z" fill="white"/>
                            <path d="M12.4856 8.24013H9.69711V5.4516C9.69711 5.06678 9.38548 4.75446 8.99996 4.75446C8.61443 4.75446 8.30281 5.06678 8.30281 5.4516V8.24013H5.51429C5.12876 8.24013 4.81714 8.55245 4.81714 8.93728C4.81714 9.3221 5.12876 9.63442 5.51429 9.63442H8.30281V12.4229C8.30281 12.8078 8.61443 13.1201 8.99996 13.1201C9.38548 13.1201 9.69711 12.8078 9.69711 12.4229V9.63442H12.4856C12.8712 9.63442 13.1828 9.3221 13.1828 8.93728C13.1828 8.55245 12.8712 8.24013 12.4856 8.24013Z" fill="white"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_3378_6837">
                            <rect width="18" height="18" fill="white"/>
                            </clipPath>
                            </defs>
                          </svg>
                          <span className='ps-2'>Add Detail Page</span>
                      </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div className='portfolio-body'>
              <div className="container-fluid">
                  <div className='row'>
                      <div className='col-12'>
                          <div className='card portfolio-card'>
                              <div className='card-body'>
                                <div class="table-responsive">
                                   <div className='table-search-box'>
                                      <form className="d-flex search-form" role="search">
                                          <label className='form-label'>
                                              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                              <g clip-path="url(#clip0_3596_5383)">
                                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.75 1.375C3.33375 1.375 1.375 3.33375 1.375 5.75C1.375 8.16625 3.33375 10.125 5.75 10.125C8.16625 10.125 10.125 8.16625 10.125 5.75C10.125 3.33375 8.16625 1.375 5.75 1.375ZM0.625 5.75C0.625 2.91954 2.91954 0.625 5.75 0.625C8.58046 0.625 10.875 2.91954 10.875 5.75C10.875 7.03026 10.4056 8.20087 9.62943 9.0991L11.2652 10.7348C11.4116 10.8813 11.4116 11.1187 11.2652 11.2652C11.1187 11.4116 10.8813 11.4116 10.7348 11.2652L9.0991 9.62943C8.20087 10.4056 7.03026 10.875 5.75 10.875C2.91954 10.875 0.625 8.58046 0.625 5.75Z" fill="#454545"/>
                                              </g>
                                              <defs>
                                                  <clipPath id="clip0_3596_5383">
                                                  <rect width="12" height="12" fill="white"/>
                                                  </clipPath>
                                              </defs>
                                              </svg>
                                          </label>
                                          <input className="form-control search" type="search" placeholder="Search" value={searchQuery} onChange={handleSearchChange} onKeyDown={handleKeyDown} aria-label="Search"/>
                                      </form>
                                    </div>
                                  <table className="table">
                                    <thead className="table-color">
                                      <tr>
                                        <th style={{width:"5%",textAlign:"center"}}>#</th>
                                        <th style={{width:"10%",textAlign:"center"}}>Image</th>
                                        <th style={{width:"20%"}}>Portfolio Project</th>
                                        <th style={{width:"35%"}}>About us</th>
                                        <th style={{width:"10%",textAlign:"center"}}>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      

                                      {
                                        paginatedData && paginatedData.map((list,index)=>
                                          <tr key={list.id}>
                                            <td className='td-style'>{index + offset +1}</td>
                                            <td className='td-style'><img src={baseURL+"/"+list.image} alt='noimage' className='image-fulid' width={60} height={54}/></td>
                                            <td>{list?.portfolios?.title ||''}</td>
                                            <td>{list.aboutUs}</td>
                                            <td className='td-style'>
                                             
                                             
                                              <span><button className='btn action-btn' onClick={()=>onUpdate(list.id)}><img src='/admin/Pen.svg' alt='noimage' className='image-fulid'/></button></span>


                                              <span className='ps-2'><button className='btn action-btn' onClick={()=>onDelete(list.id)}><img src='/admin/Trash Bin 2.svg' alt='noimage' className='image-fulid'/></button></span>
                                            </td>
                                          </tr>
                                        )
                                      } 

                                    </tbody>
                                  </table>
                                  {
                                    paginatedData.length > 0 ?
                                  <div className='table-footer'>
                                      <div className='table-show'>
                                          <span className='pe-3'>Show</span>
                                          <select className='numbershow' values={itemsPerPage} onChange={handleItemsPerPageChange}>
                                            <option>3</option>
                                            <option>5</option>
                                            <option>7</option>
                                            <option>10</option>
                                            <option>15</option>
                                          </select>
                                          
                                      </div>
                                      <div className='table-pagination'>
                                      
                                    <ReactPaginate
                                        previousLabel={previousLabel}
                                        nextLabel={nextLabel}
                                        breakLabel={'...'}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageChange}
                                        containerClassName={'pagination'}
                                        activeClassName={'active'}
                                    /> 
                                      </div>
                                  </div>
                                  :""
                                    }
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
    </Layouts>
    </>
  )
}
export default PortfolioDetailsList;
