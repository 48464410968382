import axios from "axios";
import baseURL from "../components/utils/baseURL.js";

class JobServices {
  // get jobs
  async getJobs(token) {
    return await axios.get(`${baseURL}/job`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  // get Location
  async getLocation(token) {
    return await axios.get(`${baseURL}/job/location`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  // create jobs
  async createJobs(token, data) {
    return await axios.post(`${baseURL}/job`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  // edit details by id
  async editJob(token, id) {
    return await axios.get(`${baseURL}/job/edit?id=` + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  // update Service
  async updateJob(token, data) {
    return await axios.post(`${baseURL}/job/update`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  // delete Our job
  async deleteJob(token, data) {
    return await axios.post(`${baseURL}/job/delete`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async changeJobStatus(token,id){

    return await axios.put(`${baseURL}/job/changeJobStatus/${id}`,{},{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  // get jobs
  async getJobId(token) {
    return await axios.get(`${baseURL}/job/code`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  // create job Title
  async createJobTitle(token, data) {
    return await axios.post(`${baseURL}/job/title`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async deleteJobTitle(token, data) {
    return await axios.post(`${baseURL}/job/deletetitle`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  // get jobs
  async getJobTitle(token) {
    return await axios.get(`${baseURL}/job/title`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  // get delete jobs
  async getJobDelete(token) {
    return await axios.get(`${baseURL}/job/deleterecord`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  // delete Archive job
  async deleteArchiveJob(token, data) {
    return await axios.post(`${baseURL}/job/deletearchive`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async unarchiveJob(token, data) {
    return await axios.post(`${baseURL}/job/unarchive`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default new JobServices();
