import axios from "axios";
import baseURL from "../components/utils/baseURL.js";

class UserServices {
  // Auth Login ${baseURL
  async loginService(data) {
    // console.log("servicefile",data)

    return await axios.post(`${baseURL}/login`, data);
  }

  async forgotPassword(data) {
    return await axios.post(`${baseURL}/forgot`, data);
  }

  async resetPassword(data) {
    return await axios.post(`${baseURL}/reset`, data);
  }

  async loginWithGoogle(data) {
    return await axios.post(`${baseURL}/loginwithgoogle`, data);
  }

  // ADMIN APIs

  async allUserslist() {
    return await axios.get(`${baseURL}/user`);
  }

  async addNewUser(token, data) {
    return await axios.post(`${baseURL}/register`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  }
  async userById(token, id) {
    return await axios.get(`${baseURL}/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async updateUser(token, id, data) {
    return await axios.put(`${baseURL}/user/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async deleteUser(token, id) {
    return await axios.delete(`${baseURL}/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default new UserServices();
