import React from "react";
import Layouts from "../layout/layouts";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import jobService from "../../../services/jobService";
import { useState } from "react";
import { useEffect } from "react";
import Swal from "sweetalert2";
import XError from "../../common/xError";
import Loader from "../../common/Loader";
import DOMPurify from "dompurify";

const JobTitleList = () => {
  const [jobTitle, setJobTitle] = useState([]);
  const token = localStorage.getItem("token");
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState(""); // State for the search query
  const [itemsPerPage, setItemsPerPage] = useState(10); // Number of items to show per page
  const [loader, setLoader] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    jobList();
  }, []);

  const jobList = async () => {
    await jobService.getJobTitle(token).then((res) => {
      setJobTitle(res.data?.result);
      setLoader(true);
      // console.log(res.data?.result)
    });
  };

  // set current page
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  // Filter the data based on the search query
  const filteredData = jobTitle.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(0); // Reset the current page when the search query changes
  };

  // show data function
  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    const newIndex = Math.floor((currentPage * itemsPerPage) / newItemsPerPage);
    setCurrentPage(newIndex);
  };

  const offset = currentPage * itemsPerPage;
  const pageCount = Math.ceil(filteredData.length / itemsPerPage); // set page count show in pagination
  const paginatedData = filteredData.slice(offset, offset + itemsPerPage); // show paginate data

  const previousLabel =
    currentPage === 0 ? (
      <span style={{ cursor: "not-allowed" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M10.3106 3.31341C10.449 3.31084 10.5848 3.35136 10.6991 3.42935C10.8135 3.50735 10.9008 3.61896 10.949 3.74875C10.9971 3.87855 11.0037 4.02009 10.9679 4.15381C10.9321 4.28753 10.8556 4.40681 10.749 4.49514L6.66961 7.98742L10.7515 11.4797C10.8261 11.5341 10.8886 11.6033 10.935 11.683C10.9815 11.7628 11.0109 11.8513 11.0215 11.9429C11.0321 12.0346 11.0235 12.1275 10.9963 12.2157C10.9692 12.3039 10.9241 12.3855 10.8638 12.4554C10.8035 12.5253 10.7294 12.5819 10.6462 12.6217C10.5629 12.6615 10.4723 12.6836 10.3801 12.6866C10.2878 12.6897 10.196 12.6735 10.1103 12.6393C10.0246 12.605 9.94693 12.5534 9.88221 12.4876L5.21575 8.49892C5.14236 8.43623 5.08344 8.35839 5.04303 8.27074C5.00263 8.18309 4.9817 8.08772 4.9817 7.9912C4.9817 7.89469 5.00263 7.79932 5.04303 7.71167C5.08344 7.62402 5.14236 7.54617 5.21575 7.48349L9.88221 3.48223C10.0005 3.37695 10.1523 3.31712 10.3106 3.31341Z"
            fill="#F58634"
          />
        </svg>{" "}
      </span>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M10.3106 3.31341C10.449 3.31084 10.5848 3.35136 10.6991 3.42935C10.8135 3.50735 10.9008 3.61896 10.949 3.74875C10.9971 3.87855 11.0037 4.02009 10.9679 4.15381C10.9321 4.28753 10.8556 4.40681 10.749 4.49514L6.66961 7.98742L10.7515 11.4797C10.8261 11.5341 10.8886 11.6033 10.935 11.683C10.9815 11.7628 11.0109 11.8513 11.0215 11.9429C11.0321 12.0346 11.0235 12.1275 10.9963 12.2157C10.9692 12.3039 10.9241 12.3855 10.8638 12.4554C10.8035 12.5253 10.7294 12.5819 10.6462 12.6217C10.5629 12.6615 10.4723 12.6836 10.3801 12.6866C10.2878 12.6897 10.196 12.6735 10.1103 12.6393C10.0246 12.605 9.94693 12.5534 9.88221 12.4876L5.21575 8.49892C5.14236 8.43623 5.08344 8.35839 5.04303 8.27074C5.00263 8.18309 4.9817 8.08772 4.9817 7.9912C4.9817 7.89469 5.00263 7.79932 5.04303 7.71167C5.08344 7.62402 5.14236 7.54617 5.21575 7.48349L9.88221 3.48223C10.0005 3.37695 10.1523 3.31712 10.3106 3.31341Z"
          fill="#F58634"
        />
      </svg>
    );
  const nextLabel =
    currentPage === pageCount - 1 ? (
      <span style={{ cursor: "not-allowed" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M5.68945 3.31341C5.55104 3.31084 5.41525 3.35136 5.30087 3.42935C5.1865 3.50735 5.0992 3.61896 5.05105 3.74875C5.00289 3.87855 4.99627 4.02009 5.0321 4.15381C5.06793 4.28753 5.14443 4.40681 5.25102 4.49514L9.33039 7.98742L5.2485 11.4797C5.17393 11.5341 5.11144 11.6033 5.06496 11.683C5.01849 11.7628 4.98905 11.8513 4.9785 11.9429C4.96795 12.0346 4.97652 12.1275 5.00366 12.2157C5.0308 12.3039 5.07594 12.3855 5.13621 12.4554C5.19648 12.5253 5.27059 12.5819 5.35385 12.6217C5.4371 12.6615 5.52771 12.6836 5.61994 12.6866C5.71218 12.6897 5.80404 12.6735 5.88972 12.6393C5.97541 12.605 6.05307 12.5534 6.11779 12.4876L10.7843 8.49892C10.8576 8.43623 10.9166 8.35839 10.957 8.27074C10.9974 8.18309 11.0183 8.08772 11.0183 7.9912C11.0183 7.89469 10.9974 7.79932 10.957 7.71167C10.9166 7.62402 10.8576 7.54617 10.7843 7.48349L6.11779 3.48223C5.99954 3.37695 5.84773 3.31712 5.68945 3.31341Z"
            fill="#F58634"
          />
        </svg>
      </span>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M5.68945 3.31341C5.55104 3.31084 5.41525 3.35136 5.30087 3.42935C5.1865 3.50735 5.0992 3.61896 5.05105 3.74875C5.00289 3.87855 4.99627 4.02009 5.0321 4.15381C5.06793 4.28753 5.14443 4.40681 5.25102 4.49514L9.33039 7.98742L5.2485 11.4797C5.17393 11.5341 5.11144 11.6033 5.06496 11.683C5.01849 11.7628 4.98905 11.8513 4.9785 11.9429C4.96795 12.0346 4.97652 12.1275 5.00366 12.2157C5.0308 12.3039 5.07594 12.3855 5.13621 12.4554C5.19648 12.5253 5.27059 12.5819 5.35385 12.6217C5.4371 12.6615 5.52771 12.6836 5.61994 12.6866C5.71218 12.6897 5.80404 12.6735 5.88972 12.6393C5.97541 12.605 6.05307 12.5534 6.11779 12.4876L10.7843 8.49892C10.8576 8.43623 10.9166 8.35839 10.957 8.27074C10.9974 8.18309 11.0183 8.08772 11.0183 7.9912C11.0183 7.89469 10.9974 7.79932 10.957 7.71167C10.9166 7.62402 10.8576 7.54617 10.7843 7.48349L6.11779 3.48223C5.99954 3.37695 5.84773 3.31712 5.68945 3.31341Z"
          fill="#F58634"
        />
      </svg>
    );

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent any default behavior of the "Enter" key
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    title: "",
  });
  const [error, setError] = useState({
    title: "",
  });

  const onsubmit = async () => {
    setIsLoading(true);
    if (checkValidation()) {
      await jobService
        .createJobTitle(token, state)
        .then(async (res) => {
          // console.log(res.data?.result);
          setIsLoading(false);
          Swal.fire("Job title added successfully.");
          jobList();
          onReset();
        })
        .catch((err) => {
          setIsLoading(false);
          setError({ ...error, title: err?.response.data.data.title });
          Swal.fire("Job title not created.");
        });
    } else {
      setIsLoading(false);
    }
  };
  const checkValidation = () => {
    //  console.log("validation",state);
    let error = {
      title: "",
    };

    const { title } = state;

    let flag = true;
    if (
      title === "" ||
      title === null ||
      title === undefined ||
      title.trim() === ""
    ) {
      error.title = "The title field is required.";
      flag = false;
    }

    setError({ ...error });
    // console.log(errors)
    return flag;
  };
  const onReset = () => {
    setState({
      title: "",
    });

    setError({
      title: "",
    });
  };
  
    const handleDelete = (id) => {
      // console.log("del id", id)
      Swal.fire({
        title: "Are you sure",
        text: "you want to delete this?",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        customClass: {
          confirmButton: "custom-confirm-button",
          cancelButton: "custom-cancel-button",
          popup: "custom-swal-popup",
        },
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          jobService
            .deleteJobTitle(token, { id: id })
            .then(async (res) => {
              console.log(res.data);
              Swal.fire(res.data.message);
              jobList();
            })
            .catch((err) => {
              console.log(err);
              Swal.fire(err.response.data.message);
            });
        }
      });
    };
  return (
    <Layouts>
      <div className="admin-portfolio">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row header-set">
              <div className="col-sm-6">
                <h4 className="dash-title">Job Titles</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="portfolio-body">
          <div className="container-fluid">
            <div className="row gy-2">
              <div className="col-sm-6">
                <div className="add-portfolio-body">
                  <div className="card add-portfolio-card">
                    <div className="card-body">
                      <form>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="mb-4">
                              <label className="form-label">Job Title</label>
                              <input
                                type="text"
                                className="form-control"
                                name="title"
                                value={state.title}
                                onChange={(event) => {
                                  // Sanitize input using DOMPurify
                                  const sanitizedValue = DOMPurify.sanitize(
                                    event.target.value
                                  );
                                  setState({ ...state, title: sanitizedValue });
                                }}
                                placeholder="Enter job title"
                                maxLength={25}
                              />
                              <XError message={error.title} />
                            </div>
                          </div>
                        </div>
                        <div className="footer-button pt-2">
                          <button
                            type="button"
                            onClick={onReset}
                            className="cancel-button"
                          >
                            Cancel
                          </button>
                          {isLoading ? (
                            <button type="button" className="submit-button">
                              Loading...
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={onsubmit}
                              className="submit-button"
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card portfolio-card">
                  <div className="card-body">
                    <div class="table-responsive">
                      <div className="table-search-box">
                        <form className="d-flex search-form" role="search">
                          <label className="form-label">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_3596_5383)">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M5.75 1.375C3.33375 1.375 1.375 3.33375 1.375 5.75C1.375 8.16625 3.33375 10.125 5.75 10.125C8.16625 10.125 10.125 8.16625 10.125 5.75C10.125 3.33375 8.16625 1.375 5.75 1.375ZM0.625 5.75C0.625 2.91954 2.91954 0.625 5.75 0.625C8.58046 0.625 10.875 2.91954 10.875 5.75C10.875 7.03026 10.4056 8.20087 9.62943 9.0991L11.2652 10.7348C11.4116 10.8813 11.4116 11.1187 11.2652 11.2652C11.1187 11.4116 10.8813 11.4116 10.7348 11.2652L9.0991 9.62943C8.20087 10.4056 7.03026 10.875 5.75 10.875C2.91954 10.875 0.625 8.58046 0.625 5.75Z"
                                  fill="#454545"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_3596_5383">
                                  <rect width="12" height="12" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </label>
                          <input
                            className="form-control search"
                            type="search"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            onKeyDown={handleKeyDown}
                            aria-label="Search"
                          />
                        </form>
                      </div>
                      <table className="table">
                        <thead className="table-color">
                          <tr>
                            <th style={{ width: "5%", textAlign: "center" }}>
                              #
                            </th>
                            <th style={{ width: "7%" }}></th>
                            <th style={{ width: "15%" }}>Job Title</th>
                            <th style={{width:"15%",textAlign:"center"}}>Action</th>
                          </tr>
                        </thead>
                        {loader ? (
                          <tbody>
                            {paginatedData && paginatedData.length > 0 ? (
                              paginatedData.map((list, index) => {
                                return (
                                  <tr key={list.id}>
                                    <td className="td-style">
                                      {index + offset + 1}
                                    </td>
                                    <td></td>
                                    <td style={{ width: "15%" }}>
                                      {list.title || ""}
                                    </td>
                                    <td className='td-style'> 
                                        <span className='ps-2'><button className='btn action-btn' onClick={()=>handleDelete(list.id)}><img src='/admin/Trash Bin 2.svg' alt='noimage' className='image-fulid'/></button></span>
                                        </td> 
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={7} style={{ textAlign: "center" }}>
                                  No records found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        ) : (
                          <Loader />
                        )}
                      </table>
                      {paginatedData.length > 0 ? (
                        <div className="table-footer">
                          <div className="table-show">
                            <span className="pe-3">Show</span>
                            <select
                              className="numbershow"
                              values={itemsPerPage}
                              onChange={handleItemsPerPageChange}
                            >
                              <option>10</option>
                              <option>15</option>
                              <option>20</option>
                            </select>
                          </div>
                          <div className="table-pagination">
                            <ReactPaginate
                              previousLabel={previousLabel}
                              nextLabel={nextLabel}
                              breakLabel={"..."}
                              pageCount={pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageChange}
                              containerClassName={"pagination"}
                              activeClassName={"active"}
                              forcePage={currentPage}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
};

export default JobTitleList;
