import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import portfolioService from '../../../services/portfolioService';
import Layouts from '../layout/layouts';
import XError from '../../common/xError';
import baseURL from '../../utils/baseURL';
import reviewService from '../../../services/reviewService';
import Swal from 'sweetalert2';
import { useRef } from 'react';
import DOMPurify from 'dompurify';

const EditReview = () => {

    const { id } = useParams();
    const token = localStorage.getItem('token')  
    const [selectedImage, setSelectedImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [categories,setCategories] = useState([]);
    const [formData, setFormData] = useState({
        name: '', 
        categoryId:"",
        rating:"",
        message:'',
        image: null, // To store the selected image file
      });

      const [errors, setError] = useState({
        name: '', 
        categoryId:"",
        rating:"",
        message:'',
        image: null, // To store the selected image file
    });
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    // Function to handle image selection
   // Function to handle image selection
   const handleImageChange = (event) => {
        const file = event.target.files[0]; 
        if (file && allowedFile(file.name)) {
            setFormData({ ...formData, image: event.target.files[0]?event.target.files[0]:formData.image});
            setSelectedImage(URL.createObjectURL(file));
        }else{
            setSelectedImage(null); 
            setError({...errors,image:"Please select jpg,png and jpeg file."})
        }
    };
    // set input data
    const handleChange = (event) => {
        // console.log("handale",event.target)
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]:DOMPurify.sanitize(value)}));
    };
    useEffect(() =>{

        // console.log("rat",formData.rating)
        const selectedRating = parseInt(formData.rating);

        for (let i = 1; i <= 5; i++) {
            const starImg = document.getElementById('star' + i);
            if (i <= selectedRating) {
                starImg.src = "../image/svg/Star_fill.svg"; // Replace with the URL of your filled star image
            } else {
                starImg.src = "../image/svg/Star-outline.svg"; // Replace with the URL of your empty star image
            }
        }
         
    },[formData.rating])

    useEffect(()=>{
        getCategory(); 
        getReviewDetails();
    },[])
     // get category
     const getCategory = async()=>{  
        await portfolioService.getCategories().then(res => { 
          setCategories(res.data?.result); 
        })
    }

    const getReviewDetails = async()=>{
        await reviewService.detailReview(token,id).then(res => { 
            // console.log(res.data)
            setFormData({ 
                name: res.data.result.name, 
                categoryId: res.data.result.categoryId,
                rating: res.data.result.rating,
                message: res.data.result.message,
                image: res.data.result.image,
            }) 
            
        })
        setSelectedImage(null)
        onReset()
    }

    const onSubmit = async(event) => {
        console.log(formData)
        setIsLoading(true)
          event.preventDefault();
          if (checkValidation()) { 
              const postData = new FormData();
              postData.append('id', id);
              postData.append('name', formData.name); 
              postData.append('image', formData.image); 
              postData.append('categoryId', formData.categoryId);
              postData.append('rating', formData.rating);
              postData.append('message', formData.message);
  
              await reviewService.updateReview(token,postData).then(async (res) => { 
                  // console.log(res.data?.result);
                  setIsLoading(false)
                  Swal.fire("Review updated successfully.");
                  navigate("/review-list")
              }).catch((err) => {
                  setIsLoading(false)
                  Swal.fire("Review not updated.");
              })
         }else{
          setIsLoading(false);
        }
          
    };

    const checkValidation = () => {
        //  console.log("validation",state);
        let error = {
          name: '', 
          categoryId:"",
          rating:"",
          message:'',
          image: null,
        };
    
        const { name,categoryId, rating,message,image } = formData;
    
        let flag = true;
    
        if (name === "" || name === null || name === undefined || name.trim() === '') {
          error.name = "The name field is required.";
          flag = false;
        }  
        if (message === "" || message === null || message === undefined || message.trim() === '') {
            error.message = "The message field is required.";
            flag = false;
        }
        if (categoryId === "" || categoryId === null || categoryId === undefined) {
            error.categoryId = "The category field is required.";
            flag = false;
        }
        if (rating === "" || rating === null || rating === undefined) {
            error.rating = "The rating is required.";
            flag = false;
        }
        if (image === "" || image === null || image === undefined) {
            error.image = "The file field is required.";
            flag = false;
        }
        setError({ ...error });
        // console.log(errors)
        return flag;
      };

    const allowedFile = (filename) => {
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const extension = filename.split('.').pop().toLowerCase();
        return allowedExtensions.includes(extension);
    };
    const onReset = ()=>{  
          setSelectedImage(""); 
          fileInputRef.current.value = '';
          setError({
            name: '', 
            categoryId:"",
            rating:"",
            message:'',
            image: null, // To store the selected image file
        }); 
        
      }
  return (
    <Layouts>
        <div className='admin-portfolio'>
        <div className="content-header">
            <div className="container-fluid">
                <div className="row">
                <div className="col-sm-12s"> 
                <Link to="/review-list" className='add-link-color'>
                    <h4 className="dash-title">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M4.78645 10.7139L13.7804 19.7048C14.175 20.0984 14.8144 20.0984 15.21 19.7048C15.6047 19.3111 15.6047 18.6717 15.21 18.2781L6.92952 10.0005L15.209 1.72302C15.6037 1.32937 15.6037 0.689976 15.209 0.295329C14.8144 -0.098321 14.174 -0.098321 13.7794 0.295329L4.78545 9.28616C4.39687 9.67575 4.39687 10.3252 4.78645 10.7139Z" fill="#1F4168"/>
                    </svg>
                    <span className='ps-3'>Update Client Review</span></h4>
                    </Link>
                </div>  
                </div>
            </div>
        </div>
        <div className='add-portfolio-body'>
            <div className="container-fluid">
                <div className='row'>
                    <div className='col-12'>
                        <div className='card add-portfolio-card'>
                            <div className='card-body'>
                                <form>
                                <div className='slectd-image pb-4'>
                                {selectedImage && selectedImage? <img src={selectedImage} alt="Selected" width={120} height={106} loading='lazy'/>:<img src={formData.image ?baseURL+"/"+formData.image:""} alt="Selected" loading='lazy' width={120} height={106}/>}
                                </div>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                            <div className="mb-4">
                                                <label className="form-label">Upload Image</label>
                                                <input type="file" name='image' className="form-control hint" ref={fileInputRef} accept=".jpg, .jpeg, .png" onChange={handleImageChange}
                                                /> 
                                                <XError message={errors.image} />
                                            </div>
                                    </div>  
                                    <div className='col-sm-6'>
                                            <div className="mb-4">
                                                <label className="form-label">Name</label>
                                                <input type="text" className="form-control" name="name" value={formData.name} onChange={handleChange}
                                                placeholder="Enter name" maxLength={25}/> 
                                                <XError message={errors.name} />
                                            </div>
                                    </div> 
                                </div> 
                                <div className='row'>
                                    <div className='col-sm-6'>
                                            <div className="mb-4">
                                                <label className="form-label">Category</label> 
                                                <select  className="form-control" name="categoryId" value={formData.categoryId} onChange={handleChange}>
                                                    <option>Select category</option>
                                                    {
                                                    categories && categories.map(cate => { 
                                                        return <option value={cate.id} key={cate.id}>{cate?.name}</option> 
                                                    })
                                                    }
                                                </select>
                                                <XError message={errors.categoryId} />
                                            </div>
                                    </div>
                                    <div className='col-sm-6'>
                                            <div className="mb-4">
                                            <label for="exampleFormControlTextarea1" class="form-label">Message</label>
                                            <textarea class="form-control" name='message' value={formData.message} id="exampleFormControlTextarea1" rows="4" onChange={handleChange} 
                                            placeholder='Enter message' maxLength={1000}></textarea>
                                            <XError message={errors.message} />
                                            </div>
                                    </div>  
                                </div>
                                <div className='row'>
                                    <div className='col-sm-6 rating-div'>
                                        <div className="mb-4">
                                            <label className="form-label">Rating</label> 
                                            <div className='pt-3 star-img d-flex'>
                                            <label style={{display:"block"}}> 
                                                <input type='radio' hidden name='rating' value={formData.rating === 1 ? formData.rating:1} onChange={handleChange}/>
                                                <img src="../image/svg/Star-outline.svg" id='star1' alt='noimage' className='image-fulid star' width={35} height={32}/>
                                            </label>
                                            <label style={{display:"block"}} className='ps-2'> 
                                                <input type='radio' hidden name='rating' value={formData.rating === 2 ? formData.rating:2} onChange={handleChange}/>
                                                <img src="../image/svg/Star-outline.svg" id='star2' alt='noimage' className='image-fulid star' width={35} height={32}/>
                                            </label>
                                            <label style={{display:"block"}} className='ps-2'> 
                                                <input type='radio' hidden name='rating' value={formData.rating === 3 ? formData.rating:3} onChange={handleChange}/>
                                                <img src="../image/svg/Star-outline.svg" id='star3' alt='noimage' className='image-fulid star' width={35} height={32}/>
                                            </label>
                                            <label style={{display:"block"}} className='ps-2'> 
                                                <input type='radio' hidden name='rating' value={formData.rating === 4 ? formData.rating:4} onChange={handleChange}/>
                                                <img src="../image/svg/Star-outline.svg" id='star4' alt='noimage' className='image-fulid star' width={35} height={32}/>
                                            </label>
                                            <label style={{display:"block"}} className='ps-2'> 
                                                <input type='radio' hidden name='rating' value={formData.rating === 5 ? formData.rating:5} onChange={handleChange}/>
                                                <img src="../image/svg/Star-outline.svg" id='star5' alt='noimage' className='image-fulid star' width={35} height={32}/>
                                            </label>
                                            </div>
                                            <XError message={errors.rating} />
                                        </div>
                                    </div>
                                </div>
                                <div className='footer-button pt-2'>
                                    <button type='button' onClick={getReviewDetails} className='cancel-button'>Cancel</button> 
                                    {isLoading ? (
                                    <button type='button' className='submit-button'>Loading...</button> 
                                    ):(
                                        <button type='button' onClick={onSubmit} className='submit-button'>Update</button>
                                    )}
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </Layouts>
  )
}

export default EditReview