import React, { useEffect, useState,useRef } from 'react';
import './css/portfolio_details.css';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import Layouts from './layout/layout';
import webService from '../services/webService';
import baseURL from './utils/baseURL';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation } from 'swiper/modules';
import RecentWork from './Home/RecentWork';

const PortfolioDetails = (props) => {
  
    const { id } = useParams();
    const selectedJob = id;
    const [state,setState] = useState({
        mainimage:null,
        aboutUs:"",
        businessProblem:"",
        completitionDetails:"",
        launchedYear:"",
        shortDescription:"",
        solution:"",
        technology:"",
        customerName:"",
        title:"",
        lasttitle: "",
        techname:"",
        techimage: null,
        tags:[],
        link:[]
    });
    const swiperRef = useRef(null);

    const [sideimage,setSideImage] = useState([]);
 
      
    useEffect(() =>{ 
        getPortfolioDetails();
    },[selectedJob])

    
    const getPortfolioDetails = async()=>{
       
        await webService.getPortDetails(selectedJob).then(res => { 
            //   console.log(res.data.result)
              const title = res.data?.result?.portfolios?.title.split(' ');
              let newtitle =""
              if(title && Array.isArray(title) && title.length > 0){
                title.pop();
                newtitle = title.join(' ');
              }
             console.log(res.data?.result,"res.data?.result...........")
              setState({
                mainimage: res.data?.result?.image || null,
                aboutUs: res.data?.result?.aboutUs || "",
                businessProblem: res.data?.result?.businessProblem || "",
                completitionDetails: res.data?.result?.completitionDetails || "",
                launchedYear: res.data?.result?.launchedYear || "",
                shortDescription: res.data?.result?.shortDescription || "",
                solution: res.data?.result?.solution || "",
                technology: res.data?.result?.technology || "",
                customerName: res.data?.result?.customerName || "",
                title : newtitle || "",
                lasttitle : res.data?.result?.portfolios?.title || "",
                techname: res.data?.result?.technologies?.name || "",
                techimage : res.data?.result?.technologies?.image || null,
                tags : res.data?.result?.portfolios?.tags || "",
                link : res.data?.result?.portfolioslink || "",
              })
              setSideImage(res.data?.result?.portfoliosImageLink)
        }) 
    }


    return (
        <>
            <Layouts>
                <div className='portfolio_details'>
                    {/* Part-1 */}
                    <section className="part-1">
                        <div className="container">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-12 col-lg-5">
                                    <div className="d-flex flex-column">
                                        <div
                                            className="heading-1"
                                            data-aos="fade-up"
                                            data-aos-duration={1000}
                                        >
                                            {state.title} <span className="heading-2">{state.lasttitle.split(' ').pop()}</span>{" "}
                                        </div>
                                        <div
                                            className="_description"
                                            data-aos="fade-right"
                                            data-aos-duration={1000}
                                        >
                                           {state.aboutUs}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-12 col-lg-6"
                                    data-aos="fade-left"
                                    data-aos-duration={1000}
                                >
                                    <div className="group_people">
                                        <img
                                            src={state.mainimage?baseURL + "/" + state.mainimage:""}
                                            alt=""
                                            className="img-fluid bigger"
                                        />
                                        {/* <img
                                            src="/image/portfolio_details/main2.png"
                                            alt=""
                                            className="img-fluid smaller"
                                        /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* For small screen */}
                    <section className="part-11">
                        <div className="container">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-12 col-lg-5">
                                    <div className="d-flex flex-column">
                                        <div
                                            className="heading-1"
                                            data-aos="fade-up"
                                            data-aos-duration={1000}
                                        >
                                            {state.title} <span className="heading-2">{state.lasttitle.split(' ').pop()}</span>{" "}
                                           
                                        </div>
                                        <div
                                            className="_description"
                                            data-aos="fade-right"
                                            data-aos-duration={1000}
                                        >
                                           {state.aboutUs}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-12 col-lg-6"
                                    data-aos="fade-left"
                                    data-aos-duration={1000}
                                >
                                    <div className="group_people">
                                        <div className="row sub-row">
                                            <div className='col-12'>
                                                <img
                                                    src={state.mainimage?baseURL + "/" + state.mainimage:""}
                                                    alt=""
                                                    className="img-fluid bigger"
                                                />
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Part-2 */}
                    <section className="part-2">
                        <div className="container">
                            <div className="row justify-content-between">
                                <div
                                    className="col-lg-5"
                                    data-aos="fade-right"
                                    data-aos-duration={1000}
                                >
                                    <div className="uiux">
                                        <div
                                            className="more_features_button  d-flex align-items-center"
                                            data-aos="fade-up"
                                            data-aos-duration={1000}
                                            style={{flexWrap:"wrap"}}
                                        >
                                            {
                                                state.tags && state.tags.map((tag) =>
                                                <div className='pe-1'  key="2" style={{marginBottom:"8px"}}><button>{tag.name}</button></div>
                                                )
                                            }
                                           
                                        </div>
                                        <h3>Overview</h3>
                                        <p>
                                            {state.shortDescription}
                                        </p>
                                         
                                        <div
                                            className="more_features_button2 align-items-center"
                                            data-aos="fade-up"
                                            data-aos-duration={1000}
                                        >
                                            {
                                                state.link && state.link.map((links) =>
                                                <a className='pe-1'  key="3" href={links.link} target='_blank'><button className='mb-2'>{links.type === "1"? "Android App":links.type === "2"?"iOS App" :links.type === "3"?"Web App":"Other App"}</button></a>
                                                )
                                            }
                                            {/* <button>View Live Website</button> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6" data-aos="fade-left" data-aos-duration={1000}>
                                    <div className="uiux2">
                                        <h3>How We Completed</h3>
                                        <p>
                                            {state.completitionDetails}
                                        </p>
                                        <div
                                            className="accordion accordion-flush"
                                            id="accordionFlushExample"
                                        >
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="flush-headingOne">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#flush-collapseOne"
                                                        aria-expanded="false"
                                                        aria-controls="flush-collapseOne"
                                                    >
                                                        Business Problem
                                                    </button>
                                                </h2>
                                                <div
                                                    id="flush-collapseOne"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="flush-headingOne"
                                                    data-bs-parent="#accordionFlushExample"
                                                >
                                                    <div className="accordion-body">
                                                        {state.businessProblem}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="flush-headingTwo">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#flush-collapseTwo"
                                                        aria-expanded="false"
                                                        aria-controls="flush-collapseTwo"
                                                    >
                                                        The Solution
                                                    </button>
                                                </h2>
                                                <div
                                                    id="flush-collapseTwo"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="flush-headingTwo"
                                                    data-bs-parent="#accordionFlushExample"
                                                >
                                                    <div className="accordion-body">
                                                        {state.solution}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="flush-headingThree">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#flush-collapseThree"
                                                        aria-expanded="false"
                                                        aria-controls="flush-collapseThree"
                                                    >
                                                        Technology
                                                    </button>
                                                </h2>
                                                <div
                                                    id="flush-collapseThree"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="flush-headingThree"
                                                    data-bs-parent="#accordionFlushExample"
                                                >
                                                    <div className="accordion-body">
                                                         {state.technology}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Part-3 */}
                    <section className="part-3">
                        <div className="container">
                            <div className="row main-row">
                                <div
                                    className="col-12 col-md-4 col-lg-3 center"
                                    data-aos="fade-up"
                                    data-aos-duration={1000}
                                >
                                    <div className="content">
                                        <img src="../image/portfolio_details/launched.svg" alt="" />
                                        <h3>Launched</h3>
                                        <p>{state.launchedYear}</p>
                                    </div>
                                </div>
                                <div
                                    className="col-12 col-md-4 col-lg-3 center"
                                    data-aos="zoom-in"
                                    data-aos-duration={1000}
                                >
                                    <div className="content center_logo">
                                        <img src={state.techimage?baseURL+"/"+state.techimage:""} alt="" width={73} height={73}/>
                                        <h3>Platform</h3>
                                        <p>{state.techname}</p>
                                    </div>
                                </div>
                                <div
                                    className="col-12 col-md-4 col-lg-3 center"
                                    data-aos="fade-up"
                                    data-aos-duration={1000}
                                >
                                    <div className="content">
                                        <img src="../image/portfolio_details/customer.svg" alt="" />
                                        <h3>Customer</h3>
                                        <p>{state.customerName}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Part-4 */}
                    <section className="part-4">
                        <div className="container-fuild custom-container">
                            <div className="row main-row align-items-center">
                                <div className="col-12 col-lg-4">
                                    <div className="headings">
                                        <h2 data-aos="fade-down" data-aos-duration={1000}>
                                            Check Out Pages <span>Highlights</span>
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-8">
                                    <div className="row sub-row">
                                        {
                                            sideimage && sideimage.length > 0 ? (sideimage.map((side) => {
                                                return(
                                                    <div
                                                        className="col-12 col-lg-4 col-style"
                                                        data-aos="fade-left"
                                                        data-aos-duration={1000} 
                                                         key="4"
                                                    >
                                                        <img
                                                            src={side.image?baseURL+"/"+side.image:""}
                                                            className="img-fluid section-images"
                                                            alt=""
                                                        />
                                                    </div>
                                                )
                                            })):(
                                                <div style={{textAlign:"center"}}>
                                                    <img src="/image/svg/notfound.svg" alt="" width={200}/>
                                                    <h4><b>Oops!</b></h4>
                                                    <span>Data Not Found</span>
                                                </div>
                                            )
                                        }
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Recent Work */}
                   <RecentWork recentWork={props} id={id}/>
                </div>
            </Layouts>
        </>
    )
}

export default PortfolioDetails