import React, { useEffect, useState } from 'react'
import Layouts from '../layout/layouts' 
import ReactPaginate from 'react-paginate'
import reviewService from '../../../services/reviewService'
import { useRef } from 'react'
import XError from '../../common/xError'
import Swal from 'sweetalert2'
import baseURL from '../../utils/baseURL'
import Loader from '../../common/Loader'

const LogoList = () => {

    const [clientlog, setClientLogo] = useState([]);
    const token = localStorage.getItem('token')
    const [currentPage, setCurrentPage] = useState(0);  
    const [itemsPerPage, setItemsPerPage] = useState(10)  // Number of items to show per page 
    const [selectedImage, setSelectedImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [loader, setLoader] = useState(false) 
    const [formData, setFormData] = useState({ 
        image: null, // To store the selected image file
    });

    const [errors, setError] = useState({ 
        image: null, // To store the selected image file
    });
    
    const fileInputRef = useRef(null);  

useEffect(()=>{
    clientLogoList();
},[])

const clientLogoList = async()=>{  
    await reviewService.getLogo(token).then(res => {  
        setClientLogo(res.data?.result); 
        setLoader(true)
    })
}

  // set current page
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  }; 

  // show data function
  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    const newIndex = Math.floor((currentPage * itemsPerPage) / newItemsPerPage);
    setCurrentPage(newIndex);
}

  const offset = currentPage * itemsPerPage;
  const pageCount = Math.ceil(clientlog.length / itemsPerPage); // set page count show in pagination
  const paginatedData = clientlog.slice(offset, offset + itemsPerPage); // show paginate data

  const previousLabel = currentPage === 0 ? <span style={{cursor:"not-allowed"}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M10.3106 3.31341C10.449 3.31084 10.5848 3.35136 10.6991 3.42935C10.8135 3.50735 10.9008 3.61896 10.949 3.74875C10.9971 3.87855 11.0037 4.02009 10.9679 4.15381C10.9321 4.28753 10.8556 4.40681 10.749 4.49514L6.66961 7.98742L10.7515 11.4797C10.8261 11.5341 10.8886 11.6033 10.935 11.683C10.9815 11.7628 11.0109 11.8513 11.0215 11.9429C11.0321 12.0346 11.0235 12.1275 10.9963 12.2157C10.9692 12.3039 10.9241 12.3855 10.8638 12.4554C10.8035 12.5253 10.7294 12.5819 10.6462 12.6217C10.5629 12.6615 10.4723 12.6836 10.3801 12.6866C10.2878 12.6897 10.196 12.6735 10.1103 12.6393C10.0246 12.605 9.94693 12.5534 9.88221 12.4876L5.21575 8.49892C5.14236 8.43623 5.08344 8.35839 5.04303 8.27074C5.00263 8.18309 4.9817 8.08772 4.9817 7.9912C4.9817 7.89469 5.00263 7.79932 5.04303 7.71167C5.08344 7.62402 5.14236 7.54617 5.21575 7.48349L9.88221 3.48223C10.0005 3.37695 10.1523 3.31712 10.3106 3.31341Z" fill="#F58634"/>
    </svg> </span>
    :<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M10.3106 3.31341C10.449 3.31084 10.5848 3.35136 10.6991 3.42935C10.8135 3.50735 10.9008 3.61896 10.949 3.74875C10.9971 3.87855 11.0037 4.02009 10.9679 4.15381C10.9321 4.28753 10.8556 4.40681 10.749 4.49514L6.66961 7.98742L10.7515 11.4797C10.8261 11.5341 10.8886 11.6033 10.935 11.683C10.9815 11.7628 11.0109 11.8513 11.0215 11.9429C11.0321 12.0346 11.0235 12.1275 10.9963 12.2157C10.9692 12.3039 10.9241 12.3855 10.8638 12.4554C10.8035 12.5253 10.7294 12.5819 10.6462 12.6217C10.5629 12.6615 10.4723 12.6836 10.3801 12.6866C10.2878 12.6897 10.196 12.6735 10.1103 12.6393C10.0246 12.605 9.94693 12.5534 9.88221 12.4876L5.21575 8.49892C5.14236 8.43623 5.08344 8.35839 5.04303 8.27074C5.00263 8.18309 4.9817 8.08772 4.9817 7.9912C4.9817 7.89469 5.00263 7.79932 5.04303 7.71167C5.08344 7.62402 5.14236 7.54617 5.21575 7.48349L9.88221 3.48223C10.0005 3.37695 10.1523 3.31712 10.3106 3.31341Z" fill="#F58634"/>
    </svg>;
    const nextLabel = currentPage === pageCount - 1 ? <span style={{cursor:"not-allowed"}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M5.68945 3.31341C5.55104 3.31084 5.41525 3.35136 5.30087 3.42935C5.1865 3.50735 5.0992 3.61896 5.05105 3.74875C5.00289 3.87855 4.99627 4.02009 5.0321 4.15381C5.06793 4.28753 5.14443 4.40681 5.25102 4.49514L9.33039 7.98742L5.2485 11.4797C5.17393 11.5341 5.11144 11.6033 5.06496 11.683C5.01849 11.7628 4.98905 11.8513 4.9785 11.9429C4.96795 12.0346 4.97652 12.1275 5.00366 12.2157C5.0308 12.3039 5.07594 12.3855 5.13621 12.4554C5.19648 12.5253 5.27059 12.5819 5.35385 12.6217C5.4371 12.6615 5.52771 12.6836 5.61994 12.6866C5.71218 12.6897 5.80404 12.6735 5.88972 12.6393C5.97541 12.605 6.05307 12.5534 6.11779 12.4876L10.7843 8.49892C10.8576 8.43623 10.9166 8.35839 10.957 8.27074C10.9974 8.18309 11.0183 8.08772 11.0183 7.9912C11.0183 7.89469 10.9974 7.79932 10.957 7.71167C10.9166 7.62402 10.8576 7.54617 10.7843 7.48349L6.11779 3.48223C5.99954 3.37695 5.84773 3.31712 5.68945 3.31341Z" fill="#F58634"/>
    </svg></span> 
    :<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M5.68945 3.31341C5.55104 3.31084 5.41525 3.35136 5.30087 3.42935C5.1865 3.50735 5.0992 3.61896 5.05105 3.74875C5.00289 3.87855 4.99627 4.02009 5.0321 4.15381C5.06793 4.28753 5.14443 4.40681 5.25102 4.49514L9.33039 7.98742L5.2485 11.4797C5.17393 11.5341 5.11144 11.6033 5.06496 11.683C5.01849 11.7628 4.98905 11.8513 4.9785 11.9429C4.96795 12.0346 4.97652 12.1275 5.00366 12.2157C5.0308 12.3039 5.07594 12.3855 5.13621 12.4554C5.19648 12.5253 5.27059 12.5819 5.35385 12.6217C5.4371 12.6615 5.52771 12.6836 5.61994 12.6866C5.71218 12.6897 5.80404 12.6735 5.88972 12.6393C5.97541 12.605 6.05307 12.5534 6.11779 12.4876L10.7843 8.49892C10.8576 8.43623 10.9166 8.35839 10.957 8.27074C10.9974 8.18309 11.0183 8.08772 11.0183 7.9912C11.0183 7.89469 10.9974 7.79932 10.957 7.71167C10.9166 7.62402 10.8576 7.54617 10.7843 7.48349L6.11779 3.48223C5.99954 3.37695 5.84773 3.31712 5.68945 3.31341Z" fill="#F58634"/>
    </svg>;


    // Function to handle image selection
    const handleImageChange = (event) => {
        const file = event.target.files[0]; 
        if (file && allowedFile(file.name)) {
        setFormData({ ...formData, image: event.target.files[0]});
        setSelectedImage(URL.createObjectURL(file));
        }else{
        setSelectedImage(null); 
        setError({...errors,image:"Please select jpg,png and jpeg file"})
        }
    };
    const allowedFile = (filename) => {
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const extension = filename.split('.').pop().toLowerCase();
        return allowedExtensions.includes(extension);
    };
    const onSubmit = async(event) => {
        setIsLoading(true)
        event.preventDefault();
        if (checkValidation()) { 
            const postData = new FormData(); 
            postData.append('image', formData.image); 

            await reviewService.createLogo(token,postData).then(async (res) => { 
                // console.log(res.data?.result);
                setIsLoading(false)
                Swal.fire("Client logo added successfully.");
                clientLogoList()
                onReset()
            }).catch((err) => {
                setIsLoading(false) 
                Swal.fire("Client logo not added.");
            })
       }else{
        setIsLoading(false);
      }
        
    };
    const checkValidation = () => {
        //  console.log("validation",state);
        let error = { 
            image: null,
        };
    
        const {image } = formData; 

        let flag = true; 
        if (image === "" || image === null || image === undefined) {
            error.image = "The file field is required.";
            flag = false;
        }
        setError({ ...error });
        // console.log(errors)
        return flag;
    };

    const onReset = ()=>{ 
        setFormData({ 
        image: null, // To store the selected image file
        }); 
        setSelectedImage(""); 
        fileInputRef.current.value = '';
        setError({ 
        image: null, // To store the selected image file
        });
    }
    const onDelete = (id) => { 
        // console.log("del id", id)
        Swal.fire({
          title: "Are you sure",
          text: "you want to delete this?", 
          showCancelButton: true,
          cancelButtonColor: "#d33",
          customClass: {
            confirmButton: 'custom-confirm-button',
            cancelButton: 'custom-cancel-button',
            popup: 'custom-swal-popup',
        },
          cancelButtonText:"No",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            reviewService.deleteClientLogo(token, { id: id })
              .then(async () => {
                clientLogoList()
              })
              .catch((err) => {
                 
              });
          }
        });
    };

  return (
    <Layouts>
    <div className='admin-portfolio'>
    <div className="content-header">
        <div className="container-fluid">
            <div className="row header-set">
                <div className="col-sm-6"> 
                    <h4 className="dash-title">Client Logos</h4>
                </div>  
            </div>
        </div>
    </div>
    <div className='portfolio-body'>
        <div className="container-fluid">
            <div className='row gy-2'>
                <div className='col-sm-6'>
                    <div className='add-portfolio-body'>
                        <div className='card add-portfolio-card'>
                            <div className='card-body'>
                                <form>
                                    {selectedImage &&
                                    <div className='slectd-image pb-4'>
                                         <img src={selectedImage} alt="Selected" width={120} height={106} loading='lazy'/>
                                    </div>
                                    }
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <div className="mb-4">
                                                <label className="form-label">Upload Logo</label>
                                                <input type="file" name='image' className="form-control hint" ref={fileInputRef} accept=".jpg, .jpeg, .png" onChange={handleImageChange}
                                                /> 
                                                <XError message={errors.image} />
                                            </div>
                                        </div> 
                                    </div>  
                                    <div className='footer-button pt-2'>
                                        <button type='button' onClick={onReset} className='cancel-button'>Cancel</button> 
                                        {isLoading ? (
                                        <button type='button' className='submit-button'>Loading...</button> 
                                        ):(
                                            <button type='button' onClick={onSubmit} className='submit-button'>Submit</button> 
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='card portfolio-card'>
                        <div className='card-body'>
                        <div class="table-responsive"> 
                            <table className="table">
                                <thead className="table-color">
                                <tr>
                                    <th style={{width:"2%",textAlign:"center"}}>#</th> 
                                    <th style={{width:"15%",textAlign:"center"}}>Logo</th>  
                                    <th style={{width:"5%",textAlign:"center"}}>Action</th>
                                </tr>
                                </thead>
                                {loader?
                                <tbody>  
                                {
                                    paginatedData && paginatedData.length > 0 ? (paginatedData.map((list,index)=>{ 
                                    return (<tr key={list.id}>
                                        <td className='td-style'>{index + offset +1}</td> 
                                        <td style={{textAlign:"center"}}><img src={baseURL+"/"+list.logo} alt='noimage' className='image-fulid' width={80} height={54} loading='lazy' /></td>   
                                        <td className='td-style'> 
                                        <span className='ps-2'><button className='btn action-btn' onClick={()=>onDelete(list.id)}><img src='/admin/Trash Bin 2.svg' alt='noimage' className='image-fulid'/></button></span>
                                        </td> 
                                    </tr>)
                                    })):(
                                        <tr>
                                          <td colSpan={7} style={{textAlign:"center"}}>No records found</td>
                                        </tr>
                                      )
                                } 
                                
                                </tbody>
                                : <Loader/> }
                            </table>
                            {
                                paginatedData.length > 0 ?
                            <div className='table-footer'>
                                <div className='table-show'>
                                    <span className='pe-3'>Show</span>
                                    <select className='numbershow' values={itemsPerPage} onChange={handleItemsPerPageChange}>
                                        <option>10</option>
                                        <option>15</option>
                                        <option>20</option> 
                                    </select>
                                </div>
                                <div className='table-pagination'>
                                
                                <ReactPaginate
                                    previousLabel={previousLabel}
                                    nextLabel={nextLabel}
                                    breakLabel={'...'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageChange}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                    forcePage={currentPage}
                                /> 
                                </div>
                            </div>
                            :""
                                }
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</Layouts>
  )
}

export default LogoList