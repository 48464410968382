import React from 'react'
import './css/startup.css';
import { NavLink } from 'react-router-dom';
import Layouts from './layout/layout';
import baseURL from './utils/baseURL';
import RecentWork from './Home/RecentWork';

const Startup = (props) => {

    return (
        <>
        <Layouts>
            <div className='startup'>
                {/* part-1 */}
                <section className="part-1">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6">
                                <div className="d-flex flex-column">
                                    <div
                                        className="online-part"
                                        data-aos="fade-left"
                                        data-aos-duration={1000}
                                    >
                                        <p className="online-span">Digital Solutions to All Your Needs</p>
                                    </div>
                                    <div
                                        className="heading"
                                        data-aos="fade-up"
                                        data-aos-duration={1000}
                                    >
                                        Start Up Your <span className="heading-2">Creative Engine</span>{" "}
                                    </div>
                                    <div
                                        className="_description"
                                        data-aos="fade-right"
                                        data-aos-duration={1000}
                                    >
                                        Unlock your startup's digital potential with our innovative solutions for success.
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-12 col-lg-6"
                                data-aos="fade-left"
                                data-aos-duration={1000}
                            >
                                <div className="group_people">
                                    <img
                                        src="./image/startup/Group 40834.svg"
                                        alt=""
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* part-2 */}
                <section className="part-2">
                    <div className="second-section">
                        <div className="container">
                            <div className="row justify-content-between align-items-center">
                                <div
                                    className="col-12 col-lg-5"
                                    data-aos="fade-left"
                                    data-aos-duration={1000}
                                >
                                    <div className="group_people">
                                        <img
                                            src="./image/startup/Group 40143.svg"
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex flex-column">
                                        <div
                                            className="heading-1"
                                            data-aos="fade-up"
                                            data-aos-duration={1000}
                                        >
                                            {" "}
                                            <span className="heading-2">High Quality Websites</span> with
                                            Optimum Cost
                                        </div>
                                        <div
                                            className="_description text-left-justify"
                                            data-aos="fade-left"
                                            data-aos-duration={1000}
                                        >
                                            With ready-to-use utilities developed in-house, we offer
                                            startups with high quality products at optimal prices. This
                                            helps to
                                        </div>
                                        <ul data-aos="fade-left" data-aos-duration={1000}>
                                            <li>Test the business ideas</li>
                                            <li>Gauge success rate of the idea</li>
                                            <li>Earn early adopters in the market</li>
                                        </ul>
                                        <div
                                            className="_description text-left-justify"
                                            data-aos="fade-left"
                                            data-aos-duration={1000}
                                        >
                                            You can have business idea to create a simple content management
                                            solution, develop an e-commerce solution, launch a knowledge
                                            platform, release a job portal, and many more, we offer
                                            development and maintenance cost solution for all these business
                                            needs.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* part-3 */}
                <section className="part-3">
                    <div className="third-section">
                        <div className="container">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex flex-column">
                                        <div
                                            className="heading-1"
                                            data-aos="fade-up"
                                            data-aos-duration={1000}
                                        >
                                            Cost Efficient{" "}
                                            <span className="heading-2">E-Commerce Solutions</span>
                                        </div>
                                        <div
                                            className="_description text-left-justify"
                                            data-aos="fade-right"
                                            data-aos-duration={1000}
                                        >
                                            We tailor e-commerce development services to all our customer
                                            segments – e-commerce startups and enterprises. With
                                            ready-to-use utilities developed in-house, we offer our
                                            customers best prices and faster releases to the market.
                                        </div>
                                        <div
                                            className="_description text-left-justify"
                                            data-aos="fade-right"
                                            data-aos-duration={1000}
                                        >
                                            We leverage WordPress along with WooCommerce plugin for a cost
                                            effective solution at the early stages of the business.
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-12 col-lg-5"
                                    data-aos="fade-left"
                                    data-aos-duration={1000}
                                >
                                    <div className="group_people">
                                        <img
                                            src="./image/startup/Group40143.svg"
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* part-4  */}
                <section className="part-4">
                    <div className="second-section">
                        <div className="container">
                            <div className="row justify-content-between align-items-center">
                                <div
                                    className="col-12 col-lg-5"
                                    data-aos="fade-right"
                                    data-aos-duration={1000}
                                >
                                    <div className="group_people">
                                        <img
                                            src="./image/startup/startup_part4.svg"
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex flex-column">
                                        <div
                                            className="heading-1"
                                            data-aos="fade-up"
                                            data-aos-duration={1000}
                                        >
                                            {" "}
                                            <span className="heading-2">Minimum</span> Viable Product
                                        </div>
                                        <div
                                            className="_description text-left-justify" 
                                            data-aos="fade-left"
                                            data-aos-duration={1000}
                                        >
                                            Minimum Viable Product (MVP) is a development technique in which
                                            a new product is introduced in the market with basic features,
                                            but enough to get the attention of the consumers. The final
                                            product is released in the market only after getting sufficient
                                            feedback from the product’s initial users.
                                        </div>
                                        <div
                                            className="_description"
                                            data-aos="fade-left"
                                            data-aos-duration={1000}
                                        >
                                            We guide our customers in defining MVP from the complete product
                                            and help in releasing it to market. MVP helps in
                                        </div>
                                        <ul data-aos="fade-left" data-aos-duration={1000}>
                                            <li>Lowering investment to test the waters</li>
                                            <li>Making the final product much better</li>
                                            <li>Knowing the strengths and weaknesses of the product</li>
                                            <li>Deciding ‘Go or No-Go’</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* part-5 */}
                <section className="part-5">
                    <div className="third-section">
                        <div className="container">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex flex-column">
                                        <div
                                            className="heading-1"
                                            data-aos="fade-up"
                                            data-aos-duration={1000}
                                        >
                                            Proof of <span className="heading-2">Concepts</span>
                                        </div>
                                        <div
                                            className="_description text-left-justify"
                                            data-aos="fade-right"
                                            data-aos-duration={1000}
                                        >
                                            Technology is ever evolving and at the same time, during its
                                            involvement stage, it poses lot of limitations. So, it’s crucial
                                            to establish the technical feasibility of business idea.
                                        </div>
                                        <div
                                            className="_description text-left-justify"
                                            data-aos="fade-right"
                                            data-aos-duration={1000}
                                        >
                                            We help the startups in identifying the roadblocks by performing
                                            short and quick proofs of concepts. This technical feasibility
                                            establishes the road ahead.
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-12 col-lg-5"
                                    data-aos="fade-left"
                                    data-aos-duration={1000}
                                >
                                    <div className="group_people">
                                        <img
                                            src="./image/startup/startup_part5.svg"
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* part-6 */}
                <section className="part-6">
                    <div className="container">
                        <div className="row main-row">
                            <div
                                className="col-12 col-md-12 col-lg-12 text-center"
                                data-aos="fade-up"
                                data-aos-duration={1000}
                            >
                                <div className="heading">
                                    <h3>
                                        Our <span className="orange"> Startup</span> Process
                                    </h3>
                                    <p>
                                        
                                        Digital solutions to meet all your startup needs from MVP, website, apps to custom development.
                                    </p>
                                </div>
                            </div>
                            <div
                                className="row second-row"
                                data-aos="fade-right"
                                data-aos-duration={1000}
                            >
                                <div className="col-lg-12 arrow_adjust">
                                    <span>
                                        <img
                                            className="img-fluid arrow"
                                            src="image/startup/dotted-semi-arrow.svg"
                                            alt=""
                                        />
                                    </span>
                                    <span>
                                        <img
                                            className="img-fluid arrow2"
                                            src="image/startup/dotted-semi-arrow.svg"
                                            alt=""
                                        />
                                    </span>
                                </div>
                                <div className="col-lg-2 process_detail">
                                    <img className="idea" src="image/startup/1.svg" alt="" />
                                    <h3>Idea</h3>
                                    <p>
                                    Vital Stage for Refining Innovative Concepts into Potential Business Ventures.
                                    </p>
                                </div>
                                <div className="col-lg-2 process_detail">
                                    <img className="idea" src="image/startup/2.svg" alt="" />
                                    <h3>Start</h3>
                                    <p>
                                        Decisive actions are taken to turn ideas into reality, shaping future success.
                                    </p>
                                </div>
                                <div className="col-lg-2 process_detail">
                                    <img className="idea" src="image/startup/3.svg" alt="" />
                                    <h3>Work</h3>
                                    <p>
                                    Focused on developing, iterating products, collaborating, and establishing processes.
                                    </p>
                                </div>
                                <div className="col-lg-2 process_detail">
                                    <img className="idea" src="image/startup/4.svg" alt="" />
                                    <h3>Analysis</h3>
                                    <p>
                                    Performance evaluation, insights, and data-driven decisions are all involved to achieve refined, optimized, long-term success.
                                    </p>
                                </div>
                                <div className="col-lg-2 process_detail">
                                    <img className="idea" src="image/startup/5.svg" alt="" />
                                    <h3>Goal</h3>
                                    <p>
                                    This stage defines objectives, sets measurable targets, and aligns efforts for long-term success, ensuring startup growth and direction.
                                    </p>
                                </div>
                                <div className="col-lg-12 revese">
                                    <span>
                                        <img
                                            className="r1"
                                            src="image/startup/dotted-semi-reseve.svg"
                                            alt=""
                                        />
                                    </span>
                                    <span>
                                        <img
                                            className="r2"
                                            src="image/startup/dotted-semi-reseve.svg"
                                            alt=""
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Recent Work */}
              <RecentWork recentWork={props}/>
            </div>
        </Layouts>
        </>

    )
}

export default Startup