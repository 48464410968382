import React from 'react';

const Loader = () => {
    const overlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent background
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999, // ensures the overlay appears on top
      };
    
      const loadRowStyle = {
        display: 'flex',
        gap: '8px', // spacing between dots
      };
    
      const spanStyle = {
        display: 'inline-block',
        width: '10px',
        height: '10px',
        backgroundColor: '#f76002',
        borderRadius: '50%',
        animation: 'upDown 0.5s ease-in infinite alternate',
      };
    
      const spanVariants = [
        { backgroundColor: '#e85b04c4', animationDelay: '0.16s' },
        { backgroundColor: '#e85b0491', animationDelay: '0.32s' },
        { backgroundColor: '#e85b0456', animationDelay: '0.48s' },
      ];

    //   Transform css is added to the app.css file
    
      return (
        <div style={overlayStyle}>
          <div style={loadRowStyle}>
            <span style={spanStyle}></span>
            {spanVariants.map((variant, index) => (
              <span key={index} style={{ ...spanStyle, ...variant }}></span>
            ))}
          </div>
        </div>
      );
    };

export default Loader;

