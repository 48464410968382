import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Home from '../home'
import About from '../about'
import Services from '../services'
import Platform from '../servicePlatform'
import MobileApp from '../mobileApp'
import Uiux from '../serviceUIUX'
import Startup from '../startupSol'
import Portfolio from '../portfolio'
import Careers from '../careers'
import Contact from '../contact'
import Apply from '../apply'
import Recent from '../recentWork'
import PortfolioDetails from '../portfolioDetails'
import Login from '../admin/login'
import ForgotPassword from '../admin/forgot-password'
import PasswordReset from '../admin/forgot-password/passwordReset'
import webService from '../../services/webService'
import { useEffect } from 'react'
import { useState } from 'react'

const WebRoute = () => {

  const [recent, setRecent] = useState([])
  const [recentmobile, setRecentMobile] = useState([])
  const [recentplatform, setRecentPlatform] = useState([])
  const [recentuiux, setRecentUIUX] = useState([])
  const [recentstartup, setRecentStartup] = useState([])
  useEffect(()=>{
    getRecent();
    getRecentService();
  },[])
  
  const getRecent = async () => { 
      await webService.getRecent().then(res => {  
        setRecent(res.data?.result)
      })
  }

  const getRecentService = async () => { 
    await webService.getRecentService().then(res => {  
      setRecentPlatform(res.data?.result)
      setRecentMobile(res.data?.mobile)
      setRecentUIUX(res.data?.uiux)
      setRecentStartup(res.data?.startup)
    })
}

  return (
    <>
      <Routes>
        <Route exact path='/' element={<Home data={recent} />} />
        <Route path='/about' Component={About} />
        <Route path='/services' element={<Services data={recent} />} />
        <Route path='/platform' element={<Platform data={recentplatform} />} />
        <Route path='/mobile' element={<MobileApp data={recentmobile} />}/>
        <Route path='/uiux' element={<Uiux data={recentuiux} />} />
        <Route path='/startup' element={<Startup data={recentstartup} />} />
        <Route path='/portfolio' Component={Portfolio} />
        <Route path='/careers' Component={Careers} />
        <Route path='/contact' Component={Contact} />
        <Route path='/apply' Component={Apply} />
        <Route path='/recent_work' Component={Recent} />
        <Route path='/portfolio_details/:id' element={<PortfolioDetails data={recent}/>}/>
        <Route path='/cs-admin'  element={<Login/>}/>  
        <Route path='/forgot-password'  element={<ForgotPassword/>}/>  
        <Route path='/password/reset/:token'  element={<PasswordReset/>}/>  
        <Route path="*" element={<Navigate to="/"/>}/> 
      </Routes>
    </>

  )
}

export default WebRoute