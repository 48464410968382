import React, { useEffect, useRef, useState } from 'react';
import './css/contact_us.css';
import XError from './common/xError';
import Swal from "sweetalert2";
import Layouts from './layout/layout';
import webService from '../services/webService';

const Contact = () => {
    const [emailaddress,setEmailAddress] = useState("");
    const [phonenumber,setPhoneNumber] = useState(""); 
    const [address,setAddress] = useState([]); 
    const formRef = useRef(null);
    const [showAll, setShowAll] = useState(false); 
    const toggleShowAll = () => {
        setShowAll(!showAll);
    };
    const [state, setState] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
    });
    const [errors, setErrors] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
    });
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    // check form validation
    const checkValidation = () => {
        let error = {
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
            check: "",
        };

        const {
            name,
            email,
            phone,
            subject,
            message,
        } = state;

        let flag = true;
        if (name === "" || name === null || name === undefined) {
            error.name = "The Name field is required.";
            flag = false;
        }
        const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if (email === "" || email === null || email === undefined) {
            error.email = "The Email field is required.";
            flag = false;
        } else {
            if (email && !email.match(isValidEmail)) {
                error.email = "The email must be a valid email address.";
                flag = false;
            }
        }
        const isValidPhone = /^\+?([0-9 ()+\b]+$)/;
        if (phone === "" || phone === null || phone === undefined) {
            error.phone = "The Phone field is required.";
            flag = false;
        } else {
            if (phone && !phone.match(isValidPhone)) {
                error.phone = "Phone must be a valid phone.";
                flag = false;
            }
        }
        if (subject === "" || subject === null || subject === undefined) {
            error.subject = "The Subject field is required.";
            flag = false;
        } else {
            if (subject && subject.length > 50) {
                error.subject = "Subject should be less then 50 characters";
                flag = false;
            }
        }

        if (message === "" || message === null || message === undefined) {
            error.message = "The Message field is required.";
            flag = false;
        } else {
            if (message && message.length > 200) {
                error.message = "Message should be less then 200 characters";
                flag = false;
            }
        }

        if (phone && phone.length < 8 || phone.length > 11) {
            error.phone = "Phone should be less then 12 characters";
            flag = false;
        }
        if (!isChecked) {
            error.check = "Please check the checkbox";
            flag = false;
        }
        setErrors({ ...error });
        return flag;

    }

    // submit form data
    const onSubmit = () => {
        if (checkValidation()) {
            setState({
                name: "",
                email: "",
                phone: "",
                subject: "",
                message: "",
            });
            setIsChecked(false)
            Swal.fire("Success!");
        } else {

        }
    }

    // Scroll To Send Message Form
    const scrollToForm = () => {
        formRef.current.scrollIntoView()
    }

    useEffect(() => {
      getCompanyDetails();
    },[])
    const getCompanyDetails = async () => { 
      await webService.getCompany().then(res => { 
        // console.log(res.data) 
          setEmailAddress(res.data?.result[0]?.email ? res.data?.result[0].email : "") 
          setPhoneNumber(res.data?.result[0]?.phone ? res.data?.result[0].phone : "") 
          setAddress(res.data?.result[0]?.addresses? res.data?.result[0].addresses : "")
      })
    }
    return (
        <>
        <Layouts>
            <div className='contact_us'>
                <section className="part-1">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6"  data-aos="fade-down"
                                    data-aos-duration={1000}>
                                <div
                                    className="d-flex flex-column"
                                    data-aos="fade-down"
                                    data-aos-duration={1000}
                                >
                                    <div className="heading-1">
                                        You Have a <span style={{ color: "#F58634" }}>Question?</span>
                                        <br />
                                        Let us <span style={{ color: "#F58634" }}>Answer</span> it.
                                    </div>
                                    <div className="_description text-left-justify">
                                      
                                        We are here to provide you with the information and support you need,
                                        ensuring that all your queries are addressed promptly and comprehensively.
                                        
                                    </div>
                                    <div className="send_message" hidden>
                                        <button onClick={scrollToForm}>Send Message</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div
                                    className="one_people"
                                    data-aos="fade-left"
                                    data-aos-duration={1000}
                                >
                                    <img src="/image/contact_us/main_header.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="part-2">
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            {address.length > 0 ?
                            <div className="col-12 col-lg-4 d-flex align-items-stretch">
                                <div
                                    className="card card_box_wrapper"
                                    data-aos="fade-up"
                                    data-aos-duration={1000}
                                >
                                    {/* 189 Sector 10, Panchkula, Haryana 134109 */}
                                    <div className="card-body card_box center_box">
                                        <span className="img_box">
                                            <img src="/image/contact_us/address.svg" alt="address" />
                                        </span>
                                        <div className="card_box_title">Address</div> 
                                        {address &&
                                            address.map((add, index) => {
                                                const mapLink = `http://maps.google.com/?q=${encodeURIComponent(add.address)}`;

                                                if (!showAll && index === 0) {
                                                    return (
                                                        <div className={address.length < 1 ? 'card_box_text address-card-fix':'card_box_text'} key={index}>
                                                            {add.address}
                                                            {address.length > 1 && (
                                                                <div className='map-link'>
                                                                    {' '}
                                                                    <a href={mapLink} target="_blank">
                                                                        {' '}
                                                                        <img
                                                                            src="/image/contact_us/address.png"
                                                                            alt="address"
                                                                            width={25}
                                                                            height={25}
                                                                        />{' '}
                                                                    </a>
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                }

                                                if (showAll) {
                                                    return (
                                                        <div className="card_box_text address-card-fix" key={index}>
                                                            {add.address}
                                                            <div className='map-link'>
                                                                {' '}
                                                                <a href={mapLink} target="_blank">
                                                                    {' '}
                                                                    <img
                                                                        src="/image/contact_us/address.svg"
                                                                        alt="address"
                                                                        width={25}
                                                                        height={25}
                                                                    />{' '}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    );
                                                }

                                                return null;
                                            })}
                                        {address.length > 1 && (
                                            <button className="view-more-address" onClick={toggleShowAll}>
                                                {showAll ? <img
                                                                src="/image/contact_us/viewmore.svg"
                                                                alt="address" 
                                                            />
                                                            : 
                                                            <img
                                                                src="/image/contact_us/down.svg"
                                                                alt="address" 
                                                            />}
                                            </button>
                                              
                                        )}
                                       
                                    </div> 
                                    {
                                        address && address.length === 1? 
                                        address.map((add,index)=>{
                                            const mapLink = `http://maps.google.com/?q=${encodeURIComponent(add.address)}`;
                                            return(
                                                <div className="hover_buttons">
                                                    <a href={mapLink} target="_blank">
                                                        <button>View on Map</button>
                                                    </a>
                                                </div> 
                                            )
                                        })
                                        :""
                                    }
                                    
                                </div>
                            </div>
                            :''}
                            <div className="col-12 col-lg-4 d-flex align-items-stretch">
                                <div
                                    className="card card_box_wrapper"
                                    data-aos="fade-up"
                                    data-aos-duration={1000}
                                >
                                    <div className="card-body card_box center_box">
                                        <span className="img_box">
                                            <img src="/image/contact_us/email.svg" alt="email" />
                                        </span>
                                        <div className="card_box_title">Email</div>
                                        <div className="card_box_text">{emailaddress}</div>
                                    </div>
                                    <div className="hover_buttons">
                                        <a href="mailto:info@calidig.com" target="_blank">
                                            <button>Send an Email</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 d-flex align-items-stretch">
                                <div
                                    className="card card_box_wrapper"
                                    data-aos="fade-up"
                                    data-aos-duration={1000}
                                >
                                    <div className="card-body card_box center_box">
                                        <span className="img_box">
                                            <img src="/image/contact_us/phone.svg" alt="phone" />
                                        </span>
                                        <div className="card_box_title">Phone Number</div>
                                        <div className="card_box_text">+91 {phonenumber}</div>
                                    </div>
                                    <div className="hover_buttons">
                                        <a href="tel:+917527092710" target="_blank">
                                            <button>Call us Now</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="part-3" ref={formRef} hidden>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="heading" data-aos="fade-up" data-aos-duration={1000}>
                                    Tell us <span style={{ color: "#F58634" }}>About Yourself</span>
                                </div>
                                <div className="headline" data-aos="fade-up" data-aos-duration={1000}>
                                    Use the form below to quickly send us a message
                                </div>
                            </div>
                            <div className="col-12">
                                <form data-aos="fade-up" data-aos-duration={1000}>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <input type="text" placeholder="Name"
                                                value={state.name}
                                                onChange={(event) =>
                                                    setState({ ...state, name: event.target.value })
                                                } />
                                            <div className="error-msg">
                                                <XError message={errors.name} />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <input type="email" placeholder="Email Address"
                                                value={state.email}
                                                onChange={(event) =>
                                                    setState({ ...state, email: event.target.value })
                                                }
                                            />
                                            <div className="error-msg">
                                                <XError message={errors.email} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <input type="text" placeholder="Phone"
                                                value={state.phone}
                                                onChange={(event) =>
                                                    setState({ ...state, phone: event.target.value })
                                                }
                                            />
                                            <div className="error-msg">
                                                <XError message={errors.phone} />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <input type="text" placeholder="Subject"
                                                value={state.subject}
                                                onChange={(event) =>
                                                    setState({ ...state, subject: event.target.value })
                                                }
                                            />
                                            <div className="error-msg">
                                                <XError message={errors.subject} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <textarea rows={4} placeholder="Message"
                                                value={state.message}
                                                onChange={(event) =>
                                                    setState({ ...state, message: event.target.value })
                                                }
                                            />
                                            <div className="error-msg">
                                                <XError message={errors.message} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="checkbox-control">
                                                <input type="checkbox"
                                                    checked={isChecked}
                                                    onChange={handleCheckboxChange}
                                                    required />
                                                <label>
                                                    I agree to the transfer of personal data in accordance with
                                                    the Privacy Policy.
                                                </label>
                                            </div>
                                            <div className="error-msg">
                                                <XError message={errors.check} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-submit-button">
                                        <button type="button" onClick={onSubmit}>Send Message</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layouts>
        </>

    )
}

export default Contact