import React, { useEffect } from "react";
import Layouts from "../layout/layouts";
import XError from "../../common/xError";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import settingService from "../../../services/settingService";
import Swal from "sweetalert2";
import { useRef } from "react";
import portfolioService from "../../../services/portfolioService";
import DOMPurify from "dompurify";
import userService from "../../../services/userService";
import rolePermissionService from "../../../services/rolePermissionService";
import { set } from "react-ga";

const AddUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const[roles,setRoles]=useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
 
    role: "",
    isActive: "",
    permission:[],
  });

  const [errors, setError] = useState({
    name: "",
    email: "",
   
    role: "",
    isActive: "",
    permission:[],
  });
  const navigate = useNavigate();

  // set input data
  const handleChange = (event) => {
    const { name, value, options, multiple } = event.target;
  
    // Handle multiple selection
    if (multiple) {
      const selectedValues = Array.from(options)
        .filter(option => option.selected)
        .map(option => DOMPurify.sanitize(option.value));
      setFormData((prevData) => ({
        ...prevData,
        [name]: selectedValues,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: DOMPurify.sanitize(value),
      }));
    }
  };
  useEffect(()=>{
    fetchRoles();
  },[])

  const fetchRoles =async()=>{
    const token = localStorage.getItem("token");
    await rolePermissionService.allRoles(token).then((res) => {
      setRoles(res.data.data);
    }
    ).catch((err) => {
      console.log(err);
    });
  }

  const onSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
   

  
    if (checkValidation()) {
      const token = localStorage.getItem("token");
      const postData = new FormData();
      postData.append("name", formData.name);
      postData.append("email", formData.email);
   
      postData.append("isActive", formData.isActive);
      postData.append("role", formData.role);
      postData.append("permission", formData.permission);

      await userService
        .addNewUser(token, postData)
        .then(async (res) => {
          console.log(res.data);
          setIsLoading(false);
          Swal.fire(res.data.message);
          navigate("/users");
        })
        .catch((err) => {
          console.log(err);

          setIsLoading(false);
          Swal.fire(err.response.data.error);
        });
    } else {
      setIsLoading(false);
    }
  };
  const checkValidation = () => {
    let error = {
      name: "",
      email: "",
    
      role: "",
      isActive: "",
    };

    const { name, email, role, isActive } = formData;

    let flag = true;

    if (
      name === "" ||
      name === null ||
      name === undefined ||
      name.trim() === ""
    ) {
      error.name = "The name field is required.";
      flag = false;
    }
    if (
      email === "" ||
      email === null ||
      email === undefined ||
      email.trim() === ""
    ) {
      error.aboutUs = "The email field is required.";
      flag = false;
    }
   
    if (
      role === "" ||
      role === null ||
      role === undefined ||
      role.trim() === ""
    ) {
      error.category = "The role field is required.";
      flag = false;
    }
    if (
      isActive === "" ||
      isActive === null ||
      isActive === undefined ||
      isActive.trim() === ""
    ) {
      error.category = "The status field is required.";
      flag = false;
    }
    setError({ ...error });
    // console.log(errors)
    return flag;
  };

  const onReset = () => {
    setFormData({
      name: "",
      email: "",
     
      role: "",
      isActive: "",
    });
    setError({
      name: "",
      email: "",
   
      role: "",
      isActive: "",
    });
  };

  return (
    <Layouts>
      <div className="admin-portfolio">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12s">
                <Link to="/users" className="add-link-color">
                  <h4 className="dash-title">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M4.78645 10.7139L13.7804 19.7048C14.175 20.0984 14.8144 20.0984 15.21 19.7048C15.6047 19.3111 15.6047 18.6717 15.21 18.2781L6.92952 10.0005L15.209 1.72302C15.6037 1.32937 15.6037 0.689976 15.209 0.295329C14.8144 -0.098321 14.174 -0.098321 13.7794 0.295329L4.78545 9.28616C4.39687 9.67575 4.39687 10.3252 4.78645 10.7139Z"
                        fill="#1F4168"
                      />
                    </svg>
                    <span className="ps-3">Add User</span>
                  </h4>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="add-portfolio-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card add-portfolio-card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="mb-4">
                            <label className="form-label">Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              placeholder="Enter name"
                              maxLength={25}
                            />
                            <XError message={errors.name} />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb-4">
                            <label
                              for="exampleFormControlTextarea1"
                              class="form-label"
                            >
                              Email
                            </label>
                            <input
                              class="form-control"
                              name="email"
                              type="email"
                              value={formData.email}
                              id="exampleFormControlTextarea1"
                              onChange={handleChange}
                              placeholder="Enter Email Address"
                              maxLength={150}
                            ></input>
                            <XError message={errors.email} />
                          </div>
                        </div>
                      </div>
                 
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="mb-4">
                            <label className="form-label">Role</label>
                            <select
                              class="form-select form-select-lg"
                              aria-label=".form-select-lg example"
                              name="role"
                              value={formData.role}
                              onChange={handleChange}
                            >
                              <option value="">Select Role</option>
                              {roles &&
                                roles.map((role) => {
                                  return (
                                    <option value={role.id} key={role.id}>
                                      {role?.role}
                                    </option>
                                  );
                                })}
                   
                            </select>
                            <XError message={errors.role} />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb-4">
                            <label className="form-label">Status</label>
                            <select
                              class="form-select form-select-lg"
                              aria-label=".form-select-lg example"
                              name="isActive"
                              value={formData.isActive}
                              onChange={handleChange}
                            >
                              <option value="">Select Status</option>

                              <option value="true">Active</option>
                              <option value="false">Inactive</option>
                            </select>
                            <XError message={errors.isActive} />
                          </div>
                        </div>
                      </div>
                 
                      <div className="footer-button pt-2">
                        <button
                          type="button"
                          onClick={onReset}
                          className="cancel-button"
                        >
                          Cancel
                        </button>
                        {isLoading ? (
                          <button type="button" className="submit-button">
                            Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={onSubmit}
                            className="submit-button"
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
};

export default AddUser;
