import React from "react";
import Layouts from "../layout/layouts";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import XError from "../../common/xError";
import jobService from "../../../services/jobService";
import Swal from "sweetalert2";
import DOMPurify from "dompurify";

const JobTitle = () => {
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [state, setState] = useState({
    title: "",
  });
  const [error, setError] = useState({
    title: "",
  });

  const onsubmit = async () => {
    setIsLoading(true);
    if (checkValidation()) {
      await jobService
        .createJobTitle(token, state)
        .then(async (res) => {
          // console.log(res.data?.result);
          setIsLoading(false);
          Swal.fire("Job title added successfully");
          navigate("/job-title-list");
        })
        .catch((err) => {
          setIsLoading(false);
          setError({ ...error, title: err?.response.data.data.title });
          Swal.fire("Job title not created");
        });
    } else {
      setIsLoading(false);
    }
  };
  const checkValidation = () => {
    //  console.log("validation",state);
    let error = {
      title: "",
    };

    const { title } = state;

    let flag = true;
    if (
      title === "" ||
      title === null ||
      title === undefined ||
      title.trim() === ""
    ) {
      error.title = "The title field is required.";
      flag = false;
    }

    setError({ ...error });
    // console.log(errors)
    return flag;
  };
  const onReset = () => {
    setState({
      title: "",
    });

    setError({
      title: "",
    });
  };
  return (
    <Layouts>
      <div className="admin-portfolio">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12s">
                <Link to="/job-title-list" className="add-link-color">
                  <h4 className="dash-title">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M4.78645 10.7139L13.7804 19.7048C14.175 20.0984 14.8144 20.0984 15.21 19.7048C15.6047 19.3111 15.6047 18.6717 15.21 18.2781L6.92952 10.0005L15.209 1.72302C15.6037 1.32937 15.6037 0.689976 15.209 0.295329C14.8144 -0.098321 14.174 -0.098321 13.7794 0.295329L4.78545 9.28616C4.39687 9.67575 4.39687 10.3252 4.78645 10.7139Z"
                        fill="#1F4168"
                      />
                    </svg>
                    <span className="ps-3">Job Title</span>
                  </h4>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="add-portfolio-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card add-portfolio-card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="mb-4">
                            <label className="form-label">Job Title</label>
                            {/* <input type="text" className="form-control" name="title" 
                                                value={state.title}
                                                onChange={(event) =>
                                                setState({ ...state, title: event.target.value })
                                                }
                                                placeholder="Enter job title" maxLength={25}/>   */}
                            <input
                              type="text"
                              className="form-control"
                              name="title"
                              value={state.title}
                              onChange={(event) => {
                                // Sanitize the input to remove any harmful content
                                const sanitizedValue = DOMPurify.sanitize(
                                  event.target.value
                                );
                                setState({ ...state, title: sanitizedValue });
                              }}
                              placeholder="Enter job title"
                              maxLength={25}
                            />
                            <XError message={error.title} />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mt-3">
                            <div className="footer-button pt-2">
                              <button
                                type="button"
                                onClick={onReset}
                                className="cancel-button"
                              >
                                Cancel
                              </button>
                              {isLoading ? (
                                <button type="button" className="submit-button">
                                  Loading...
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={onsubmit}
                                  className="submit-button"
                                >
                                  Submit
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layouts>
  );
};

export default JobTitle;
