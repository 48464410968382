import React, { useEffect, useState } from 'react'
import './css/login.css'
import XError from '../common/xError';
import userService from '../../services/userService';
import { Link, useNavigate } from 'react-router-dom'; 
import { auth , googleProvider} from "../../firebase";
import { createUserWithEmailAndPassword,signInWithPopup, signOut } from "firebase/auth";
import { jwtDecode } from "jwt-decode";

const Login = () => {
	const [state, setState] = useState({
        email: "",
        password: ""
    });
    const [error, setError] = useState({
        email: "",
        password: ""
    });
    const [rememberMe, setRememberMe] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    let navigate = useNavigate();

    // This effect runs when the component mounts (page load)
    useEffect(() => {
        localStorage.removeItem('token'); 
        localStorage.removeItem("menuactive");
        localStorage.removeItem("hideshow");

        const rememberedEmail = localStorage.getItem('rememberedEmail');
        const rememberedPassword = localStorage.getItem('rememberedPassword');
        const check = localStorage.getItem("checkbox");
        const expirationTime = localStorage.getItem("tokenExpiration");

        if (expirationTime && Date.now() > expirationTime) {
            logout();  // Logout if the token is expired
        } else if (check === "true") {
            if (rememberedEmail && rememberedPassword) {
                setState({
                    email: rememberedEmail,
                    password: rememberedPassword
                });
                setRememberMe(check);
            }
        } else {
            localStorage.removeItem("rememberedEmail");
            localStorage.removeItem("rememberedPassword");
        }

        if (expirationTime) {
            setLogoutTimer(expirationTime - Date.now());  // Set the timer to logout after expiration
        }
    }, []);

    // Helper function to set the timer for token expiry
    const setLogoutTimer = (millisecondsUntilExpiry) => {
        setTimeout(() => {
            logout();  // Automatically logout when the token expires
        }, millisecondsUntilExpiry);
    };

    // Function to logout the user
    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiration');
        localStorage.removeItem('rememberedEmail');
        localStorage.removeItem('rememberedPassword');
        navigate("/login");  // Redirect to login page
    };

    // Function to handle Google Sign-In
    const signInWithGoogle = async () => {
        try {
            await signInWithPopup(auth, googleProvider);
            console.log(auth?.currentUser);
            await userService.loginWithGoogle({
                email: auth?.currentUser?.email
            }).then(res => {  
                const token = res?.data?.data?.token;
                const decodedToken = jwtDecode(token);
                const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds

                localStorage.setItem('token', token); 
                localStorage.setItem('user', auth?.currentUser?.displayName);
                localStorage.setItem('tokenExpiration', expirationTime);

                setLogoutTimer(expirationTime - Date.now());
                navigate("/dashboard");
            }).catch(handleLoginError);
        } catch (err) {
            console.error(err);
        }
    };

    // Function to handle login form submission
    const onSubmit = async (event) => {
        event.preventDefault();
        if (validation()) {
            await userService.loginService({
                email: state.email,
                password: state.password
            }).then(res => {
                console.log(res.data.data);
                
                const token = res?.data?.data?.token;
                const decodedToken = jwtDecode(token);
                const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds

                localStorage.setItem('token', token);
                localStorage.setItem('user', res?.data?.data?.name);
                localStorage.setItem('checkbox', rememberMe);
                localStorage.setItem('role', res?.data?.data?.role);
                localStorage.setItem('tokenExpiration', expirationTime);

                setLogoutTimer(expirationTime - Date.now());
                if (rememberMe) {
                    localStorage.setItem('rememberedEmail', res?.data?.data?.email);
                    localStorage.setItem('rememberedPassword', res?.data?.data?.password);
                }
                navigate("/dashboard");
            }).catch(handleLoginError);
        }
    };

    // Handle validation errors from the API
    const handleLoginError = (err) => {
        let error = {
            email: "",
            password: ""
        };
        console.log(err);
        const { email, password } = err?.response?.data.data;

        if (email !== undefined || email !== "" || email !== null) {
            error.email = email;
        }
        if (password !== undefined || password !== "" || password !== null) {
            error.password = password;
        }

        setError({ ...error });
    };

    // Function to handle checkbox change (remember me)
    const handleCheckboxChange = (event) => {
        setRememberMe(event.target.checked);
    };

    // Check for validation of inputs
    const validation = () => {
        let flag = true;
        let error = {
            email: '',
            password: ''
        };

        if (state.email === null || state.email === undefined || state.email === "") {
            error.email = "The email field is required.";
            flag = false;
        } else if (!validateEmail(state.email)) {
            error.email = "The email must be a valid email address.";
            flag = false;
        }

        if (state.password === null || state.password === undefined || state.password === "") {
            error.password = "The password field is required.";
            flag = false;
        }

        setError({ ...error });
        return flag;
    };

    // Helper function to validate email
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

  return (
    <div className="authincation-content">
		<section className="login-part">
			<div className="container">
				<div className='login'>
				<div className='login-card'>
					<div className='card'> 
					<form onSubmit={onSubmit}>
						<div className='login-header text-center'>
							<img src='../image/calidig-logo.svg' alt='no_image' className="img-fluid admin-logo"/>
						</div>
						<div className='card-body'>
							<div className="mb-4">
								<label htmlFor="email" className="form-label">Email</label>
								<input type="text" className="form-control"
								 id="email" placeholder="Enter email"
								 value={state.email}
								 onChange={(event) => setState({...state,email: event.target.value})}
								 autoComplete="off"/>
								 <XError message={error.email} />
							</div>
							<div className="mb-4">
								<label htmlFor="password" className="form-label">Password</label>
								<input type={showPassword ? 'text' : 'password'} className="form-control"
								 id="password" placeholder="Enter password"
								 value={state.password}
								 onChange={(event) => setState({...state,password: event.target.value})}
								 autoComplete="off"/>
								 <i 
									className={`fa fa-eye${showPassword?'':'-slash'}`}
									onClick={() => setShowPassword(!showPassword)}
									aria-hidden="true"></i>
								 <XError message={error.password} />
							</div>
							<div className="checkbox-control d-flex">
								<input type="checkbox" id="remember" checked={rememberMe}
								onChange={handleCheckboxChange}/>
								<label htmlFor="remember">
									Remember Me
								</label>
							</div>
							<div className="form-login-button pt-4">
								<button type="submit">Login</button>
							</div>
							<div className="row d-flex justify-content-between mt-2">
								<div className="text-center" id="forget">
									<Link to="/forgot-password" className='forgot-style'>Forgot password?</Link>
									<div>Or </div>
									<button onClick={signInWithGoogle} type="button" className="login_with_gmail"> <img  alt="Google sign-in" src="../image/gmail.png" />
     									 Login with Google</button>
								</div>
							</div>

						</div>
					</form>
				    </div>
				</div>
				</div>
				
			</div>
		</section>
	</div>
  )
}

export default Login