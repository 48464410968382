import React,{useEffect} from 'react'  
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';  

const Header = () => {
    let navigate = useNavigate(); 
    const user = localStorage.getItem('user') 
   

    useEffect(() => {
        const token = localStorage.getItem('token');
        const tokenExpiration = localStorage.getItem('tokenExpiration');  // Get token expiration time from localStorage

        if (token && tokenExpiration) {
            // Check if the token is expired
            if (Date.now() > tokenExpiration) {
                // Token is expired, logout the user
                localStorage.removeItem('token');
                localStorage.removeItem('tokenExpiration');
                navigate('/cs-admin');  // Redirect to the login page
            }
        } else {
            // No token found, redirect to login
            navigate('/cs-admin');
        }
    }, [navigate]);

    const userInitials = user
        .split(' ')[0]  // Get the first word
        .charAt(0)      // Get the first character
        .toUpperCase(); // Convert to uppercase
    // const userInitials = user
    // .split(' ')
    // .map(name => name[0])
    // .join('')
    // .toUpperCase(); 

    const onLogout = () =>{
        Swal.fire({
            title: "Are you sure",
            text: "you want to logout?", 
            showCancelButton: true, 
            customClass: {
                confirmButton: 'custom-confirm-button',
                cancelButton: 'custom-cancel-button',
                popup: 'custom-swal-popup',
            }, 
            cancelButtonText: 'Cancel',
            confirmButtonText: "Logout", 
          }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem("token")
                localStorage.removeItem("role")
                localStorage.removeItem("user")
                localStorage.setItem("child",false)
                navigate('/cs-admin')
            }
          }); 
      
    }
     
    return (
        <>
            <nav class="navbar navbar-default admin-navigation">
                <div class="container-fluid">
                     <div className='search-box'>
            
                    </div> 
                    <div className='nav navbar-nav navbar-right'> 
                    {user} 
                        </div>
                    <div className='nav navbar-nav navbar-right dropdown'> 
                        <div className='pic_user dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" style={{width:"40px",height:"40px"}}>
                               {userInitials}
                        </div>
                        <ul class="dropdown-menu logout-ul">
                            <li className='logout-li' onClick={onLogout}>
                                {/* <a href="#" className='logout-style'> */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <g clip-path="url(#clip0_3415_14561)">
                                <path d="M11.2499 9.75009C10.8352 9.75009 10.4999 10.0861 10.4999 10.5V13.5001C10.4999 13.9133 10.1639 14.25 9.74986 14.25H7.49988V3.00016C7.49988 2.35965 7.0919 1.78742 6.47843 1.57441L6.25638 1.50013H9.74986C10.1639 1.50013 10.4999 1.83685 10.4999 2.25021V4.50019C10.4999 4.91408 10.8352 5.25014 11.2499 5.25014C11.6646 5.25014 11.9998 4.91408 11.9998 4.50019V2.25021C11.9998 1.00974 10.9903 0.00023352 9.74986 0.00023352H1.68748C1.65894 0.00023352 1.63503 0.0129951 1.60729 0.0167216C1.57115 0.0136982 1.5367 0.000198364 1.50003 0.000198364C0.672779 0.00023352 0 0.672907 0 1.50016V15C0 15.6405 0.407984 16.2128 1.02145 16.4258L5.53495 17.9303C5.68794 17.9775 5.8401 18.0001 5.99995 18.0001C6.82721 18.0001 7.49984 17.3273 7.49984 16.5V15.7501H9.74982C10.9903 15.7501 11.9998 14.7406 11.9998 13.5001V10.5C11.9998 10.0861 11.6646 9.75009 11.2499 9.75009H11.2499Z" fill="#F58634"/>
                                <path d="M17.7801 6.9699L14.7801 3.96997C14.6752 3.86501 14.5416 3.79351 14.3961 3.76452C14.2506 3.73553 14.0998 3.75034 13.9627 3.8071C13.6829 3.92343 13.4999 4.19712 13.4999 4.50019V6.75017H10.5C10.0859 6.75017 9.74988 7.08609 9.74988 7.50012C9.74988 7.91415 10.0859 8.25006 10.5 8.25006H13.4999V10.5C13.4999 10.8031 13.6829 11.0768 13.9627 11.1931C14.0997 11.2499 14.2506 11.2647 14.3961 11.2357C14.5415 11.2068 14.6752 11.1353 14.7801 11.0304L17.7801 8.03034C18.0733 7.73714 18.0733 7.2631 17.7801 6.9699Z" fill="#F58634"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_3415_14561">
                                <rect width="18" height="18" fill="white"/>
                                </clipPath>
                            </defs>
                            </svg>
                                <span className='ps-2'>Log Out</span>
                                {/* </a> */}
                                
                                </li> 
                        </ul>
                    </div>
                     
                </div>
            </nav>
        </>
    )
}

export default Header