import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Dashboard from '../dashboard' 
import AdminPortolio from '../portfolio/adminPortfolio'
import AddPortfolio from '../portfolio/addPortfolio'
import PortfolioList from '../portfolio-details/index'
import PortfolioaddDetails from '../portfolio-details/addDetails'
import PortfolioeditDetails from '../portfolio-details/editDetails'
import EditPortfolio from '../portfolio/editPortfolio'
import Setting from '../setting'
import ServiceList from '../service'
import AddService from '../service/addService'
import EditService from '../service/editService'
import SocailList from '../social'
import AddSocial from '../social/addSocial'
import EditSocial from '../social/editSocial' 
import AddComplete from '../ourcomplete/addComplete' 
import Jobs from '../job'
import AddJob from '../job/addJob'
import EditJob from '../job/editJob'
import TechnologyList from '../technology'
import AddTechnology from '../technology/addTechnology'
import EditTechnology from '../technology/editTechnology'
import ReviewList from '../clientReview'
import AddReview from '../clientReview/addReview'
import EditReview from '../clientReview/editReview'
import Login from '../login'
import Home from '../../home'
import About from '../../about'
import Services from '../../services'
import Platform from '../../servicePlatform'
import MobileApp from '../../mobileApp'
import Uiux from '../../serviceUIUX'
import Startup from '../../startupSol'
import Portfolio from '../../portfolio'
import Careers from '../../careers'
import Contact from '../../contact'
import Apply from '../../apply'
import Recent from '../../recentWork'
import PortfolioDetails from '../../portfolioDetails'
import ForgotPassword from '../forgot-password'
import PasswordReset from '../forgot-password/passwordReset'
import { useState } from 'react'
import { useEffect } from 'react'
import webService from '../../../services/webService'
import JobTitle from '../job/jobTitle'
import JobTitleList from '../job/jobTitleList'
import DeleteRecords from '../job/deleteRecords'
import LogoList from '../clientReview/logoList'
import AddLogo from '../clientReview/addLogo'
import UsersList from '../users'
import AddUser from '../users/addUser'
import EditUser from '../users/editUser'
import RoleAndPermission from '../role-permission'
import AddRole from '../role-permission/add-role'
import EditRole from '../role-permission/edit-role'

const AuthenticationRoute = () => {
  const [recent, setRecent] = useState([])
  const [recentmobile, setRecentMobile] = useState([])
  const [recentplatform, setRecentPlatform] = useState([])
  const [recentuiux, setRecentUIUX] = useState([])
  const [recentstartup, setRecentStartup] = useState([])
  useEffect(()=>{
    getRecent();
    getRecentService();
    const token = localStorage.getItem('token');
  },[])
  
  const getRecent = async () => { 
      await webService.getRecent().then(res => {  
        setRecent(res.data?.result)
        console.log(res.data);
        
      })
  }

  const getRecentService = async () => { 
    await webService.getRecentService().then(res => {  
      setRecentPlatform(res.data?.result)
      setRecentMobile(res.data?.mobile)
      setRecentUIUX(res.data?.uiux)
      setRecentStartup(res.data?.startup)
    })
}
  return (
    <>
    <Routes>
        <Route exact path='/' element={<Home data={recent} />} />
        <Route path='/about' Component={About} />
        <Route path='/services' element={<Services data={recent} />} />
        <Route path='/platform' element={<Platform data={recentplatform} />} />
        <Route path='/mobile' element={<MobileApp data={recentmobile} />}/>
        <Route path='/uiux' element={<Uiux data={recentuiux} />} />
        <Route path='/startup' element={<Startup data={recentstartup} />} />
        <Route path='/portfolio' Component={Portfolio} />
        <Route path='/careers' Component={Careers} />
        <Route path='/contact' Component={Contact} />
        <Route path='/apply' Component={Apply} />
        <Route path='/recent_work' Component={Recent} />
        <Route path='/portfolio_details/:id' element={<PortfolioDetails data={recent}/>}/>
        <Route path='/cs-admin'  element={<Login/>}/>  
        <Route path='/forgot-password'  element={<ForgotPassword/>}/>  
        <Route path='/password/reset/:token'  element={<PasswordReset/>}/>  
        <Route path="*" element={<Navigate to="/"/>}/> 
        
        <Route path="/dashboard" element={<Dashboard/>}/> 
        <Route path='/admin-portfolios' element={<AdminPortolio/>}/>
        <Route path='/add-portfolio' element={<AddPortfolio/>}/>
        <Route path='/edit-portfolio/:id' element={<EditPortfolio/>}/>
        <Route path='/admin-settings' element={<Setting/>}/>
        <Route path='/service-list' element={<ServiceList/>}/>
        <Route path='/add-service' element={<AddService/>}/>
        <Route path='/edit-service/:id' element={<EditService/>}/>
        <Route path='/social-list' element={<SocailList/>}/>
        <Route path='/add-social' element={<AddSocial/>}/>
        <Route path='/edit-social/:id' element={<EditSocial/>}/> 
        <Route path='/our-works' element={<AddComplete/>}/> 
        <Route path='/job-list' element={<Jobs/>}/>
        <Route path='/add-job' element={<AddJob/>}/>
        <Route path='/edit-job/:id' element={<EditJob/>}/>
        <Route path='/add-job-title' element={<JobTitle/>}/>
        <Route path='/job-title-list' element={<JobTitleList/>}/>
        <Route path='/job-archive' element={<DeleteRecords/>}/>
        <Route path='/technology-list' element={<TechnologyList/>}/>
        <Route path='/add-technology' element={<AddTechnology/>}/>
        <Route path='/edit-technology/:id' element={<EditTechnology/>}/>
        <Route path='/review-list' element={<ReviewList/>}/>
        <Route path='/add-review' element={<AddReview/>}/>
        <Route path='/edit-review/:id' element={<EditReview/>}/>
        <Route path='/logo-list' element={<LogoList/>}/>
        <Route path='/add-logo' element={<AddLogo/>}/>
        {/* <Route path="*" element={<Navigate to="/cs-admin"/>}/>  */}
        {/* <Route exact path='/cs-admin'  element={<Login/>}/>  */}
        <Route path='/portfolio-list' element={<PortfolioList/>} />
        <Route path='/add-portfolioDetails/:id' element={<PortfolioaddDetails/>} /> 
        <Route path='/edit-portfolioDetails/:id' element={<PortfolioeditDetails/>} />
        <Route path='/users' element={<UsersList/>} />
        <Route path="/add-user" element={<AddUser/>}/>
        <Route path='/edit-user/:id' element={<EditUser/>} />

        <Route path='/role-permission' element={<RoleAndPermission/>} />
        <Route path='/add-role' element={<AddRole/>} />
        <Route path='/edit-role/:id' element={<EditRole/>} />
    </Routes>
    </>
  )
}

export default AuthenticationRoute