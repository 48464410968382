import { useEffect } from 'react'; 
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './components/css/style.css'; 
import PortalRoute from './components/admin/layout';
import './components/admin/css/header.css'
import { useState } from 'react';
import webService from './services/webService';
import baseURL from './components/utils/baseURL';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

const TRACKING_ID = "UA-293530659-1"; // OUR_TRACKING_ID G-R8DYDK4MW2   UA-281994682-2
ReactGA.initialize(TRACKING_ID); // Replace with your tracking ID UA-204806892-1 UA-283041709-1 


function App() { 
  const [iconData, setIconData] = useState("");
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
      setInitialized(true);
      getCompanyDetails();
    },[])

    useEffect(() => {
        if (initialized) {
          ReactGA.pageview(location.pathname + location.search);
          
        }
    }, [initialized, location]);

  const getCompanyDetails = async () => { 
    await webService.getCompany().then(res => {  
        setIconData(res.data?.result[0]?.favicon ? res.data?.result[0].favicon : "")  
    })
  }
   const faviconLink = document.getElementById("favicon");

   faviconLink.href = baseURL+"/"+iconData;

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <> 
      {/* <WebRoute/>  */}
      <PortalRoute /> 
    </>
  );
}

export default App;
