import React from 'react'  
import { isAuthenticated } from '../../../services/authService' 
import AuthenticationRoute from './authenticationRoute'
import AuthenticatedRoute from './authenticatedRoute'

const PortalRoute = () => {




  return (
    <>
     {isAuthenticated() ? <AuthenticationRoute/> : <AuthenticatedRoute/>}
     {/* <AuthenticationRoute/> */}
    </>
  )
}

export default PortalRoute
