import axios from "axios";
import baseURL from "../components/utils/baseURL.js"

class ReviewServices { 
 
     // get Client Review
     async getReview(token) { 
        return await axios.get(`${baseURL}/review`,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

    // create Client Review
    async createReview(token,data) {  
        return await axios.post(`${baseURL}/review`,data,{
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "multipart/form-data" 
            }
            
        });
    }  

     // edit Review 
     async detailReview(token,id) {  
        // console.log(id)
        return await axios.get(`${baseURL}/review/edit?id=`+id,{
            headers: {
                'Authorization': `Bearer ${token}` 
            }
        });
    }

     // update Review
     async updateReview(token,data) {  
        // console.log(data)
        return await axios.post(`${baseURL}/review/update`,data,{
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "multipart/form-data" 
            }
            
        });
    }

     // delete Review 
     async deleteReview(token,data) {  
        return await axios.post(`${baseURL}/review/delete`,data,{
            headers: {
                'Authorization': `Bearer ${token}` 
            }
        });
    }

    // get Client Logo
    async getLogo(token) { 
        return await axios.get(`${baseURL}/review/logo`,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    }
    
    // create Client Logo
    async createLogo(token,data) {  
        return await axios.post(`${baseURL}/review/logo`,data,{
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "multipart/form-data" 
            }
            
        });
    } 

    // delete client Logo 
    async deleteClientLogo(token,data) {  
        return await axios.post(`${baseURL}/review/deletelogo`,data,{
            headers: {
                'Authorization': `Bearer ${token}` 
            }
        });
    }
    
} 
    
export default new ReviewServices()