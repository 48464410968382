import React from 'react';

const ComponentLoader = () => {
    const containerStyle = {
        position: 'relative', // allows the loader to be positioned within this container
        width: '100%', 
        height: '100%', 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const loadRowStyle = {
        display: 'flex',
        gap: '8px', // spacing between dots
    };

    const spanStyle = {
        display: 'inline-block',
        width: '10px',
        height: '10px',
        backgroundColor: '#f76002',
        borderRadius: '50%',
        animation: 'upDown 0.5s ease-in infinite alternate',
    };

    const spanVariants = [
        { backgroundColor: '#e85b04c4', animationDelay: '0.16s' },
        { backgroundColor: '#e85b0491', animationDelay: '0.32s' },
        { backgroundColor: '#e85b0456', animationDelay: '0.48s' },
    ];

    return (
        <div style={containerStyle}>
            <div style={loadRowStyle}>
                <span style={spanStyle}></span>
                {spanVariants.map((variant, index) => (
                    <span key={index} style={{ ...spanStyle, ...variant }}></span>
                ))}
            </div>
        </div>
    );
};

export default ComponentLoader;
