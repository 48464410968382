export const permissions = [
    { value: "dashboard", label: "Dashboard" },
    { value: "portfolio", label: "Portfolio" },
    { value: "jobs", label: "Jobs" },
    { value: "client_review", label: "Client Review" },
    { value: "technologies", label: "Technologies" },
    { value: "services", label: "Services" },
    { value: "social_media", label: "Social Media" },
    { value: "our_works", label: "Our Works" },
    { value: "settings", label: "Settings" },
    { value: "client_logos", label: "Client Logos" },
    { value: "users", label: "Users" },
    { value: "role", label: "Roles and Permissions" },
  ];