import React from 'react';

const XError = (props) => {

	const { message } = props;

	if (Boolean(message) === true) {
		return (
			<span className="form-text text-danger">
				{message}
			</span>
		)
	}

	return null;
}

export default XError;