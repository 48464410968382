import axios from "axios";
import baseURL from "../components/utils/baseURL.js"

class PortfolioServices {

   // get portfolio 
    async getPortfolio(token) { 
        return await axios.get(`${baseURL}/portfolios/`,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
      }

    // get Categories 
    async getCategories() { 
        return await axios.get(`${baseURL}/categories/` 
        );
    }

    // get Categories 
    async getTags() { 
        return await axios.get(`${baseURL}/tags/` 
        );
    }

    // get portfolio 
    async createPortfolio(token,data) {  
        return await axios.post(`${baseURL}/portfolios/`,data,{
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "multipart/form-data" 
            }
            
        });
    }

       // delete portfolio 
    async deletePortfolio(token,data) {  
        return await axios.post(`${baseURL}/portfolios/delete`,data,{
            headers: {
                'Authorization': `Bearer ${token}` 
            }
        });
    }

       // edit portfolio 
    async detailPortfolio(token,id) {  
        // console.log(id)
        return await axios.get(`${baseURL}/portfolios/edit?id=`+id,{
            headers: {
                'Authorization': `Bearer ${token}` 
            }
        });
    }

       // update portfolio 
       async updatePortfolio(token,data) {  
        // console.log(data)
        return await axios.post(`${baseURL}/portfolios/update`,data,{
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "multipart/form-data" 
            }
            
        });    
    }
    
   
}

   
    
    export default new PortfolioServices()