import React, { useEffect, useRef, Suspense } from "react";
import { NavLink } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Layouts from "./layout/layout";
import ComponentLoader from "./common/ComponentLoader";

// Dynamically import the components
const DigitalExperience = React.lazy(() => import("./Home/DigitalExperience"));
const RecentWork = React.lazy(() => import("./Home/RecentWork"));
const ClientReview = React.lazy(() => import("./Home/ClientReview"));
const OurService = React.lazy(() => import("./Home/OurService"));
const CompletedWork = React.lazy(() => import("./Home/CompletedWork"));

const Home = (props) => {
  const swiperRef = useRef(null);

  gsap.registerPlugin(ScrollTrigger);

  // Hero Image scrolling animation
  useEffect(() => {
    const heroImage = document.querySelector("#pic-2_container");
    let mm = gsap.matchMedia();
    mm.add("(min-width: 992px)", () => {
      gsap.to(heroImage, {
        x: 20, //Move the image right
        y: 440, // Move the image down
        rotation: 0, // Rotate the image 0 degrees
        ease: "none",
        scrollTrigger: {
          trigger: ".main_div",
          start: "20% top",
          endTrigger: ".responsive_outer",
          end: "center center",
          scrub: true,
        },
      });
    });
    mm.add("(min-width: 1200px)", () => {
      gsap.to(heroImage, {
        x: 40, //Move the image right
        y: 500, // Move the image down
        rotation: 0, // Rotate the image 0 degrees
        ease: "none",
        scrollTrigger: {
          trigger: ".main_div",
          start: "20% top",
          endTrigger: ".responsive_outer",
          end: "center center",
          scrub: true,
        },
      });
    });
    mm.add("(min-width: 1500px)", () => {
      gsap.to(heroImage, {
        x: 0, //Move the image right 60
        y: 500, // Move the image down
        rotation: 0, // Rotate the image 0 degrees
        ease: "none",
        scrollTrigger: {
          trigger: ".main_div",
          start: "20% top",
          endTrigger: ".responsive_outer",
          end: "center center",
          scrub: true,
        },
      });
    });
    mm.add("(min-width: 2500px)", () => {
      gsap.to(heroImage, {
        x: 0, //Move the image right60
        y: 550, // Move the image down
        rotation: 0, // Rotate the image 0 degrees
        ease: "none",
        scrollTrigger: {
          trigger: ".main_div",
          start: "10% top",
          endTrigger: ".responsive_outer",
          end: "center center",
          scrub: true,
        },
      });
    });
  }, []);

  // Swiper
//   useEffect(() => {
//     if (swiperRef.current) {
//       document.getElementsByClassName("swiper-left")[0].style.opacity = 0.5;
//       document.getElementsByClassName(
//         "swiper-button-prev-new"
//       )[0].style.cursor = "not-allowed";

//       swiperRef.current.swiper.on("slideChange", () => {
//         if (swiperRef.current.swiper.isBeginning) {
//           document.getElementsByClassName("swiper-left")[0].style.opacity = 0.5;
//           document.getElementsByClassName(
//             "swiper-button-prev-new"
//           )[0].style.cursor = "not-allowed";
//         } else {
//           document.getElementsByClassName("swiper-left")[0].style.opacity = 1;
//           document.getElementsByClassName(
//             "swiper-button-prev-new"
//           )[0].style.cursor = "pointer";
//         }
//         if (swiperRef.current.swiper.isEnd) {
//           document.getElementsByClassName(
//             "swiper-right"
//           )[0].style.opacity = 0.5;
//           document.getElementsByClassName(
//             "swiper-button-next-new"
//           )[0].style.cursor = "not-allowed";
//         } else {
//           document.getElementsByClassName("swiper-right")[0].style.opacity = 1;
//           document.getElementsByClassName(
//             "swiper-button-next-new"
//           )[0].style.cursor = "pointer";
//         }
//       });
//     }
//   }, []);

  return (
    <>
      <Layouts>
        <div className="home">
          <section className="main_div">
            <div className="maine-div">
              <div className="container">
                <div className="row align-items-center">
                  <div
                    className="col-lg-6 col-12"
                    data-aos="fade-down"
                    data-aos-duration={1000}
                  >
                    <h1 data-aos="fade-down" data-aos-duration={1000}>
                      <span
                        className="first"
                        style={{
                          color: "#F58634",
                          display: "block",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Transforming Your{" "}
                        <span style={{ color: "black" }}>Ideas</span>{" "}
                        <span style={{ color: "#1E1E1E", display: "block" }}>
                          into Digital{" "}
                          <span style={{ color: "#F58634" }}>Solutions</span>
                          <br />
                        </span>{" "}
                      </span>
                    </h1>
                    <p data-aos="fade-right" data-aos-duration={1000}>
                      We Deliver Calibrated Digital Solutions to Advance Your
                      Business.
                    </p>
                    <div data-aos="fade-down" data-aos-duration={1000}>
                      <NavLink to="/contact">
                        <button>Get Started</button>
                      </NavLink>
                    </div>
                    <div style={{ marginTop: 52 }}>
                      <img
                        className="img-fluid"
                        src="/image/icons.png"
                        alt=""
                        data-aos="zoom-in-up"
                        data-aos-duration={1000}
                        width={448}
                        height={176}
                        loading="eager"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div data-aos="fade-down-left" data-aos-duration={1000}>
                      <div id="pic_container">
                        <img
                          id="pic"
                          className="img-fluid"
                          src="/image/1.png"
                          alt=""
                          style={{ width: "100%" }}
                          height={100}
                          loading="eager"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Responsivness */}
          <section className="responsive_outer">
            <div className="responsive">
              <div className="container">
                <div className="row align-items-center justify-content-between">
                  <div
                    className="col-12 col-lg-6"
                    style={{ paddingRight: "60px" }}
                  >
                    <h4
                      className="adjust"
                      data-aos="fade-up"
                      data-aos-duration={1000}
                    >
                      <span>Boost Your Online Perform</span>
                      ance with Technical Expertise and Quality Products
                    </h4>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div id="pic-2_container">
                      <img
                        id="pic-2"
                        className="img-fluid"
                        src="/image/2.4.png"
                        alt=".."
                        style={{ width: "100%" }}
                        height={100}
                        loading="eager"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Suspense fallback={<ComponentLoader />}>
            <OurService />
          </Suspense>

          {/*Why choose us */}
          <section style={{ backgroundColor: "#FCFAF8" }}>
            <div
              className="choose-div"
              style={{ paddingTop: 100, paddingBottom: 100 }}
            >
              <div className="container">
                <div className="row title_row">
                  <div className="col-12">
                    <h4 data-aos="fade-up" data-aos-duration={1000}>
                      Why <span style={{ color: "#F58634" }}>Choose Us </span>
                    </h4>
                    <p
                      data-aos="fade-up"
                      data-aos-duration={1000}
                      className="choose-p"
                    >
                      We blend creativity, expertise, and dedication, ensuring
                      customized solutions
                      <br /> that drive success and meet your unique business
                      needs.
                    </p>
                  </div>
                </div>
                <div
                  className="row"
                  data-aos="fade-up"
                  data-aos-duration={1000}
                >
                  <div className="col-12 col-lg-4">
                    <div className="card_box">
                      <div>
                        <img
                          src="image/svg/Frame-2.svg"
                          alt="no images"
                          width={40}
                          height={40}
                        />
                      </div>
                      <div className="card_box_title">
                        Customer Relationship
                      </div>
                      <div className="card_box_text">
                        We understand that our growth is directly proportional
                        to success of our customers.
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="card_box center_box">
                      <div>
                        <img
                          src="image/svg/Frame-1.svg"
                          alt="no images"
                          width={40}
                          height={40}
                        />
                      </div>
                      <div className="card_box_title">Innovation</div>
                      <div className="card_box_text">
                        Being in startup boat, innovation is the key to succeed.
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="card_box">
                      <div>
                        <img
                          src="image/svg/Frame-7.svg"
                          alt="no images"
                          width={40}
                          height={40}
                        />
                      </div>
                      <div className="card_box_title">Aspirational</div>
                      <div className="card_box_text">
                        We are a startup and would like to grow.
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="card_box">
                      <div>
                        <img
                          src="image/svg/Frame-4.svg"
                          alt="no images"
                          width={40}
                          height={40}
                        />
                      </div>
                      <div className="card_box_title">Delivery Excellence</div>
                      <div className="card_box_text">
                        On-schedule delivery, communication and learn from
                        feedbacks.
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="card_box center_box">
                      <div>
                        <img
                          src="image/svg/Frame-3.svg"
                          alt="no images"
                          width={40}
                          height={40}
                        />
                      </div>
                      <div className="card_box_title">Productized Approach</div>
                      <div className="card_box_text">
                        Developing a process, idea, skill, or service to make it
                        marketable for sale to the public.
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="card_box">
                      <div>
                        <img
                          src="image/svg/Frame-5.svg"
                          alt="no images"
                          width={40}
                          height={40}
                        />
                      </div>
                      <div className="card_box_title">Optimum Costs</div>
                      <div className="card_box_text">
                        We operate at minimal operational expenses to offer
                        optimized costs to customers.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* our process of work */}
          <section>
            <div
              className="home-process"
              style={{
                paddingTop: 100,
                paddingBottom: 100,
                background: "#ffffff",
              }}
            >
              <div className="container">
                <div className="row home-process-row align-items-center">
                  <div
                    className="col-lg-6 col-12"
                    data-aos="fade-right"
                    data-aos-duration={1000}
                  >
                    <img
                      src="image/svg/Group 40081.svg"
                      alt="no images"
                      id="home-process-img"
                      width={100}
                      height={780}
                    />
                  </div>
                  <div className="col-lg-6 col-12">
                    <h4
                      className="home-process-head"
                      data-aos="fade-up"
                      data-aos-duration={1000}
                    >
                      <span style={{ color: "#1E1E1E" }}> Our </span>
                      <span style={{ color: "#F58634" }}>Process of Work</span>
                    </h4>
                    <p
                      className="home-process-p text-left-justify"
                      style={{
                        fontSize: 16,
                        fontWeight: 500,
                        color: "#666666",
                        lineHeight: "20px",
                        marginBottom: 40,
                      }}
                      data-aos="fade-left"
                      data-aos-duration={1000}
                    >
                      We then fine-tune a marketing plan that allows us to laser
                      focus and target your audience through the right channels.
                    </p>
                    <div data-aos="fade-left" data-aos-duration={1000}>
                      <div className="home-process-option">
                        <div className="option_pic d-flex flex-column">
                          <img
                            src="image/svg/strategy.svg"
                            alt="no images"
                            width={62}
                            height={62}
                          />
                          <span className="flex-grow-1 dotted_line" />
                        </div>
                        <div className="option-details">
                          <h5
                            className="option-header"
                            style={{
                              fontSize: 22,
                              fontWeight: 600,
                              lineHeight: "27px",
                              color: "#333333",
                              marginBottom: 8,
                            }}
                          >
                            Strategy
                          </h5>
                          <p
                            className="option-p text-left-justify"
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              lineHeight: "20px",
                              color: "#666666",
                              marginBottom: 51,
                            }}
                          >
                            We combine tech innovation with best practices,
                            empowering your organization to excel in today's
                            dynamic digital landscape.
                          </p>
                        </div>
                      </div>
                      <div className="home-process-option">
                        <div className="option_pic d-flex flex-column">
                          <img
                            src="image/svg/Group 39808.svg"
                            alt="no images"
                            width={62}
                            height={62}
                          />
                          <span className="flex-grow-1 dotted_line" />
                        </div>
                        <div className="option-details">
                          <h5
                            className="option-header"
                            style={{
                              fontSize: 22,
                              fontWeight: 600,
                              lineHeight: "27px",
                              color: "#333333",
                              marginBottom: 8,
                            }}
                          >
                            Design
                          </h5>
                          <p
                            className="option-p text-left-justify"
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              lineHeight: "20px",
                              color: "#666666",
                              marginBottom: 51,
                            }}
                          >
                            Our philosophy revolves around simplicity,
                            functionality, and beauty. We ensure that every
                            pixel is carefully considered to make your digital
                            presence truly stand out.
                          </p>
                        </div>
                      </div>
                      <div className="home-process-option">
                        <div className="option_pic d-flex flex-column">
                          <img
                            src="image/svg/Group 39808 (1).svg"
                            alt="no images"
                            width={62}
                            height={62}
                          />
                          <span className="flex-grow-1 dotted_line" />
                        </div>
                        <div className="option-details">
                          <h5
                            className="option-header"
                            style={{
                              fontSize: 22,
                              fontWeight: 600,
                              lineHeight: "27px",
                              color: "#333333",
                              marginBottom: 8,
                            }}
                          >
                            Development
                          </h5>
                          <p
                            className="option-p text-left-justify"
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              lineHeight: "20px",
                              color: "#666666",
                              marginBottom: 51,
                            }}
                          >
                            Embracing challenges, our developers are problem
                            solvers, transforming complex ideas into
                            user-friendly software that fuels business success.
                          </p>
                        </div>
                      </div>
                      <div className="home-process-option">
                        <div className="option_pic d-flex flex-column">
                          <img
                            src="image/svg/Group 39808 (2).svg"
                            alt="no images"
                            width={62}
                            height={62}
                          />
                          <span className="flex-grow-1 dotted_line" />
                        </div>
                        <div className="option-details">
                          <h5
                            className="option-header"
                            style={{
                              fontSize: 22,
                              fontWeight: 600,
                              lineHeight: "27px",
                              color: "#333333",
                              marginBottom: 8,
                            }}
                          >
                            QA Testing
                          </h5>
                          <p
                            className="option-p text-left-justify"
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              lineHeight: "20px",
                              color: "#666666",
                              marginBottom: 51,
                            }}
                          >
                            Our QA team tirelessly ensures flawless software,
                            guaranteeing a smooth, reliable user experience with
                            no room for errors.
                          </p>
                        </div>
                      </div>
                      <div className="home-process-option">
                        <div className="option_pic d-flex flex-column">
                          <img
                            src="image/svg/Group 39808 (3).svg"
                            alt="no images"
                            width={62}
                            height={62}
                          />
                          <span className="flex-grow-1" />
                        </div>
                        <div className="option-details">
                          <h5
                            className="option-header"
                            style={{
                              fontSize: 22,
                              fontWeight: 600,
                              lineHeight: "27px",
                              color: "#333333",
                              marginBottom: 8,
                            }}
                          >
                            Deployment
                          </h5>
                          <p
                            className="option-p text-left-justify"
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              lineHeight: "20px",
                              color: "#666666",
                              marginBottom: 51,
                            }}
                          >
                            We manage everything from servers to cloud
                            deployments, ensuring your systems empower your
                            business and serve customers effectively.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Suspense fallback={<ComponentLoader />}>
            <DigitalExperience />
            <CompletedWork />
            <RecentWork recentWork={props} />
            <ClientReview />
          </Suspense>
        </div>
      </Layouts>
    </>
  );
};

export default Home;
