import React, { useEffect, useState } from 'react';
import './css/dashboard.css';
import Layouts from './layout/layouts';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import dashboardService from '../../services/dashboardService';  
import { NavLink, useNavigate } from 'react-router-dom'; 
import webService from '../../services/webService';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Dashboard = () => {

  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]; 
  const [barDatas, setBarData] = useState({
    labels: monthNames,
    datasets: [
      {
        label: 'Job Postings',
        backgroundColor: '#F58634',
        data: Array(12).fill(0),
        barThickness: 10,
        maxBarThickness: 12
      },
    ],
  }); 

  const [loader, setLoader] = useState(false)
  const barOptions = {
    scales: {
      x: {
        grid: {
          display: false
        },
        border: {
          display: false
        },
        ticks: {
          color: '#7a7a7a',
          font: {
            family: 'Montserrat',
            size: 13,
            weight: '500'
          }
        }
      },
      y: {
        grid: {
          display: false
        },
        border: {
          display: false
        },
        ticks: {
          color: '#7a7a7a',
          font: {
            family: 'Montserrat',
            size: 18,
            weight: '500'
          }
        },
        min:1,
        max:30
      },
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          color: '#7a7a7a',
          font: {
            family: 'Montserrat',
            size: 18,
            weight: '500'
          }
        }
      },
      title: {
        display: true,
        text: 'Job Postings',
        color: '#454545',
        font: {
          family: 'Montserrat',
          size: 18,
          weight: '500'
        },
        padding: { bottom: 32 },
        position: 'top',
        align: 'start'
      }
    },
    maintainAspectRatio: false
  }

  const doughnutLabels = ['Total Visitors']   
  const[doughnutChartData, setdoughnutChartData] = useState({
    labels: doughnutLabels,
    datasets: [
      {
        data: [0, 100], // First data point represents the value you want to show, and the second represents the remaining to fill the doughnut
        backgroundColor: ['#F58634', '#FEF4ED'] 
      },
    ],
  });
    
  const [jobcount,setJobCount] = useState(0)
  const [reviewcount,setReviewCount] = useState(0)
  const [portfoliocount,setPortfolioCount] = useState(0)
  const token = localStorage.getItem('token'); 
  const navigate = useNavigate();
 

  useEffect(() => {
      const token = localStorage.getItem('token');
      const tokenExpiration = localStorage.getItem('tokenExpiration');  // Get token expiration time from localStorage

      if (token && tokenExpiration) {
          // Check if the token is expired
          if (Date.now() > tokenExpiration) {
              // Token is expired, logout the user
              localStorage.removeItem('token');
              localStorage.removeItem('tokenExpiration');
              navigate('/cs-admin');  // Redirect to the login page
          }else{
            getCount();
            getJob();
            getGooglevisitor();
          }
      } else {
          // No token found, redirect to login
          navigate('/cs-admin');
      }
  }, [navigate]);

 

  const getCount = async()=>{  
    await dashboardService.getCount(token).then(res => {  
      setJobCount(res.data?.result || 0); 
      setReviewCount(res.data?.review || 0);
      setPortfolioCount(res.data?.portfolio || 0) 
     })
  }
  const getJob = async()=>{  
    await dashboardService.getJobCount(token).then(res => {  
      // console.log(res.data?.result)

    let apiData =  res.data?.result; 
    const values = Array(12).fill(0);
    apiData.forEach(item => {
      values[item.month - 1] = item.count;
    }); 

    setBarData(prevChartData => ({
      ...prevChartData,
      datasets: [
        {
          ...prevChartData.datasets[0],
          data: values,
        },
      ],
    })); 
   
     })
  }
  const getGooglevisitor = async()=>{  
    await webService.getvisitorData().then(res => {   
      const percentage = res.data?.result.value / 100; 
      // Create a new copy of the doughnutChartData object with updated data
      const updatedChartData = {
        ...doughnutChartData,
        datasets: [
          {
            ...doughnutChartData.datasets[0],
            data: [percentage, 100 - percentage],
          },
        ],
      };
      setdoughnutChartData(updatedChartData) 
      setLoader(true)
    });
  }
  const handleMenuActive = (menuactive) =>{
    // alert(menuactive)
    if(menuactive == "4" || menuactive == "14" || menuactive == "15"){ 
      localStorage.setItem("child",true)
    }else{ 
      localStorage.setItem("child",false)
    }
    localStorage.setItem("menuactive",menuactive) 
  }

  return (
    <>
      <Layouts>
        <div className='admin_dashboard'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12 col-lg-4 gy-3'>
              <NavLink to='/job-list' style={{textDecoration:"none"}} onClick={()=>handleMenuActive(4)}>
                <div className='card_box'>
                  <div className='_img'>
                    <img src='/admin/dashboard/total_job_postings.svg' alt='' />
                  </div>
                  <div className='details'>
                    <div className='heading'>
                      Total Job Postings 
                    </div>
                    <div className='values'>
                      {jobcount}
                    </div>
                  </div>
                </div>
                </NavLink>
              </div>
              <div className='col-12 col-lg-4 gy-3'>
              <NavLink to='/admin-portfolios' style={{textDecoration:"none"}} onClick={()=>handleMenuActive(2)}>
                <div className='card_box'>
                  <div className='_img'>
                    <img src='/admin/dashboard/total_portfolios.svg' alt='' />
                  </div>
                  <div className='details'>
                    <div className='heading'>
                      Total Portfolios
                    </div>
                    <div className='values'>
                      {portfoliocount}
                    </div>
                  </div>
                </div>
              </NavLink>
              </div>
              <div className='col-12 col-lg-4 gy-3'>
              <NavLink to='/review-list' style={{textDecoration:"none"}} onClick={()=>handleMenuActive(6)}>
                <div className='card_box'>
                  <div className='_img'>
                    <img src='/admin/dashboard/total_client_reviews.svg' alt='' />
                  </div>
                  <div className='details'>
                    <div className='heading'>
                      Total Clients Reviews
                    </div>
                    <div className='values'>
                      {reviewcount}  
                    </div>
                  </div>
                </div>
              </NavLink>
              </div>

              <div className='col-12 col-lg-8'>
                <div className='chart_box'>
                  {/* <div className='heading'>
                    Job Postings
                  </div> */}
                  <Bar
                    data={barDatas}
                    options={barOptions}
                    width={"100%"}
                    height={350}
                  />
                </div>
              </div>
              <div className='col-12 col-lg-4'>
            
                <div className='chart_box'>  
                {  loader ? 
                  <Doughnut
                    data={doughnutChartData}
                    options={{
                      cutout: "85%",
                      elements: {
                        arc: {
                          borderRadius: 10,
                        },
                      },
                      plugins: {
                        doughnutlabel: {
                          labels: [
                            {
                              text: `${doughnutChartData.datasets[0].data[0]}%`,
                              font: {
                                size: 18,
                                family: "Montserrat",
                                color: "#F58634",
                                weight: "600",
                              },
                            },
                          ],
                        },
                        legend: {
                          display: true,
                          labels: {
                            usePointStyle: true,
                            boxWidth: 10,
                            boxHeight: 8,
                            color: "#454545",
                            font: {
                              family: "Montserrat",
                              size: 14,
                              weight: "500",
                            },
                            padding: 64,
                          },
                          position: "bottom",
                          align: "center",
                        },
                        title: {
                          display: true,
                          text: "Website Visitors",
                          color: "#454545",
                          font: {
                            family: "Montserrat",
                            size: 18,
                            weight: "500",
                          },
                          padding: { bottom: 48 },
                          position: "top",
                          align: "start",
                        }, 
                      },
                      maintainAspectRatio: false,
                    }}
                    plugins={[{
                      beforeDraw: function (chart) {
                        let width = chart.width;
                        let height = chart.height;
                        let ctx = chart.ctx;
                        ctx.restore();
                  
                        // 1st Text
                        ctx.font = "600 18px Montserrat";
                        ctx.fillStyle = "#F58634";
                        // ctx.textAlign = "center";
                        ctx.textBaseline = "middle";
                        let text1 = `${doughnutChartData.datasets[0].data[0]}%`,
                          textX1 = Math.round((width - ctx.measureText(text1).width) / 2),
                          textY1 = height / 2.2;
                        ctx.fillText(text1, textX1, textY1);
                        ctx.restore();
                  
                        // 2nd Text
                        ctx.font = "500 12px Montserrat";
                        ctx.fillStyle = "#7a7a7a";
                        // ctx.textAlign = "center";
                        ctx.textBaseline = "middle";
                        let text2 = "Website growth",
                          textX2 = Math.round((width - ctx.measureText(text2).width) / 2),
                          textY2 = height / 1.95;
                        ctx.fillText(text2, textX2, textY2);
                        ctx.save();
                      }
                    }]}
                    width={"100%"}
                    height={350}
                  /> 
                  : 
                  <div className='heading'>
                      Website Visitors
                      <div style={{height:"296px", width:"349px"}}>
                        <h3 style={{color:"#F58634",fontSize:"18px", textAlign:"center",paddingTop:"9rem"}}>Loading.....</h3>
                      </div>
                  </div>}
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </Layouts>
    </>
  )
}

export default Dashboard